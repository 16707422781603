import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { withRouter, useHistory } from "react-router-dom"

import { getToken, IsModemAccess,DateUTCToLocal } from '../../helpers/helper';
import { del, post } from '../../helpers/api_helper';
import { POST_GET_ALL_USER, DELETE_USER, POST_UPDATE_USER_STATUS, POST_UPDATE_USER_PROFILE_STATUS } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import ReactDOM from 'react-dom';

import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

const UserProfile = props => {
    let history = useHistory();
    const [user_id, SetUserId] = useState(0);
    
    useEffect(() => {
        get_admin_user();
    }, [])

    // get user role list
    const get_admin_user = () => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": POST_GET_ALL_USER ,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [13,14,15] }, { "targets": 0, "visible": false }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "id" },
                    { "data": "username" },
                    { "data": "registered_from" },
                    { "data": "user_type" },
                    { "data": "email" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {data.country_code && <div className="text-center"><span className="country_flags"><img src={`/flags/${data.country_code}.png`} alt={data.country_name} title={data.country_name} style={{'maxWidth' : '25px' ,'maxHeight': '25px'}} /> </span></div> }
                                {!data.country_code && <span>-</span> }
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>{parseFloat(data.offers_balance).toFixed(2)}</>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>{parseFloat(data.video_balance).toFixed(2)}</>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>{parseFloat(data.leaderboard_balance).toFixed(2)}</>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>{parseFloat(data.referral_balance).toFixed(2)}</>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>{parseFloat(data.bonus_balance).toFixed(2)}</>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>{parseFloat(data.points_balance).toFixed(2)}</>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>{parseFloat(data.total_balance).toFixed(2)}</>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>{DateUTCToLocal(data.created_at)}</>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => { 
                            ReactDOM.render(<>
                                {data.profile_verified === 1 && <button onClick={UpdateProfileStatus.bind(this, data.id, data.profile_verified)} className="border-0 badge badge-success fs-6"  >Yes</button>}
                                {data.profile_verified === 0 && <button onClick={UpdateProfileStatus.bind(this, data.id, data.profile_verified)} className="border-0 badge badge-danger fs-6" >No</button>}
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => { 
                            ReactDOM.render(<>
                                {data.user_status === 1 && <button onClick={UpdateStatus.bind(this, data.id, data.user_status)} className="border-0 badge badge-success fs-6"  >Active</button>}
                                {data.user_status === 0 && <button onClick={UpdateStatus.bind(this, data.id, data.user_status)} className="border-0 badge badge-danger fs-6" >Deactive</button>}
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {IsModemAccess('user', 'view') && <>
                                <button className="btn btn-soft-primary waves-effect waves-light p-1 mr-2" onClick={() => { history.push('/user-chat/' + data.id); }} ><i className="fa fa-comments" aria-hidden="true"></i></button>
                                <button className="btn btn-soft-info waves-effect waves-light p-1 mr-2" onClick={() => { history.push('/user-detail/' + data.id); }} ><i className="fas fa-eye align-middle"></i></button>
                                </>}
                                {IsModemAccess('user', 'delete') && <button className="btn btn-soft-danger waves-effect waves-light p-1 mr-2" onClick={() => { SetUserId(data.id); }} data-toggle="modal" data-target="#deleteModal" ><i className="bx bx-trash-alt align-middle"></i></button>}
                            </>, td)
                        }
                    },
                ]
            });
        });
    }

    // remove user role
    const remove_row = async () => {
        var res_data = await del(DELETE_USER + "?user_id=" + user_id);
        if (res_data.status) {
            toast(res_data.message);
            get_admin_user();
        } else {
            toast.error(res_data.message);
        }
    }

    //UpdateStatus
    const UpdateStatus = async (user_id, status) => {
        if (!IsModemAccess('user', 'edit')) {
            toast('Does not permission this module');
            return true;
        }
        var res_data = await post(POST_UPDATE_USER_STATUS, { user_id: user_id, status: status });
        if (res_data.status) {
            toast(res_data.message);
            get_admin_user();
        } else {
            toast.error(res_data.message);
        }
    }

    //UpdateProfileStatus
    const UpdateProfileStatus = async (user_id, status) => {
        if (!IsModemAccess('user', 'edit')) {
            toast('Does not permission this module');
            return true;
        }
        var res_data = await post(POST_UPDATE_USER_PROFILE_STATUS, { user_id: user_id, status: status });
        if (res_data.status) {
            toast(res_data.message);
            get_admin_user();
        } else {
            toast.error(res_data.message);
        }
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>User | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem="User" />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <div className="mt-4 mb-5">
                                        {IsModemAccess('user', 'view') && <table id="dataTableExample" className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <td>Id</td>
                                                    <td>User Name</td>
                                                    <td>RF</td>
                                                    <td>UT</td>
                                                    <td>Email</td>
                                                    <td>Country</td>
                                                    <td>Offers Bl</td>
                                                    <td>Video Bl</td>
                                                    <td>Leaderboard Bl</td>
                                                    <td>Referral Bl</td>
                                                    <td>Bonus Bl</td>
                                                    <td>Points Bl</td>
                                                    <td>Total Bl</td>
                                                    <td>Registered Date</td>
                                                    <td>Profile Verified<br/> by Admin</td>
                                                    <td>Status</td>
                                                    <td>Action</td>
                                                </tr>
                                            </thead>
                                            <tbody>

                                            </tbody>
                                        </table>}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Confirm Delete</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this record?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">No</button>
                            <button onClick={remove_row.bind(this)} data-dismiss="modal" className="btn btn-primary">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

    )
}

export default withRouter(UserProfile)

import Moment from 'moment';

const getToken = () => {
    return sessionStorage.getItem('fmc_token') || null;
}

const getUserDetail = () => {
    return JSON.parse(sessionStorage.getItem('fmc_user'));
}

const getUserId = () => {
    return JSON.parse(sessionStorage.getItem('fmc_user'))['user_id'];
}

const getUserType = () => {
    if(sessionStorage.getItem('user')){
        return JSON.parse(sessionStorage.getItem('fmc_user'))['u_type'];
    }else{
        return 0;
    }
}

const removeUserSession = () => {
    sessionStorage.removeItem('fmc_token');
    sessionStorage.removeItem('fmc_user');
}

const setUserSession = (token, user) => {
    sessionStorage.setItem('fmc_token', token);
    sessionStorage.setItem('fmc_user', JSON.stringify(user));
}

const is_login = () => {
    if (sessionStorage.getItem('fmc_token')) {
        return true;
    } else {
        return false;
    }
}

const SingalSelectValueArray = (option_array = [],value = '') => {
    return option_array.filter((item) => {
        return item.value === value;
    })
}

const MultiSelectValueArray = (option_array = [],value = '') => {
    var select_value = [];
    value = (value)? value.split(",") : [];
    if(value.length > 0){
        value.filter((value_item) => {
            option_array.filter((item) => {
                if(value_item.toString() === item.value.toString()){
                    select_value.push(item);
                }
            })
        });
    }
    return select_value;
}

const IsModemAccess = (modem_name,type) => {
    var modem = JSON.parse(sessionStorage.getItem('fmc_user'))['role_assigned'];

    if(modem[modem_name] && modem[modem_name].module[type] === 1){
        return true;
    }else{
        return false;
    }
}

const UTCToLocal = (date) => {
    date  = parseInt(date);
    return Moment(date).format('DD-MM-YYYY HH:mm:ss');
}

const DateUTCToLocal = (date,format = "DD-MM-YYYY HH:mm:ss") => {
    date = date.replace('Z','');
    // return date;
    // var dt = new Date(date);
    // return Moment(dt.getTime()).format(format);
    date = Moment(date).format('YYYY-MM-DD HH:mm:ss');
    var timestamp = Moment.utc(date).valueOf();
    return Moment(timestamp).format(format);
}

// arrayColumn
const arrayColumn = (column_array, column, key = false) => {
    const arrayColumn = (arr, n) => arr.map(x => x[n]);
    return arrayColumn(column_array, column);
}

const AmountFormat = (amount, digit = 2) => {
    if(amount && amount >= 0){
        return parseFloat(amount).toFixed(digit);
    }else{
        return parseFloat(amount).toFixed(digit);
    }
}

export {AmountFormat,DateUTCToLocal, UTCToLocal,IsModemAccess,SingalSelectValueArray,getUserType,getToken, removeUserSession, setUserSession, is_login,getUserDetail, getUserId,arrayColumn,MultiSelectValueArray };
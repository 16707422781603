import MetaTags from "react-meta-tags"
import React, { useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { withRouter, useHistory } from "react-router-dom"

import { getToken, IsModemAccess } from '../../helpers/helper';
import { POST_GET_LEADERBOARD } from '../../helpers/url_helper';
import ReactDOM from 'react-dom';

import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

const UserProfile = props => {
    let history = useHistory();
    
    useEffect(() => {
        get_admin_user();
    }, [])

    // get user role list
    const get_admin_user = () => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": POST_GET_LEADERBOARD ,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [7] }, { "targets": 0, "visible": false }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "leaderboard_results_id" },
                    { "data": "type" },
                    { "data": "created_at" },
                    { "data": "start_date" },
                    { "data": "end_date" },
                    { "data": "total_user" },
                    { "data": "total_coins" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {IsModemAccess('leaderboard', 'view') && <>
                                <button className="btn btn-soft-info waves-effect waves-light p-1 mr-2" onClick={() => { history.push('/leaderboard/detail/' + data.leaderboard_results_id); }} ><i className="fa fa-eye" aria-hidden="true"></i></button>
                                </>}
                            </>, td)
                        }
                    },
                ]
            });
        });
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Leaderboard | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem="Leaderboard" />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <div className="mt-4 mb-5">
                                        {IsModemAccess('leaderboard', 'view') && <table id="dataTableExample" className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <td>Id</td>
                                                    <td>Type</td>
                                                    <td>Result Date</td>
                                                    <td>Start Date</td>
                                                    <td>End Date</td>
                                                    <td>Total User</td>
                                                    <td>Total Coins</td>
                                                    <td>Action</td>
                                                </tr>
                                            </thead>
                                            <tbody>

                                            </tbody>
                                        </table>}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>

    )
}

export default withRouter(UserProfile)

import MetaTags from "react-meta-tags"
import React, { useState, useEffect, useRef } from "react"
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap"
import { useHistory, withRouter, Link } from "react-router-dom"

import Loader from './../../components/Common/Loader';
import SimpleReactValidator from 'simple-react-validator';
import { post, get } from '../../helpers/api_helper';
import { SingalSelectValueArray, IsModemAccess } from '../../helpers/helper';
import { POST_GET_ADMIN_USER_DETAIL, POST_ADD_EDIT_ADMIN_USER, GET_ADMIN_FORM_OPTION } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import Select from "react-select"
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"


const AdminForm = props => {
    let history = useHistory();

    const [isLoader, setLoader] = useState(0);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [title, setTitle] = useState('Add Admin');
    const [UserRole, setUserRole] = useState([]);
    const [SelectUserRole, setSelectUserRole] = useState([]);
    const [form_inputs, setInputs] = useState({ id: 0, user_type: '', email: '', username: '', password: '', c_password: '' });
    const [, forceUpdate] = useState();
    const [PasswordShow, SetPasswordShow] = useState(false);
    const [CPasswordShow, SetCPasswordShow] = useState(false);

    var var_UserRole = [];


    useEffect(() => {
        async function get_detaile() {
            await get_admin_form_option();
            if (props.match.params.id) {
                if (!IsModemAccess('admin', 'edit')) {
                    toast('Does not permission this module');
                    history.push("/");
                }

                setTitle('Edit Admin');
                var res_data = await post(POST_GET_ADMIN_USER_DETAIL, { 'user_id': props.match.params.id }, {});
                if (res_data.status) {
                    await setInputs(res_data.detail);
                    await setSelectUserRole(await SingalSelectValueArray(var_UserRole, res_data.detail.user_type));
                } else {
                    toast.error(res_data.message);
                    history.push("/");
                }
            } else {
                if (!IsModemAccess('admin', 'add')) {
                    toast('Does not permission this module');
                    history.push("/");
                }
            }
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_ADD_EDIT_ADMIN_USER, form_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
                history.push("/admin");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    //get admin form option
    const get_admin_form_option = async () => {
        var res_data = await get(GET_ADMIN_FORM_OPTION, form_inputs);
        if (res_data.status) {
            var_UserRole = res_data.user_roleses;
            setUserRole(var_UserRole);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name === 'user_type') {
            var value = event.target.value.replace(' ', '');
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: value }));
        } else {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    const handleSelectChange = (name, value) => {
        setInputs(inputs => ({ ...form_inputs, [name]: value.value }));
    }

     // GeneratePassword
     const GeneratePassword = () => {
        var generator = require('generate-password');
        var password = generator.generate({
            length: 10,
            numbers: true,
            symbols: false,
            uppercase: false,
            excludeSimilarCharacters: false
        });
        setInputs(inputs => ({ ...form_inputs, ['password']: password }));
    }
    
    const PasswordShowHide = (name) => {
        console.log(name);
        if (name === 'password') {
            if (PasswordShow) {
                SetPasswordShow(false);
            } else {
                SetPasswordShow(true);
            }
        } else {
            if (CPasswordShow) {
                SetCPasswordShow(false);
            } else {
                SetCPasswordShow(true);
            }
        }
    }

    if (!isLoader) {
        return (<Loader></Loader>);
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{title} | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem={title} />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <form className="av-invalid" onSubmit={handleSubmit}>
                                        <Row>
                                            <Col lg="6">
                                                <div className="form-group mb-3">
                                                    <label className="form-label">User Name</label>
                                                    <input name="username" value={form_inputs.username} onChange={handleInputChange} className="form-control" placeholder="Enter User Name" type="text" />
                                                    {simpleValidator.current.message('user name', form_inputs.username, 'required')}
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Select Role {SelectUserRole.length > 0 ? SelectUserRole[0].label : ''}</label>
                                                    <Select
                                                        name="user_type"
                                                        defaultValue={SelectUserRole}
                                                        isMulti={false}
                                                        options={UserRole}
                                                        onChange={handleSelectChange.bind(this, 'user_type')}
                                                        classNamePrefix="select2-selection"
                                                        isLoading={false}
                                                    />
                                                    {simpleValidator.current.message('role', form_inputs.user_type, 'required')}
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Email Address</label>
                                                    <input name="email" value={form_inputs.email} onChange={handleInputChange} className="form-control" placeholder="Enter Email Address" type="text" />
                                                    {simpleValidator.current.message('email address', form_inputs.email, 'required|email')}
                                                </div>
                                            </Col>
                                            <Col lg="6">
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Enter Password</label>
                                                    <Row>
                                                        <Col xs='8'>
                                                            <div className="input-group">
                                                                <input name="password" value={form_inputs.password} onChange={handleInputChange} className="form-control" placeholder="Enter Password" type={PasswordShow ? 'text' : 'password'} />
                                                                <div className="input-group-text pt-1" onClick={PasswordShowHide.bind(this, 'password')}>
                                                                    {!PasswordShow && <i className="fas fa-eye align-middle"></i>}
                                                                    {PasswordShow && <i className="fas fa-eye-slash"></i>}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col xs='4'>
                                                            <Button type="button" onClick={GeneratePassword} className="btn btn-primary">Generate Password</Button>
                                                        </Col>
                                                    </Row>

                                                    {!form_inputs.user_id && simpleValidator.current.message('password', form_inputs.password, 'required')}
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Confirm Password</label>
                                                    <Row>
                                                        <Col xs='8'>
                                                            <div className="input-group">
                                                                <input name="c_password" value={form_inputs.c_password} onChange={handleInputChange} className="form-control" placeholder="Enter Confirm Password" type={CPasswordShow ? 'text' : 'password'} />
                                                                <div className="input-group-text pt-1" onClick={PasswordShowHide.bind(this, 'c_password')}>
                                                                    {!CPasswordShow && <i className="fas fa-eye align-middle"></i>}
                                                                    {CPasswordShow && <i className="fas fa-eye-slash"></i>}
                                                                </div>
                                                            </div>
                                                            {form_inputs.password && simpleValidator.current.message('confirm password', form_inputs.c_password, `required|in:${form_inputs.password}`, { messages: { in: `Passwords don't match.` } })}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="text-center mt-4">
                                            <Button disabled={ButtonDisabled} type="submit" color="primary">{ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Save</Button>
                                            <Link to="/admin" className="btn btn-danger ms-3"> Cancel</Link>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(AdminForm)

import MetaTags from "react-meta-tags"
import React, { useState, useEffect, useRef } from "react"
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap"
import { useHistory, withRouter, Link } from "react-router-dom"

import Loader from '../../components/Common/Loader';
import SimpleReactValidator from 'simple-react-validator';
import { post } from '../../helpers/api_helper';
import { IsModemAccess } from '../../helpers/helper';
import { POST_GET_BLOG_DETAIL, POST_ADD_EDIT_BLOG } from '../../helpers/url_helper';
import { toast } from 'react-toastify';

// Form Editor
// import ReactQuill from "react-quill"
// import 'react-quill/dist/quill.snow.css'

import CKEditor from "react-ckeditor-component";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

const AdminForm = props => {
    let history = useHistory();

    const [isLoader, setLoader] = useState(0);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [title, setTitle] = useState('Add Custom Offerwall');
    const [form_inputs, setInputs] = useState({ blog_id: 0, title: '', slug: '', description: '', image: '', meta_title: '', meta_description: '', meta_keywords: '' });
    const [, forceUpdate] = useState();
    const [Description, setDescription] = useState();

    useEffect(() => {
        async function get_detaile() {
            if (props.match.params.id) {
                if (!IsModemAccess('blog', 'edit')) {
                    toast('Does not permission this module');
                    history.push("/");
                }

                setTitle('Edit Blog');
                var res_data = await post(POST_GET_BLOG_DETAIL, { 'blog_id': props.match.params.id }, {});
                if (res_data.status) {
                    await setInputs(res_data.detail);
                    await setDescription(res_data.detail.description);
                } else {
                    toast.error(res_data.message);
                    history.push("/");
                }
            } else {
                if (!IsModemAccess('blog', 'add')) {
                    toast('Does not permission this module');
                    history.push("/");
                }
            }
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            form_inputs.description = Description;
            var res_data = await post(POST_ADD_EDIT_BLOG, form_inputs);
            if (res_data.status) {
                toast(res_data.message);
                history.push("/blog");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name === 'image') {
            var file = event.target.files[0];
            var reader = new FileReader();
            reader.onloadend = function () {
                console.log(reader);
                setInputs(inputs => ({ ...form_inputs, ['image']: reader.result }));
            }
            reader.readAsDataURL(file);
        } else {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    // const handleEditorChange = (event) => {
    //     setInputs(inputs => ({ ...form_inputs, ['description']: event }));
    // }

    const handleEditorChange = (event) => {
        var newContent = event.editor.getData();
        setDescription(newContent);
    };

    // const quillRef = useRef();

    // const imageHandler = async () => {
    //     const editor = quillRef.current.getEditor();
    //     const input = document.createElement('input');
    //     input.setAttribute('type', 'file');
    //     input.setAttribute('accept', 'image/*');
    //     input.click();

    //     input.onchange = async () => {
    //         var file = input.files[0];
    //         var reader = new FileReader();
    //         reader.onloadend = function () {
    //             editor.insertEmbed(editor.getSelection(), "image", reader.result);
    //         }
    //         reader.readAsDataURL(file);
    //     };
    // }

    // const modules = useMemo(() => ({
    //     toolbar: {
    //         container: [
    //             [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    //             ['bold', 'italic', 'underline', "strike"],
    //             [{ 'list': 'ordered' }, { 'list': 'bullet' },
    //             { 'indent': '-1' }, { 'indent': '+1' }],
    //             ['image', "link", "code-block"],
    //             [{ 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'] }]
    //         ],
    //         handlers: {
    //             image: imageHandler
    //         },
    //     },
    // }), [])

    if (!isLoader) {
        return (<Loader></Loader>);
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{title} | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem={title} />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <form className="av-invalid" onSubmit={handleSubmit}>
                                        <Row>
                                            <Col lg="6">
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Title</label>
                                                    <input name="title" value={form_inputs.title} onChange={handleInputChange} className="form-control" placeholder="Enter Title" type="text" />
                                                    {simpleValidator.current.message('title', form_inputs.title, 'required')}
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Url Slug</label>
                                                    <input name="slug" value={form_inputs.slug} onChange={handleInputChange} className="form-control" placeholder="Enter Url Slug" type="text" />
                                                    {simpleValidator.current.message('slug', form_inputs.slug, 'required')}
                                                </div>
                                                <Row className="mb-4">
                                                    <Col lg="8">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label">Image <small className="ml-3">Accept only png, jpg and jpeg image   Size 1000px x 1000px</small></label>
                                                            <input name="image" onChange={handleInputChange} className="form-control" placeholder="Select png Image" type="file" accept="image/png,image/jpg,image/jpeg" />
                                                        </div>
                                                    </Col>
                                                    <Col lg="4" className="mt-3">
                                                        {form_inputs.image_url && <img alt="Offerwall" src={form_inputs.image_url} style={{ 'maxWidth': '150px' }} />}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg="6">
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Meta Title </label>
                                                    <input name="meta_title" value={form_inputs.meta_title} onChange={handleInputChange} className="form-control" placeholder="Enter Meta Title" type="text" />
                                                    {/* {simpleValidator.current.message('meta title', form_inputs.meta_title, 'required')} */}
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Meta keywords</label>
                                                    <input name="meta_keywords" value={form_inputs.meta_keywords} onChange={handleInputChange} className="form-control" placeholder="Enter Meta keywords" type="text" />
                                                    {/* {simpleValidator.current.message('meta keywords', form_inputs.meta_keywords, 'required')} */}
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Meta Description</label>
                                                    <textarea name="meta_description" value={form_inputs.meta_description} onChange={handleInputChange} className="form-control" placeholder="Enter Meta Description" />
                                                    {/* {simpleValidator.current.message('meta description', form_inputs.meta_description, 'required')} */}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12 mt-5">
                                                {/* <ReactQuill
                                                    ref={quillRef}
                                                    theme='snow'
                                                    value={form_inputs.description}
                                                    modules={modules}
                                                    onChange={handleEditorChange}
                                                /> */}
                                                <CKEditor
                                                    activeClass="p10"
                                                    content={Description}
                                                    events={{
                                                        change: handleEditorChange
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <div className="text-center mt-4">
                                            <Button disabled={ButtonDisabled} type="submit" color="primary">{ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Save</Button>
                                            <Link to="/blog" className="btn btn-danger ms-3"> Cancel</Link>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(AdminForm)

import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap"

// availity-reactstrap-validation
import { withRouter, useHistory, Link } from "react-router-dom"

import { get, post } from '../../helpers/api_helper';
import { IsModemAccess, DateUTCToLocal, SingalSelectValueArray } from '../../helpers/helper';
import { POST_GET_WITHDRAWAL_REQUEST_DETAIL, POST_UPDATE_WITHDRAWAL_REQUEST_STATUS, POST_SEND_GIFT_CARD,POST_MOBILE_RECHARGE,POST_SEND_GIFT_CARD_EMAIL, POST_PAYOUT_CRYPTO, POST_PAYOUT_PAYPAL } from '../../helpers/url_helper';
import { toast } from 'react-toastify';

import Loader from '../../components/Common/Loader';
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import Select from "react-select"

const UserProfile = props => {
    let history = useHistory();

    var var_status_list = [
        { 'value': '0', 'label': 'Pending' },
        { 'value': '1', 'label': 'Prosseing' },
        { 'value': '2', 'label': 'Completed' },
        { 'value': '3', 'label': 'Cancel' }
    ];
    const [StatusList] = useState(var_status_list);
    const [SelectStatus, setSelectStatus] = useState();
    const [form_inputs, setInputs] = useState({ requests_id: 0, status: '', note: '',transferMode : '' });

    const [isLoader, setLoader] = useState(0);
    const [Detail, setDetail] = useState();
    const [ProviderExtra, setProviderExtra] = useState({});
    const [ButtonDisabled, SetButtonDisabled] = useState(false);

    useEffect(() => {
        async function get_detaile() {
            if (props.match.params.id) {
                if (!IsModemAccess('withdrawal_requests', 'view')) {
                    toast('Does not permission this module');
                    history.push("/");
                }
                await get_withdrawal_detail();
            }
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])

    const get_withdrawal_detail = async () => {
        var res_data = await get(POST_GET_WITHDRAWAL_REQUEST_DETAIL + '?request_id=' + props.match.params.id);
        if (res_data.status) {
            await setDetail(res_data.detail);
            if(res_data.detail.payout_extra){
                var payout_extra = JSON.parse(res_data.detail.payout_extra);
                setProviderExtra(payout_extra.exchange_options);
            }

            setInputs({ requests_id: props.match.params.id, status: res_data.detail.status, note: res_data.detail.payout_note,transferMode : ''});
            await setSelectStatus(await SingalSelectValueArray(var_status_list, res_data.detail.status.toString()));
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();

        SetButtonDisabled(true);
        var res_data = await post(POST_UPDATE_WITHDRAWAL_REQUEST_STATUS, form_inputs);
        if (res_data.status) {
            toast(res_data.message);
            get_withdrawal_detail();
        } else {
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    }

    // form submit event
    const send_gift_card = async (event) => {
        event.preventDefault();

        SetButtonDisabled(true);
        var res_data = await post(POST_SEND_GIFT_CARD, {'id' : props.match.params.id});
        if (res_data.status) {
            toast(res_data.message);
            get_withdrawal_detail();
        } else {
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    }

    const send_gift_card_email = async (event) => {
        event.preventDefault();

        SetButtonDisabled(true);
        var res_data = await post(POST_SEND_GIFT_CARD_EMAIL, {'id' : props.match.params.id});
        if (res_data.status) {
            toast(res_data.message);
        } else {
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    }

    // form submit event
    const recharge = async (event) => {
        event.preventDefault();

        SetButtonDisabled(true);
        var res_data = await post(POST_MOBILE_RECHARGE, {'id' : props.match.params.id});
        if (res_data.status) {
            toast(res_data.message);
            get_withdrawal_detail();
        } else {
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    }

    // form submit event
    const send_crypto = async (event) => {
        event.preventDefault();

        SetButtonDisabled(true);
        var res_data = await post(POST_PAYOUT_CRYPTO, {'id' : props.match.params.id});
        if (res_data.status) {
            toast(res_data.message);
            get_withdrawal_detail();
        } else {
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    }

    // form submit event
    const payout_paypal = async (event) => {
        event.preventDefault();

        SetButtonDisabled(true);
        var res_data = await post(POST_PAYOUT_PAYPAL, {'id' : props.match.params.id});
        if (res_data.status) {
            toast(res_data.message);
            get_withdrawal_detail();
        } else {
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }

    const handleSelectChange = (name, value) => {
        setInputs(inputs => ({ ...form_inputs, [name]: value.value }));
    }

    const copyLink = (code) => {
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = code;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        toast('Copied Successfully');
      }

    if (!isLoader) {
        return (<Loader></Loader>);
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Withdrawal Request Detail | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem="Withdrawal Request Detail" />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">Withdrawal Request Id :</h5>
                                            <span>{Detail.transaction_id}</span>
                                        </Col>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">Withdrawal Request Date :</h5>
                                            <span>{DateUTCToLocal(Detail.created_at)}</span>
                                        </Col>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">User Name :</h5>
                                            <span><Link onClick={() => { window.open('/user-detail/' + Detail.user_id, "_blank").focus(); }} to="#">{Detail.username}</Link></span>
                                        </Col>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">Email :</h5>
                                            <span>{Detail.email}</span>
                                        </Col>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">Withdraw Type :</h5>
                                            <span>{Detail.withdraw_type}</span>
                                        </Col>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">Withdrawal In :</h5>
                                            <span>{Detail.name}</span>
                                        </Col>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">Status:</h5>
                                            {Detail.status.toString() === '0' && <span className="text-info fs-6" >Pending</span>}
                                            {Detail.status.toString() === '1' && <span className="text-warning fs-6" >Prosseing</span>}
                                            {Detail.status.toString() === '2' && <span className="text-success fs-6" >Success</span>}
                                            {Detail.status.toString() === '3' && <span className="text-danger fs-6" >Cancel</span>}
                                        </Col>
                                        {Detail.vpn_info && <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1 text-danger"><i class="fa fa-exclamation-triangle mr-2" aria-hidden="true"></i> Last VPN Detected:</h5>
                                            <span className="text-danger">{DateUTCToLocal(Detail.vpn_info.created_at)}</span>
                                        </Col>}
                                    </Row>
                                    <hr/>
                                    <Row>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">Total Balance :</h5>
                                            <span>{Detail.total_user_coins}</span>
                                        </Col>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">Total Requested Coins :</h5>
                                            <h4 className={`banner ${Detail.coins > Detail.total_user_coins && Detail.status.toString() === '0' ? "text-danger" : "text-success"}`}><b>{Detail.coins}</b></h4>
                                        </Col>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">Payable Amount :</h5>
                                            <span>{Detail.currency_code} {Detail.payout_amount}</span>
                                        </Col>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">Note:</h5>
                                            <span>{Detail.payout_note}</span>
                                        </Col>
                                    </Row>
                                    <hr/>
                                    <Row>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">Total Cash Request :</h5>
                                            <span>{Detail.withdrawal_request_count.total_cash_request}</span>
                                        </Col>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">Total Crypto Request :</h5>
                                            <span>{Detail.withdrawal_request_count.total_crypto_request}</span>
                                        </Col>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">Total Gift Card Request :</h5>
                                            <span>{Detail.withdrawal_request_count.total_gift_card_request}</span>
                                        </Col>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">Total Mobile Topup Request :</h5>
                                            <span>{Detail.withdrawal_request_count.total_mobile_top_up_request}</span>
                                        </Col>
                                    </Row>

                                    <hr />
                                    <Row>
                                        <Col lg="6">
                                            <h5 className="mb-3">Payout Info :</h5>
                                            <table className="table">
                                                {Object.entries(Detail.payout_info).map(([key, value]) => {
                                                    return (<tr>
                                                        <td width="150px" style={{'textTransform': 'capitalize'}}>{key}</td>
                                                        <td>{value}</td>
                                                        <td width="60px"><span onClick={copyLink.bind(this,value)}><i className="fa fa-copy text-primary pointer" style={{'cursor': 'pointer','fontSize' : '18px'}}></i></span></td>
                                                    </tr>
                                                    )
                                                 })}
                                            </table>
                                            <div>
                                                {Detail.qr_code && <img src={Detail.qr_code} />}
                                            </div>
                                        </Col>
                                        <Col lg="6">
                                            <table className="table">
                                                <tr>
                                                    <td></td>
                                                    <td>Coins</td>
                                                    <td>Amount</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Total</td>
                                                    <td>{Detail.coins}</td>
                                                    <td>{Detail.currency_code} {(Detail.payout_amount + Detail.payout_fees)}</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Fees</td>
                                                    <td>-{Detail.payout_fees_coins}</td>
                                                    <td>-{Detail.currency_code} {(Detail.payout_fees)}</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Payout Amount</td>
                                                    <td>{Detail.payout_amount_coins}</td>
                                                    <td>{Detail.currency_code} {(Detail.payout_amount)}</td>
                                                    <td width="60px"><span onClick={copyLink.bind(this,Detail.payout_amount)}><i className="fa fa-copy text-primary pointer" style={{'cursor': 'pointer','fontSize' : '18px'}}></i></span></td>
                                                </tr>
                                            </table>

                                            <div className="text-center">
                                            {/* Gift Card */}
                                            {((Detail.status.toString() === '0' || Detail.status.toString() === '1') && (Detail.category === 'Gift-Card' || Detail.category === 'Game-Skin')) && <> 
                                                <button type="button" disabled={ButtonDisabled} onClick={send_gift_card} className="btn btn-success"> {ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Generate Gift Card</button></>}
                                            {(Detail.status.toString() === '2' &&(Detail.category === 'Gift-Card' || Detail.category === 'Game-Skin')) && <>
                                                 <button type="button" disabled={ButtonDisabled} onClick={send_gift_card_email} className="btn btn-success"> {ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Send Email</button>
                                            </>}
                                            
                                            {/* mobile Recharge */}
                                            {((Detail.status.toString() === '0' || Detail.status.toString() === '1') && Detail.category === 'Mobile-Top-Up') && <> 
                                                <button type="button" disabled={ButtonDisabled} onClick={recharge} className="btn btn-success"> {ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Mobile Recharge</button></>}
                                            
                                            {/* Payout Crypto */}
                                            {((Detail.status.toString() === '0' || Detail.status.toString() === '1') && Detail.withdraw_type == 'crypto') && <><button type="button" disabled={ButtonDisabled} onClick={send_crypto} className="btn btn-success"> {ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Payout Crypto </button></>}
                                            
                                            {/* Payout Paypal */}
                                            {(Detail.status.toString() === '0' || Detail.status.toString() === '1') && Detail.enable_provider === 'paypal' && <button type="button" disabled={ButtonDisabled} onClick={payout_paypal} className="btn btn-success"> {ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Payout Paypal </button>}


                                            </div>
                                        </Col>
                                        <Col lg="4">
                                            {(Detail.status.toString() === '0' || Detail.status.toString() === '1') && <>
                                            <form className="av-invalid" onSubmit={handleSubmit}>
                                                <Row>
                                                    <Col lg="12">
                                                        <h5 className="mb-3">Update Status :</h5>
                                                        <div className="form-group mb-3">
                                                            <label className="form-label">Status</label>
                                                            <Select
                                                                name="status"
                                                                defaultValue={SelectStatus}
                                                                isMulti={false}
                                                                options={StatusList}
                                                                onChange={handleSelectChange.bind(this, 'status')}
                                                                classNamePrefix="select2-selection"
                                                                isLoading={false}
                                                            />
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <label className="form-label">Note</label>
                                                            <textarea rows={5} name="note" value={form_inputs.note} onChange={handleInputChange} className="form-control" />
                                                        </div>
                                                        <div className="text-center mt-4">
                                                            <Button disabled={ButtonDisabled} type="submit" color="primary">{ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Update</Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </form></>}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

        </React.Fragment>
    )
}

export default withRouter(UserProfile)
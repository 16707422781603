import MetaTags from "react-meta-tags"
import React, { useState, useEffect, useRef } from "react"
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap"
import { useHistory, withRouter, Link } from "react-router-dom"

import SimpleReactValidator from 'simple-react-validator';
import { post } from '../../helpers/api_helper';
import { POST_GET_REWARDS_DETAIL, POST_ADD_EDIT_REWARDS } from '../../helpers/url_helper';
import { IsModemAccess } from '../../helpers/helper';
import { toast } from 'react-toastify';


//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"


const UserProfile = props => {
    let history = useHistory();

    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [title, setTitle] = useState('Add Reward');
    const [form_inputs, setInputs] = useState({ reward_id: 0, day: '', name: '', min_coins: '', max_coins: '', type: '' });
    const [, forceUpdate] = useState();

    useEffect(() => {
        async function get_detaile() {
            if (props.match.params.id) {
                if (!IsModemAccess('rewards', 'edit')) {
                    toast('Does not permission this module');
                    history.push("/");
                }
                setTitle('Edit Reward');
                var res_data = await post(POST_GET_REWARDS_DETAIL, { 'reward_id': props.match.params.id }, {});
                if (res_data.status) {
                    setInputs(res_data.detail);
                } else {
                    toast.error(res_data.message);
                    history.push("/");
                }
            } else {
                if (!IsModemAccess('rewards', 'add')) {
                    toast('Does not permission this module');
                    history.push("/");
                }
            }
        }
        get_detaile();
    }, [props.match.params.id])

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_ADD_EDIT_REWARDS, form_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
                history.push("/rewards");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{title} | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem={title} />

                    <Row>
                        <Col lg="6">
                            <Card>
                                <CardBody>
                                    <form className="av-invalid" onSubmit={handleSubmit}>
                                        <div className="form-group mb-3">
                                            <label className="form-label">Name</label>
                                            <input name="name" value={form_inputs.name} onChange={handleInputChange} className="form-control" placeholder="Enter Name" type="text" />
                                            {simpleValidator.current.message('name', form_inputs.name, 'required')}
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label">Day</label>
                                            <input name="day" maxLength="4" value={form_inputs.day} onChange={handleInputChange} className="form-control" placeholder="Enter Phone Code" type="text" />
                                            {simpleValidator.current.message('day', form_inputs.day, 'required|numeric')}
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Type</label>
                                                    <select name="type" value={form_inputs.type} onChange={handleInputChange}  >
                                                        <option value="">Select Type</option>
                                                        <option value="coins">Coins</option>
                                                        <option value="Offer">Offer</option>
                                                    </select>
                                                    {simpleValidator.current.message('type', form_inputs.type, 'required')}
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Min Completed Coins / Offer</label>
                                                    <input name="type_value" value={form_inputs.type_value} onChange={handleInputChange} className="form-control" placeholder="Enter value" type="text" />
                                                    {simpleValidator.current.message('value', form_inputs.type_value, 'required|numeric')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Min Coins</label>
                                                    <input name="min_coins" value={form_inputs.min_coins} onChange={handleInputChange} className="form-control" placeholder="Enter Min Coins" type="text" />
                                                    {simpleValidator.current.message('min', form_inputs.min_coins, 'required|numeric')}
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Max Coins</label>
                                                    <input name="max_coins" value={form_inputs.max_coins} onChange={handleInputChange} className="form-control" placeholder="Enter Max Coins" type="text" />
                                                    {simpleValidator.current.message('max', form_inputs.max_coins, 'required|numeric')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center mt-4">
                                            <Button disabled={ButtonDisabled} type="submit" color="primary">{ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Save</Button>
                                            <Link to="/rewards" className="btn btn-danger ms-3"> Cancel</Link>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(UserProfile)

import MetaTags from "react-meta-tags"
import React, { useState, useEffect, useRef } from "react"
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap"
import { useHistory, withRouter, Link } from "react-router-dom"

import SimpleReactValidator from 'simple-react-validator';
import { post, get } from '../../helpers/api_helper';
import { POST_GET_WITHDRAWAL_OPTIONS_DETAIL, POST_GET_PRODUCT_TREMENDOUS, POST_GET_PRODUCT_RELOADLY, POST_ADD_EDIT_WITHDRAWAL_OPTIONS, POST_GET_WITHDRAWAL_FORM_OPTION } from '../../helpers/url_helper';
import { IsModemAccess, MultiSelectValueArray, arrayColumn } from '../../helpers/helper';
import { toast } from 'react-toastify';
import Loader from './../../components/Common/Loader';

import 'react-quill/dist/quill.snow.css'
import Select from "react-select"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

const UserProfile = props => {
    let history = useHistory();

    const [GiftCardProvider] = useState([
        { 'value': 'reloadly', 'label': 'Reloadly' },
        // { 'value': 'tremendous', 'label': 'Tremendous' },
        { 'value': 'paypal', 'label': 'Paypal' }
    ]);
    const [CountriesList, setCountriesList] = useState([]);
    const [SelectCountriesList, setSelectCountriesList] = useState([]);
    const [SelectCountries, setSelectCountries] = useState([]);
    const [isLoader, setLoader] = useState(0);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [title, setTitle] = useState('Add Withdrawal Options');
    const [exchange_options, setexchange_options] = React.useState([]);
    const [form_inputs, setInputs] = useState({ id: 0, withdraw_type: 0, name: '', category: '', exchange_options: [], country: '', description: '', terms: '', logo_url: '', is_mobile_data_plan: 0 });
    const [, forceUpdate] = useState();
    const [ProductListTremendous, setProductListTremendous] = React.useState([]);
    const [ProductListReloadly, setProductListReloadly] = React.useState([]);
    const [loaderShow, setloaderShow] = React.useState(false);
    const [LoadMoreProduct, setLoadMoreProduct] = React.useState(false);
    const [page, Setpage] = React.useState(1);
    const [Search, SetSearch] = React.useState('');

    var countrie_list = [];

    useEffect(() => {
        async function get_detaile() {
            await get_form_option();
            if (props.match.params.id) {
                if (!IsModemAccess('withdrawal_options', 'edit')) {
                    toast('Does not permission this module');
                    history.push("/");
                }
                setTitle('Edit Withdrawal Options');
                var res_data = await post(POST_GET_WITHDRAWAL_OPTIONS_DETAIL, { 'withdrawal_option_id': props.match.params.id }, {});
                if (res_data.status) {
                    await setexchange_options(res_data.detail.exchange_options);
                    await setSelectCountries(await MultiSelectValueArray(countrie_list, res_data.detail.country));
                    await get_select_country(JSON.parse("[" + res_data.detail.country + "]"), countrie_list);
                    setInputs(res_data.detail);
                } else {
                    toast.error(res_data.message);
                    history.push("/");
                }
            } else {
                if (!IsModemAccess('withdrawal_options', 'add')) {
                    toast('Does not permission this module');
                    history.push("/");
                }
            }
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])

    //get form option
    const get_form_option = async () => {
        var res_data = await get(POST_GET_WITHDRAWAL_FORM_OPTION);
        if (res_data.status) {
            countrie_list = res_data.countrie_list;
            setCountriesList(countrie_list);
        }
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            form_inputs.exchange_options = (exchange_options.length > 0) ? exchange_options : [];
            var res_data = await post(POST_ADD_EDIT_WITHDRAWAL_OPTIONS, form_inputs);
            if (res_data.status) {
                toast(res_data.message);
                history.push("/withdrawal-options");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name === 'logo') {
            var file = event.target.files[0];
            var reader = new FileReader();
            reader.onloadend = function () {
                setInputs(inputs => ({ ...form_inputs, ['logo']: reader.result }));
            }
            reader.readAsDataURL(file);
        } else if (event.target.name === 'is_mobile_data_plan') {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: (event.target.checked) ? 1 : 0 }));
        } else {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }


    const handleInputChangeMethod = (e, index) => {
        var name = e.target.name;
        var value = e.target.value;
        const list = [...exchange_options];
        list[index][name] = value;
        setexchange_options(list);
    };

    const addRow = () => {
        setexchange_options([...exchange_options, {
            "countries": "",
            "gift_card_product_id": "",
            "title": "",
            "currency_code": "",
            "amount": "",
            "fee": "",
            "total_coins": ""
        }]);
    }

    let RemoveRow = (i) => {
        const values = [...exchange_options];
        values.splice(i, 1);
        setexchange_options(values)
    }

    const handleMultiSelectChange = async (name, value) => {
        var values = arrayColumn(value, 'value');
        await setInputs(inputs => ({ ...form_inputs, [name]: values.join() }));
        await get_select_country(values);
    }

    const get_select_country = (select_countrie_id, countrie_list = []) => {
        countrie_list = (countrie_list.length > 0) ? countrie_list : CountriesList;
        var select_country = [];
        for (var countries of countrie_list) {
            if (select_countrie_id.indexOf(countries.value) >= 0) {
                select_country.push(countries);
            }
        }
        setSelectCountriesList(select_country);
    }

    //get product tremendous
    const get_product_tremendous = async () => {
        setloaderShow(true);
        setProductListTremendous([]);
        var res_data = await post(POST_GET_PRODUCT_TREMENDOUS);
        if (res_data.status) {
            setProductListTremendous(res_data.data.product_list);
        }
        setloaderShow(false);
    }

    //get product tremendous
    const get_product_reloadly = async (load_more = false) => {
        setloaderShow(true);
        var page_no = (load_more) ? (parseInt(page) + parseInt(1)) : 1;
        Setpage(page_no);
        var res_data = await post(POST_GET_PRODUCT_RELOADLY, { page: page_no, search : Search });
        if (res_data.status) {
            if (load_more) {
                var product = ProductListReloadly.concat(res_data.data.product_list);
                setProductListReloadly(product);
            } else {
                setProductListReloadly(res_data.data.product_list);
            }
            setLoadMoreProduct(res_data.data.load_more)
        }
        setloaderShow(false);
    }

    const load_more_product = async (page = 1) => {
        // Setpage(parseInt(page) + parseInt(1))
        get_product_reloadly(true);
    }

    if (!isLoader) {
        return (<Loader></Loader>);
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{title} | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem={title} />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <form className="av-invalid" onSubmit={handleSubmit}>
                                        <Row>
                                            <Col lg="6">
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Withdrawal Type</label>
                                                    <select name="withdraw_type" value={form_inputs.withdraw_type} onChange={handleInputChange} className="form-control">
                                                        <option value="">Select withdraw Type</option>
                                                        <option value="cash">Cash</option>
                                                        <option value="crypto">Crypto</option>
                                                    </select>
                                                    {simpleValidator.current.message('Withdrawal type', form_inputs.withdraw_type, 'required')}
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Category</label>
                                                    <select name="category" value={form_inputs.category} onChange={handleInputChange} className="form-control">
                                                        <option value="">Select Category</option>
                                                        <option value="Cash">Cash</option>
                                                        <option value="UPI">UPI</option>
                                                        <option value="Gift-Card">Gift Card</option>
                                                        <option value="Game-Skin">Game Skin</option>
                                                        <option value="Mobile-Top-Up">Mobile-Top-Up</option>
                                                    </select>
                                                    {simpleValidator.current.message('category', form_inputs.category, 'required')}
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Title</label>
                                                    <input name="name" value={form_inputs.name} onChange={handleInputChange} className="form-control" placeholder="Enter Title" type="text" />
                                                    {simpleValidator.current.message('title', form_inputs.name, 'required')}
                                                </div>
                                                {form_inputs.category === 'Mobile-Top-Up' && <div className="row">
                                                    <div className="col-sm-6 form-group mb-3">
                                                        <label className="form-label">Minimum Coins</label>
                                                        <input name="crypto_minimum_coins" value={form_inputs.crypto_minimum_coins} onChange={handleInputChange} className="form-control" placeholder="Enter Minimum Coins" type="text" />
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <label className="form-label">Is Mobile Data Plan</label>
                                                        <div className="form-check form-switch form-switch-md mt-0" dir="ltr">
                                                            <input name="is_mobile_data_plan" onChange={(event) => handleInputChange(event)} defaultChecked={form_inputs.is_mobile_data_plan} type="checkbox" className="form-check-input" />
                                                        </div>
                                                    </div>
                                                </div>}
                                                {(form_inputs.withdraw_type === 'crypto' || form_inputs.category === 'UPI') && <>
                                                    <div className="row">
                                                        <div className="col-sm-6 form-group mb-3">
                                                            <label className="form-label">{(form_inputs.withdraw_type === 'crypto')? 'Cryptocurrency Code' : 'Currency Code'}</label>
                                                            <input name="code" value={form_inputs.code} onChange={handleInputChange} className="form-control" placeholder="Enter Code" type="text" />
                                                        </div>
                                                        <div className="col-sm-6 form-group mb-3">
                                                            <label className="form-label">Fee (%)</label>
                                                            <input name="crypto_fee_percentage" value={form_inputs.crypto_fee_percentage} onChange={handleInputChange} className="form-control" placeholder="Enter Fee" type="text" />
                                                        </div>
                                                        <div className="col-sm-6 form-group mb-3">
                                                            <label className="form-label">Minimum Coins</label>
                                                            <input name="crypto_minimum_coins" value={form_inputs.crypto_minimum_coins} onChange={handleInputChange} className="form-control" placeholder="Enter Minimum Coins" type="text" />
                                                        </div>
                                                        <div className="col-sm-6 form-group mb-3">
                                                            <label className="form-label">Maximum Coins</label>
                                                            <input name="crypto_maximum_coins" value={form_inputs.crypto_maximum_coins} onChange={handleInputChange} className="form-control" placeholder="Enter Maximum Coins" type="text" />
                                                        </div>
                                                    </div>
                                                </>}
                                                {(form_inputs.withdraw_type === 'cash' && form_inputs.category !== 'UPI'  ) && <>
                                                    <div className="form-group mb-3">
                                                        <label className="form-label">Countries</label>
                                                        <Select
                                                            name="country"
                                                            defaultValue={SelectCountries}
                                                            isMulti={true}
                                                            options={CountriesList}
                                                            onChange={handleMultiSelectChange.bind(this, 'country')}
                                                            classNamePrefix="select2-selection"
                                                            isLoading={false}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <label className="form-label">Enable Provider</label>
                                                        <select name="enable_provider" value={form_inputs.enable_provider} onChange={handleInputChange} className="form-control">
                                                            <option value="">Select Provider</option>
                                                            {Object.entries(GiftCardProvider).map(([key, value]) => (
                                                                <option key={key} value={value.value}>{value.label}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </>}
                                                <Row>
                                                    <Col lg="8">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label">Logo <small>Accept only png, jpg and jpeg image (Size 210px X 120px)</small></label>
                                                            <input name="logo" onChange={handleInputChange} className="form-control" placeholder="Select png Image" type="file" accept="image/png,image/jpg,image/jpeg" />
                                                        </div>
                                                    </Col>
                                                    <Col lg="4" className="mt-3">
                                                        {form_inputs.logo_url && <img alt="Offerwall" src={form_inputs.logo_url} style={{ 'maxWidth': '150px' }} />}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg="6">
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Description</label>
                                                    <textarea rows="5" name="description" value={form_inputs.description} onChange={handleInputChange} className="form-control" placeholder="Enter Description" />
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Terms</label>
                                                    <textarea rows="5" name="terms" value={form_inputs.terms} onChange={handleInputChange} className="form-control" placeholder="Enter Terms" />
                                                </div>
                                            </Col>
                                        </Row>

                                        {(form_inputs.withdraw_type === 'cash' && form_inputs.category !== 'Mobile-Top-Up' && form_inputs.category !== 'UPI') && <Row>
                                            <Col lg="6" className="mt-4 mb-3">
                                                <h5 className="">Withdrawal Fee</h5>
                                            </Col>
                                            <Col lg="6" className="mt-4 mb-3 text-right">
                                                <button type="button" data-toggle="modal" onClick={() => { Setpage(1); get_product_reloadly(false); }} data-target="#GetProductReloadlyModal" className="btn btn-sm btn-success mr-3">Get Product Reloadly</button>

                                                {/* <button type="button" data-toggle="modal" onClick={get_product_tremendous} data-target="#GetProductTremendousModal" className="btn btn-sm btn-success ">Get Product Tremendous</button> */}
                                            </Col>
                                            <Col lg="12">

                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <td>Countries</td>
                                                            <td>Provider</td>
                                                            <td className={(form_inputs.enable_provider == 'paypal' ? 'none' : '')}>Product Id</td>
                                                            <td>Title</td>
                                                            <td>Currency Code</td>
                                                            <td>Amount</td>
                                                            <td>Fee</td>
                                                            <td>Fee Coins</td>
                                                            <td>Total Coins</td>
                                                            <td><button type="button" onClick={addRow} className="btn btn-primary btn-sm w-xs">+Add</button></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {exchange_options && Object.entries(exchange_options).map(([key, value]) => (
                                                            <tr key={key}>
                                                                <td>
                                                                    <select name="countries" value={value.countries} onChange={e => handleInputChangeMethod(e, key)} className="form-control">
                                                                        <option value="">Select Countries</option>
                                                                        {Object.entries(SelectCountriesList).map(([key, value]) => (
                                                                            <option key={key} value={value.value}>{value.label}</option>
                                                                        ))}
                                                                    </select>
                                                                    {simpleValidator.current.message('countries', value.countries, 'required')}
                                                                </td>
                                                                <td>
                                                                    <select name="provider" value={value.provider} onChange={e => handleInputChangeMethod(e, key)} className="form-control">
                                                                        <option value="">Select Provider</option>
                                                                        {Object.entries(GiftCardProvider).map(([key, value]) => (
                                                                            <option key={key} value={value.value}>{value.label}</option>
                                                                        ))}
                                                                    </select>
                                                                    {simpleValidator.current.message('provider', value.provider, 'required')}
                                                                </td>
                                                                <td className={(form_inputs.enable_provider == 'paypal' ? 'none' : '')}>
                                                                    <input name="gift_card_product_id" value={value.gift_card_product_id} onChange={e => handleInputChangeMethod(e, key)} className="form-control" placeholder="Enter Product Id" type="text" />
                                                                    {simpleValidator.current.message('product id', value.gift_card_product_id, (form_inputs.enable_provider == 'paypal')? '' :'required')}
                                                                </td>
                                                                <td>
                                                                    <input name="title" value={value.title} onChange={e => handleInputChangeMethod(e, key)} className="form-control" placeholder="Enter title" type="text" />
                                                                    {simpleValidator.current.message('title', value.title, 'required')}
                                                                </td>
                                                                <td>
                                                                    <input name="currency_code" value={value.currency_code} onChange={e => handleInputChangeMethod(e, key)} className="form-control" placeholder="Enter currency code" type="text" />
                                                                    {simpleValidator.current.message('currency code', value.currency_code, 'required')}
                                                                </td>
                                                                <td>
                                                                    <input name="amount" maxLength="16" value={value.amount} onChange={e => handleInputChangeMethod(e, key)} className="form-control" placeholder="Enter amount" type="text" />
                                                                    {simpleValidator.current.message('amount', value.amount, 'required|numeric')}
                                                                </td>
                                                                <td>
                                                                    <input name="fee" maxLength="16" value={value.fee} onChange={e => handleInputChangeMethod(e, key)} className="form-control" placeholder="Enter Fee" type="text" />
                                                                    {simpleValidator.current.message('fee', value.fee, 'required|numeric')}
                                                                </td>
                                                                <td>
                                                                    <input name="fee_coins" maxLength="16" value={value.fee_coins} onChange={e => handleInputChangeMethod(e, key)} className="form-control" placeholder="Enter Fee Coins" type="text" />
                                                                    {simpleValidator.current.message('fee coins', value.fee_coins, 'required|numeric')}
                                                                </td>
                                                                <td>
                                                                    <input name="total_coins" maxLength="16" value={value.total_coins} onChange={e => handleInputChangeMethod(e, key)} className="form-control" placeholder="Enter total coins" type="text" />
                                                                    {simpleValidator.current.message('total_coins', value.total_coins, 'required|numeric')}
                                                                </td>
                                                                <td>
                                                                    <button type="button" className="btn btn-danger btn-sm" onClick={() => RemoveRow(key)}>Remove</button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </Col>
                                        </Row>}
                                        <div className="text-center mt-4">
                                            <Button disabled={ButtonDisabled} type="submit" color="primary">{ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Save</Button>
                                            <Link to="/withdrawal-options" className="btn btn-danger ms-3"> Cancel</Link>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="modal fade" id="GetProductTremendousModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Product List</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {loaderShow && <i className="fas fa-spinner me-2 fa-spin"></i>}
                            {!loaderShow && <>
                                <div className="row">
                                    <div className="col-sm-12" style={{ "height": "73vh", "overflow": "auto" }}>
                                        <table className="price-table">
                                            <thead>
                                                <tr>
                                                    <td>Product Id</td>
                                                    <td>Image</td>
                                                    <td>Name</td>
                                                    <td>country</td>
                                                    <td>currency</td>
                                                    <td width={150}>Varied</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {ProductListTremendous && Object.entries(ProductListTremendous).map(([key, value]) => (
                                                    <tr key={key}>
                                                        <td>{value.id}</td>
                                                        <td>
                                                            <img src={(value.images.length > 0) ? value.images[0].src : ''} width="150" />
                                                        </td>
                                                        <td>{value.name}</td>
                                                        <td>
                                                            {value.countries.length > 0 && Object.entries(value.countries).map(([key, countries]) => (
                                                                <span key={key}> {countries.abbr} , </span>
                                                            ))}
                                                        </td>
                                                        <td>
                                                            {value.currency_codes.join(' , ')}
                                                        </td>
                                                        <td>
                                                            {value.skus.length > 0 && Object.entries(value.skus).map(([key, skus]) => (<>
                                                                <span key={key}> {skus.min} - {skus.max} </span><br />
                                                            </>))}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </>}
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="GetProductReloadlyModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="row" style={{'width' : '100%'}}>
                                <div className="col-sm-8">
                                    <h5 className="modal-title" id="exampleModalLabel">Product List</h5>
                                </div>
                                <div className="col-sm-4">
                                    <div class="input-group">
                                        <input type="text" name="search" onChange={(event) => { SetSearch(event.target.value); }} class="form-control" placeholder="search" />
                                        <div class="input-group-append" onClick={() => { Setpage(1); get_product_reloadly(false); }}>
                                            <span class="input-group-text"><i class="fas fa-search"></i></span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            {(loaderShow && !LoadMoreProduct) && <i className="fas fa-spinner me-2 fa-spin"></i>}
                            <div className="row">
                                <div className="col-sm-12" style={{ "height": "73vh", "overflow": "auto" }}>
                                    <table className="price-table">
                                        <thead>
                                            <tr>
                                                <td>Product Id</td>
                                                <td>Image</td>
                                                <td>Name</td>
                                                <td>currency</td>
                                                <td width={150}>Varied</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ProductListReloadly && Object.entries(ProductListReloadly).map(([key, value]) => (
                                                <tr key={key}>
                                                    <td>{value.productId}</td>
                                                    <td>
                                                        <img src={value.logoUrls} width="150" />
                                                    </td>
                                                    <td>{value.productName}</td>
                                                    <td>
                                                        {value.recipientCurrencyCode}
                                                    </td>
                                                    <td>
                                                        <span>{value.fixedRecipientDenominations.join(' , ')}</span>
                                                        {!value.fixedRecipientDenominations.length && <span>{value.minRecipientDenomination} - {value.maxRecipientDenomination}</span>}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="text-center mt-3"> {LoadMoreProduct}
                                        {!LoadMoreProduct && <button onClick={load_more_product.bind(this, 1)} className="btn btn-sm btn-success">Load More</button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default withRouter(UserProfile)

import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { useHistory, withRouter, Link } from "react-router-dom"

import { getToken,getUserId,IsModemAccess } from '../../helpers/helper';
import {del,post} from '../../helpers/api_helper';
import { POST_GET_ALL_ADMIN_USER,DELETE_ADMIN_USER,POST_UPDATE_ADMIN_STATUS } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import ReactDOM from 'react-dom';

import $ from 'jquery'; 
import "datatables.net-dt/js/dataTables.dataTables";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"


const UserProfile = props => {
    let history = useHistory();
    const [user_role_id, SetUserRoleId] = useState(0);

    useEffect(() => {
        get_admin_user();
    }, [])

    // get user role list
    const get_admin_user = () => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": POST_GET_ALL_ADMIN_USER,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [4,5] }, { "targets": 0, "visible": false }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "id" },
                    { "data": "username" },
                    { "data": "role_name" },
                    { "data": "email" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {data.user_status === 1 && <button onClick={UpdateStatus.bind(this,data.id,data.user_status)}  className="border-0 badge badge-success fs-6"  >Active</button>}
                                {data.user_status === 0 && <button onClick={UpdateStatus.bind(this,data.id,data.user_status)} className="border-0 badge badge-danger fs-6" >Deactive</button>}
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {IsModemAccess('admin','edit') && <button className="btn btn-soft-success waves-effect waves-light p-1 mr-2" onClick={() => { history.push('/edit-admin/' + data.id); }} ><i className="bx bx-edit align-middle"></i></button>}
                                {(getUserId() !== data.id && IsModemAccess('admin','delete')) && <button className="btn btn-soft-danger waves-effect waves-light p-1 mr-2" onClick={() => { SetUserRoleId(data.id); }} data-toggle="modal" data-target="#deleteModal" ><i className="bx bx-trash-alt align-middle"></i></button>}
                            </>, td)
                        }
                    },
                ]
            });
        });
    }

    // remove user role
    const remove_row = async() => {
        var res_data =  await del(DELETE_ADMIN_USER+"?user_id="+user_role_id);
        if(res_data.status){
            toast(res_data.message);
            get_admin_user();
        }else{
            toast.error(res_data.message);
        }
    }

    //UpdateStatus
    const UpdateStatus = async(user_id,status) => {
        if(!IsModemAccess('user','edit')){
            toast('Does not permission this module');
            return true;
        }
        var res_data =  await post(POST_UPDATE_ADMIN_STATUS,{user_id:user_id,status:status});
        if(res_data.status){
            toast(res_data.message);
            get_admin_user();
        }else{
            toast.error(res_data.message);
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Admin User | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem="Admin User" />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h5>Admin User List</h5>
                                        </div>
                                        <div className="col-sm-6 text-end">
                                        {IsModemAccess('admin','add') && <Link to="/add-admin" className="btn btn-primary btn-sm w-xs">+ Add</Link>}
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="mt-4 mb-5">
                                        {IsModemAccess('admin','view') && <table id="dataTableExample" className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <td>Id</td>
                                                    <td>User Name</td>
                                                    <td>Role</td>
                                                    <td>Email</td>
                                                    <td>Status</td>
                                                    <td>Action</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                
                                            </tbody>
                                        </table>}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Confirm Delete</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this record?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">No</button>
                            <button onClick={remove_row.bind(this)} data-dismiss="modal" className="btn btn-primary">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

    )
}

export default withRouter(UserProfile)

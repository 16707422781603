import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { withRouter,Link } from "react-router-dom"

import { getToken, IsModemAccess, DateUTCToLocal } from '../../helpers/helper';
import { del } from '../../helpers/api_helper';
import { POST_GET_ALL_NOTIFICATION, DELETE_NOTIFICATION} from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import ReactDOM from 'react-dom';

import $ from 'jquery';
import "flatpickr/dist/themes/material_blue.css"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"


const UserProfile = props => {

    const [notification_id, SetNotificationId] = useState(0);
    
    useEffect(() => {
        async function get_detaile() {
            get_list();
        }
        get_detaile();
    }, [])

    // get user role list
    const get_list = () => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": POST_GET_ALL_NOTIFICATION,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [5] }, { "targets": 0, "visible": false }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "app_notification_id" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<> {DateUTCToLocal(data.created_at)}</>, td)
                        }
                    },
                    { "data": "notification_title" },
                    { "data": "notification_message" },
                    { "data": "notification_criteria" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {/* {IsModemAccess('notification','view') && <button className="btn btn-soft-info waves-effect waves-light p-1 mr-2" onClick={() => {  history.push('/notifications-detail/' + data.app_notification_id);}} ><i className="fas fa-eye align-middle"></i></button>} */}
                                {IsModemAccess('notification','delete') && <button className="btn btn-soft-danger waves-effect waves-light p-1 mr-2" onClick={() => { SetNotificationId(data.app_notification_id); }} data-toggle="modal" data-target="#deleteModal" ><i className="bx bx-trash-alt align-middle"></i></button>}
                            </>, td)
                        }
                    },
                ]
            });
        });
    }

    // remove user role
    const remove_row = async() => {
        var res_data =  await del(DELETE_NOTIFICATION+"?notification_id="+notification_id);
        if(res_data.status){
            toast(res_data.message);
            get_list();
        }else{
            toast.error(res_data.message);
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Notifications List | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem="Notifications List" />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h5>Notifications List</h5>
                                        </div>
                                        <div className="col-sm-6 text-end">
                                            {IsModemAccess('notification','add') && <Link to="send-notification" className="btn btn-primary btn-sm w-xs">+ Add</Link>}
                                        </div>
                                    </div>
                                    <hr />
                                   
                                    <div className="mt-4 mb-5">
                                        {IsModemAccess('notification', 'view') && <table id="dataTableExample" className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <td>Id</td>
                                                    <td>Date</td>
                                                    <td>Title</td>
                                                    <td>Message</td>
                                                    <td>Criteria</td>
                                                    <td>Action</td>
                                                </tr>
                                            </thead>
                                            <tbody>

                                            </tbody>
                                        </table>}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Confirm Delete</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this record?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">No</button>
                            <button onClick={remove_row.bind(this)} data-dismiss="modal" className="btn btn-primary">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default withRouter(UserProfile)

import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { withRouter, useHistory } from "react-router-dom"
import { get } from '../../helpers/api_helper';
import { getToken, IsModemAccess, DateUTCToLocal } from '../../helpers/helper';
import { POST_API_LOG_LIST, GET_API_LOG_FILTER_OPTION } from '../../helpers/url_helper';
import ReactDOM from 'react-dom';
import $ from 'jquery';

import "flatpickr/dist/themes/material_blue.css"
import AsyncSelect from 'react-select/async';
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"


const UserProfile = props => {
    let history = useHistory();

    const [inputValue, setValue] = useState('');
    const [UserId, SetUserId] = useState('');
    var form_inputs = { user_id: '' };


    useEffect(() => {
        async function get_detaile() {
            get_list();
        }
        get_detaile();
    }, [])

    // get user role list
    const get_list = () => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": POST_API_LOG_LIST + '?user_id=' + form_inputs.user_id,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [6] }, { "targets": 0, "visible": false }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "id" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<> {DateUTCToLocal(data.created_at)}</>, td)
                        }
                    },
                    { "data": "user_name" },
                    { "data": "method" },
                    { "data": "device_type" },
                    { "data": "url" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                <button className="btn btn-soft-info waves-effect waves-light p-1 mr-2" onClick={() => { history.push('/api-logs-detail/' + data.id); }} ><i className="fas fa-eye align-middle"></i></button>
                            </>, td)
                        }
                    },
                ]
            });
        });
    }

    const applyFilter = async () => {
        form_inputs = { user_id: UserId };
        await get_list();
    }

    const fetchData = async () => {
        console.log(inputValue);
        return await get(GET_API_LOG_FILTER_OPTION +'?s='+inputValue).then(result => {
            const res = result.user_list;
            return res;
        });
    }
    // handle input change event
    const handleInputChange = value => {
        setValue(value);
    };

    const handleSelectChange = async (name, value) => {
        var values = (value && value.value) ? value.value : '';
        await SetUserId(values);
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Api Log | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem="Api Log" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg="3"></Col>
                                        <Col lg="3 mt-3">
                                            <AsyncSelect
                                                name="user_id"
                                                isClearable={true}
                                                isMulti={false}
                                                loadOptions={fetchData}
                                                onInputChange={handleInputChange}
                                                onChange={handleSelectChange.bind(this, 'user_id')}
                                                classNamePrefix="select2-selection"
                                                isLoading={false}
                                            />
                                        </Col>
                                        <Col lg="1 mt-3">
                                            <button onClick={applyFilter.bind(this)} className="btn btn-primary w-xs">Apply</button>
                                        </Col>
                                    </Row>
                                    <div className="mt-4 mb-5">
                                        {IsModemAccess('api_logs', 'view') && <table id="dataTableExample" className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <td>Id</td>
                                                    <td>Date</td>
                                                    <td>User Name</td>
                                                    <td>Method</td>
                                                    <td>Device Type</td>
                                                    <td>Url</td>
                                                    <td>Action</td>
                                                </tr>
                                            </thead>
                                            <tbody>

                                            </tbody>
                                        </table>}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(UserProfile)

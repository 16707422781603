import MetaTags from "react-meta-tags"
import React, { useState,useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"

// availity-reactstrap-validation
import { withRouter,useHistory, Link } from "react-router-dom"

import { get } from '../../helpers/api_helper';
import { IsModemAccess,DateUTCToLocal } from '../../helpers/helper';
import { POST_GET_TRANSACTION_DETAIL } from '../../helpers/url_helper';
import { toast } from 'react-toastify';

import Loader from './../../components/Common/Loader';
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

const UserProfile = props => {
    let history = useHistory();

    const [isLoader, setLoader] = useState(0);
    const [Detail, setDetail] = useState();

    useEffect(() => {
        async function get_detaile() {
            if (props.match.params.id) {
                if(!IsModemAccess('transactions','view')){
                    toast('Does not permission this module');
                    history.push("/");
                }
                
                var res_data = await get(POST_GET_TRANSACTION_DETAIL+'?transactions_id='+props.match.params.id);
                if (res_data.status) {
                    setDetail(res_data.detail);
                } else {
                    toast.error(res_data.message);
                    history.push("/");
                }
            }
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])

    if (!isLoader) {
        return (<Loader></Loader>);
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Transactions Detail | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem="Transactions Detail" />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">Transaction Id :</h5>
                                            <span>{Detail.txn_id}</span>
                                        </Col>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">Transaction Date :</h5>
                                            <span>{DateUTCToLocal(Detail.created_at)}</span>
                                        </Col>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">User Name :</h5>
                                            <span><Link onClick={() => { window.open('/user-detail/' + Detail.user_id, "_blank").focus(); }}  to="#">{Detail.username}</Link></span>
                                        </Col>
                                        {Detail.offer_log_id > 0 && <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">Offer Log Id :</h5>
                                            <span><Link onClick={() => { window.open('/offerwall-logs-detail/' + Detail.offer_log_id, "_blank").focus(); }}  to="#">{Detail.offer_log_id}</Link></span>
                                        </Col>}
                                        {Detail.withdrawal_req_id > 0 && <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">Withdrawal Request Id :</h5>
                                            <span><Link onClick={() => { window.open('/withdrawal-request-detail/' + Detail.withdrawal_req_id, "_blank").focus(); }}  to="#">{Detail.withdrawal_req_id}</Link></span>
                                        </Col>}
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">Email Address :</h5>
                                            <span>{Detail.email}</span>
                                        </Col>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">Title :</h5>
                                            <span>{Detail.txn_title}</span>
                                        </Col>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">Transaction For :</h5>
                                            <span className="text-capitalize">{Detail.txn_for}</span>
                                        </Col>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">Transaction Type :</h5>
                                            <span className="text-capitalize">{Detail.txn_type}</span>
                                        </Col>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">Amount :</h5>
                                            <span>{Detail.amount}</span>
                                        </Col>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">Status :</h5>
                                            {Detail.status === 0 && <span className="text-danger">Pending</span>}
                                            {Detail.status === 1 && <span className="text-success">Completed</span>}
                                            {Detail.status === 7 && <span className="text-danger">Cancelled</span>}
                                            {Detail.status === 7 && <span className="text-danger">Deleted</span>}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(UserProfile)

import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useRef } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { useHistory,withRouter, Link } from 'react-router-dom';
import {post} from '../../helpers/api_helper';
import {POST_LOGIN,POST_VERIFY_OTP} from '../../helpers/url_helper';
import {setUserSession,getToken} from '../../helpers/helper';
import { toast } from 'react-toastify';
// UI
import { Row, Col, Container } from "reactstrap"
import logo from "../../assets/images/logoweb.svg"

//Import config
import CarouselPage from "./CarouselPage"

const Login = props => {
    let history = useHistory();

    if(getToken()){
        history.push("/dashboard");
    }

    const simpleValidator = useRef(new SimpleReactValidator());
    const OtpValidator = useRef(new SimpleReactValidator());

    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [otp_screen, SetOTP_screen] = useState(false);
    const [login_inputs, setInputs] = useState({ email: '', password: '', otp : '', otp_screen : false });
    const [, forceUpdate] = useState();

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var md5 = require('md5');
            login_inputs.password = md5(login_inputs.password);
            var res_data =  await post(POST_LOGIN,login_inputs,{});
            if(res_data.status){
                SetOTP_screen(true);
                toast(res_data.message);
            }else{
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // form submit event
    const verifyOtp = async (event) => {
        event.preventDefault();
        const formValid = OtpValidator.current.allValid()
        if (!formValid) {
            OtpValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data =  await post(POST_VERIFY_OTP,login_inputs,{});
            if(res_data.status){
                setUserSession(res_data.data.token, res_data.data);
                history.push("/dashboard");
            }else{
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    const resend_otp =  async () => {
        var res_data =  await post(POST_LOGIN,login_inputs,{});
        if(res_data.status){
            toast(res_data.message);
        }else{
            toast.error(res_data.message);
        }
    }
 
    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...login_inputs, [event.target.name]: event.target.value }));
    }
    return (
        <React.Fragment>
            <MetaTags>
                <title>Login | Free Money Coins</title>
            </MetaTags>
            <div className="auth-page">
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <Col lg={3} md={5} className="col-xxl-3">
                            <div className="auth-full-page-content d-flex p-sm-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        <div className="mb-4 mb-md-5 text-center">
                                            <Link to="/dashboard" className="d-block auth-logo">
                                                <img src={logo} alt=""/> <span className="logo-txt"></span>
                                            </Link>
                                        </div>
                                        <div className="auth-content my-auto">
                                            <div className="text-center">
                                                <h5 className="mb-0">Welcome Back !</h5>
                                                <p className="text-muted mt-2">Sign in to continue to Free Money Coins.</p>
                                            </div>

                                            {!otp_screen && 
                                                <form className="custom-form mt-4 pt-2" onSubmit={handleSubmit}>
                                                    <div className="mb-3">
                                                        <label className="form-label">Email Address</label>
                                                        <input name="email" value={login_inputs.email} onChange={handleInputChange} className="form-control" placeholder="Enter Email Address" type="text" />
                                                        {simpleValidator.current.message('email', login_inputs.email, 'required|email')}
                                                    </div>
                                                    <div className="mb-3">
                                                        <div className="d-flex align-items-start">
                                                            <div className="flex-grow-1">
                                                                <label className="form-label">Password</label>
                                                            </div>
                                                            <div className="flex-shrink-0">
                                                                <div className="">
                                                                    <Link to="/forgot-password" className="text-muted">Forgot password?</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <input name="password" value={login_inputs.password} onChange={handleInputChange} type="password" className="form-control" placeholder="Enter Password" />
                                                            {simpleValidator.current.message('password', login_inputs.password, 'required')}
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <button disabled={ButtonDisabled} className="btn btn-primary w-100 waves-effect waves-light" type="submit"> {ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Log In</button>
                                                    </div>
                                                </form> 
                                            }

                                            {otp_screen && 
                                                <form className="custom-form mt-4 pt-2" onSubmit={verifyOtp}>
                                                    <div className="mb-3">
                                                        <label className="form-label">Otp</label>
                                                        <input name="otp" value={login_inputs.otp} onChange={handleInputChange} className="form-control" placeholder="OTP" type="text" />
                                                        {OtpValidator.current.message('otp', login_inputs.otp, 'required')}
                                                    </div>
                                                    <div className="mb-3">
                                                        <div className="flex-shrink-0">
                                                            <div className="text-right" style={{'text-align':'right'}}>
                                                                <Link onClick={resend_otp} to="#" className="text-muted">Resend otp</Link>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                    <div className="mb-3">
                                                        <button disabled={ButtonDisabled} className="btn btn-primary w-100 waves-effect waves-light" type="submit">{ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Verify</button>
                                                    </div>
                                                </form>
                                            }
                                        </div>
                                        <div className="mt-4 mt-md-5 text-center">
                                            <p className="mb-0">Copyright © {new Date().getFullYear()} FreeMoneyCoins.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <CarouselPage />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(Login)

Login.propTypes = {
    history: PropTypes.object,
}
import MetaTags from "react-meta-tags"
import React, { useState, useEffect, useRef } from "react"
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap"
import { useHistory, withRouter, Link } from "react-router-dom"

import SimpleReactValidator from 'simple-react-validator';
import { post,get } from '../../helpers/api_helper';
import { GET_ALL_SETTING, POST_UPDATE_SETTING } from '../../helpers/url_helper';
import { toast } from 'react-toastify';


//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"


const UserProfile = props => {
    let history = useHistory();

    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [title] = useState('Winning Amount');
    const [form_inputs, setInputs] = React.useState([]);
    const [, forceUpdate] = useState();

    useEffect(() => {
        async function get_detaile() {
            var res_data = await get(GET_ALL_SETTING + "?type=signup-winning-amount"); 
            if (res_data.status) {
                setInputs((res_data.data.signup_winning_amount) ? JSON.parse(res_data.data.signup_winning_amount) : []);
            } else {
                toast.error(res_data.message);
            }
        }
        get_detaile();
    }, [props.match.params.id])

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_UPDATE_SETTING, { 'signup_winning_amount': JSON.stringify(form_inputs)});
            if (res_data.status) {
                toast(res_data.message);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    const handleInputChangeMethod = (e, index) => {
        var name = e.target.name;
        var value = e.target.value;
        if(name == 'is_selected'){
            const list = [...form_inputs];
            list[index][name] = (e.target.checked) ? '1' : '0';
            console.log(list);
            setInputs(list);
        }else{
            const list = [...form_inputs];
            list[index][name] = value;
            setInputs(list);
        }
    };

    const addRow = () => {
        setInputs([...form_inputs, {
            "title": '',
            "coins": "",
            "is_selected": "",
        }]);
    }

    let RemoveRow = (i) => {
        const values = [...form_inputs];
        values.splice(i, 1);
        setInputs(values)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{title} | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem={title} />

                    <Row>
                        <Col lg="8">
                            <Card>
                                <CardBody>
                                    <form className="av-invalid" onSubmit={handleSubmit}>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <td>Title</td>
                                                    <td>Coins</td>
                                                    <td>Selected</td>
                                                    <td>
                                                        {form_inputs.length <=3 && <button type="button" onClick={addRow} className="btn btn-primary btn-sm w-xs">+Add</button>}
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {form_inputs && Object.entries(form_inputs).map(([key, value]) => (
                                                    <tr>
                                                        <td>
                                                            <input name="title" maxLength="16" value={value.title} onChange={e => handleInputChangeMethod(e, key)} className="form-control" placeholder="Enter Title" type="text" />
                                                            {simpleValidator.current.message('title', value.title, 'required')}
                                                        </td>
                                                        <td>
                                                            <input name="coins" maxLength="16" value={value.coins} onChange={e => handleInputChangeMethod(e, key)} className="form-control" placeholder="Enter Coins" type="text" />
                                                            {simpleValidator.current.message('coins', value.coins, 'required|numeric')}
                                                        </td>
                                                        <td>
                                                            <div className="form-check form-switch form-switch-md mt-0" dir="ltr">
                                                                <input name="is_selected" onChange={e => handleInputChangeMethod(e, key)} defaultChecked={(value.is_selected)? true : false} type="checkbox" className="form-check-input" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => RemoveRow(key)}>Remove</button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <div className="text-center mt-4">
                                            <Button disabled={ButtonDisabled} type="submit" color="primary">{ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Save</Button>
                                            <Link to="/setting/common-setting" className="btn btn-danger ms-3"> Cancel</Link>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(UserProfile)

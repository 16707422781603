import React from "react"
import { Redirect } from "react-router-dom"

//Dashboard
import Dashboard from "../pages/Dashboard/index";
import DashboardChart from "../pages/Dashboard/DashboardChart";

// Authentication related pages
import userProfile from "../pages/Authentication/user-profile"
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ChangePassword from "../pages/Authentication/ChangePassword"


// admin
import AdminList from "../pages/Admin/AdminList"
import AddEditAdmin from "../pages/Admin/AddEditAdmin"

// user
import UserList from "../pages/User/UserList"
import UserDetail from "../pages/User/UserDetail"
import UserChat from "../pages/User/UserChat"

// user roles
import UserRoleList from "../pages/UpdateRole/UserRoleList"
import AddEditUserRole from "../pages/UpdateRole/AddEditUserRole"
import AssignModule from "../pages/UpdateRole/AssignModule"

// TransactionsList
import TransactionsList from "../pages/Transactions/TransactionsList";
import TransactionsDetail from "../pages/Transactions/TransactionsDetail";

// WithdrawalRequests
import WithdrawalRequestsList from "../pages/WithdrawalRequests/WithdrawalRequestsList";
import WithdrawalRequestsDetail from "../pages/WithdrawalRequests/WithdrawalRequestsDetail";

//offerwall
import OfferwallList from "../pages/Offerwall/OfferwallList"
import OfferwallForm from "../pages/Offerwall/OfferwallForm"

//offerwall
import CategoriesList from "../pages/OfferCategories/CategoriesList"
import CategoriesForm from "../pages/OfferCategories/CategoriesForm"

//CustomeOfferwall
import CustomeOfferwallList from "../pages/CustomeOfferwall/OfferwallList"
import CustomeOfferwallForm from "../pages/CustomeOfferwall/OfferwallForm"

//leaderboard
import LeaderboardList from "../pages/Leaderboard/LeaderboardList"
import LeaderboardDetail from "../pages/Leaderboard/LeaderboardDetail"

// api log
import ApiLogList from "../pages/ApiLog/ApiLogList";
import ApiDetail from "../pages/ApiLog/ApiLogDetail";

// OfferwallLogApi
import OfferwallLogList from "../pages/OfferwallLogApi/ApiLogList";
import OfferwallLogDetail from "../pages/OfferwallLogApi/ApiLogDetail";

// vpn log
import VpnLogList from "../pages/VpnLog/VpnLogList";

//withdrawal_options
import WithdrawalOptionsList from "../pages/WithdrawalOptions/WithdrawalOptionsList"
import WithdrawalOptionsForm from "../pages/WithdrawalOptions/AddEditWithdrawalOptions"

//banner
import BannerList from "../pages/Banner/BannerList"
import BannerForm from "../pages/Banner/BannerForm";

//country
import CountryList from "../pages/Country/CountryList"
import AddEditCountry from "../pages/Country/AddEditCountry";

//rewards
import RewardList from "../pages/Reward/RewardList"
import AddEditReward from "../pages/Reward/AddEditReward";

//Blog
import BlogList from "../pages/Blog/BlogList"
import BlogForm from "../pages/Blog/BlogForm";

//Notification
import NotificationList from "../pages/Notification/NotificationList"
import NotificationSend from "../pages/Notification/NotificationSend";

//setting
import Setting from "../pages/Setting/Setting"
import OtpSetting from "../pages/Setting/OtpSetting"
import SmtpSetting from "../pages/Setting/SmtpSetting"
import CommonSetting from "../pages/Setting/CommonSetting"
import LeaderboardSetting from "../pages/Setting/LeaderboardSetting"
import LeaderboardPrize from "../pages/Setting/LeaderboardPrize"
import SignupWinningAmount from "../pages/Setting/SignupWinningAmount"
import WithdrawalSetting from "../pages/Setting/WithdrawalSetting"

import Chat from "../pages/Chat/Chat"

//ProSetting
import ProSetting from "../pages/Setting/ProSetting"


// report
import OfferwallReport from "../pages/Report/OfferwallReport"
import AccountReport from "../pages/Report/AccountReport"
import ChargebacksReport from "../pages/Report/ChargebacksReport"

import Error404 from "../pages/Error404";

const userRoutes = [

    //dashboard
    { path: "/dashboard", component: Dashboard },
    { path: "/dashboard-chart", component: DashboardChart },

    //profile
    { path: "/profile", component: userProfile },
    { path: "/change-password", component: ChangePassword },

    // Admin
    { path: "/admin", component: AdminList },
    { path: "/add-admin", component: AddEditAdmin },
    { path: "/edit-admin/:id?", component: AddEditAdmin },

    // user
    { path: "/user", component: UserList },
    { path: "/user-detail/:id?", component: UserDetail },
    { path: "/user-chat/:id?", component: UserChat },

    // user roles
    { path: "/user-roles", component: UserRoleList },
    { path: "/add-user-role", component: AddEditUserRole },
    { path: "/edit-user-role/:id?", component: AddEditUserRole },
    { path: "/assign-module/:id?", component: AssignModule },

    // transactions
    { path: "/transactions", component: TransactionsList },
    { path: "/transactions-detail/:id?", component: TransactionsDetail },

    { path: "/withdrawal-requests", component: WithdrawalRequestsList },
    { path: "/withdrawal-requests-detail/:id?", component:  WithdrawalRequestsDetail},

    //offerwall
    { path: "/offerwall", component: OfferwallList },
    { path: "/add-offerwall", component: OfferwallForm },
    { path: "/edit-offerwall/:id?", component: OfferwallForm },

    //Custome Offerwall
    { path: "/custom-offerwall", component: CustomeOfferwallList },
    { path: "/add-custom-offerwall", component: CustomeOfferwallForm },
    { path: "/edit-custom-offerwall/:id?", component: CustomeOfferwallForm },
   
    //category 
    { path: "/category", component: CategoriesList },
    { path: "/add-category", component: CategoriesForm },
    { path: "/edit-category/:id?", component: CategoriesForm },
     
    // api log
    { path: "/api-logs", component: ApiLogList },
    { path: "/api-logs-detail/:id?", component: ApiDetail },
 
    // offerwall log
    { path: "/offerwall-logs", component: OfferwallLogList },
    { path: "/offerwall-logs-detail/:id?", component: OfferwallLogDetail },
    
    // vpn log
    { path: "/vpn-logs", component: VpnLogList },

    //withdrawal_options
    { path: "/withdrawal-options", component: WithdrawalOptionsList },
    { path: "/add-withdrawal-options", component: WithdrawalOptionsForm },
    { path: "/edit-withdrawal-options/:id?", component: WithdrawalOptionsForm },

    //Banner
    { path: "/banner", component: BannerList },
    { path: "/add-banner", component: BannerForm },
    
    //leaderboard
    { path: "/leaderboard", component: LeaderboardList },
    { path: "/leaderboard/detail/:id?", component: LeaderboardDetail },

    //CountryList
    { path: "/country", component: CountryList },
    { path: "/add-country", component: AddEditCountry },
    { path: "/edit-country/:id?", component: AddEditCountry },

    //rewards
    { path: "/rewards", component: RewardList },
    { path: "/add-reward", component: AddEditReward },
    { path: "/edit-reward/:id?", component: AddEditReward },
    
    //report
    { path: "/offerwall-report", component: OfferwallReport },
    { path: "/account-report", component: AccountReport },
    { path: "/chargebacks-report", component: ChargebacksReport },

    // setting
    { path: "/setting", component: Setting },
    { path: "/setting/otp-setting", component: OtpSetting },
    { path: "/setting/smtp-setting", component: SmtpSetting },
    { path: "/setting/common-setting", component: CommonSetting },
    { path: "/setting/leaderboard-setting", component: LeaderboardSetting },
    { path: "/setting/leaderboard-setting/:type?", component: LeaderboardPrize },
    { path: "/setting/signup-winning-amount", component: SignupWinningAmount },
    { path: "/setting/withdrawal-setting", component: WithdrawalSetting },

    // Chat
    { path: "/chat", component: Chat },
    
    //Blog
    { path: "/blog", component: BlogList },
    { path: "/add-blog", component: BlogForm },
    { path: "/edit-blog/:id?", component: BlogForm },

    //NotificationList
    { path: "/notification", component: NotificationList },
    { path: "/send-notification", component: NotificationSend },
    
    // pro setting
    { path: "/pro-setting", component: ProSetting },
    

    { path: "*", component: Error404 },

    // this route should be at the end of all other routes
    { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [
    //authencation page
    { path: "/logout", component: Logout },
    { path: "/login", component: Login },
    { path: "/forgot-password", component: ForgetPwd },
]

export { userRoutes, authRoutes }

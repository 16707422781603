import MetaTags from "react-meta-tags"
import React, { useEffect } from "react"
import { Container, Row, Col} from "reactstrap"
import { useHistory, withRouter,Link } from "react-router-dom"

import { IsModemAccess } from '../../helpers/helper';
import { toast } from 'react-toastify';
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"


const AdminForm = props => {
    let history = useHistory();

    if (!IsModemAccess('app_setting', 'edit')) {
        toast('Does not permission this module');
        history.push("/");
    }
 

    useEffect(() => {
        async function get_detaile() {
        }
        get_detaile();
    }, [])

  
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Setting | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem="Setting" />

                    <Row>
                        <Col lg="3" >
                            <div className="border card-body rounded bg-light-box mt-3">
                                <h5 className=" mt-4 mb-4 text-center">SMTP Setting</h5>
                                <div className="text-right"><Link to="/setting/smtp-setting" style={{'fontSize': '14px'}}>{`CONFIGURE >`}</Link></div>
                            </div>
                        </Col>
                        <Col lg="3" >
                            <div className="border card-body rounded bg-light-box mt-3">
                                <h5 className=" mt-4 mb-4 text-center">OTP Setting</h5>
                                <div className="text-right"><Link to="/setting/otp-setting" style={{'fontSize': '14px'}}>{`CONFIGURE >`}</Link></div>
                            </div>
                        </Col>
                        <Col lg="3" >
                            <div className="border card-body rounded bg-light-box mt-3">
                                <h5 className=" mt-4 mb-4 text-center">Common Setting</h5>
                                <div className="text-right"><Link to="/setting/common-setting" style={{'fontSize': '14px'}}>{`CONFIGURE >`}</Link></div>
                            </div>
                        </Col>
                        <Col lg="3" >
                            <div className="border card-body rounded bg-light-box mt-3">
                                <h5 className=" mt-4 mb-4 text-center">Leaderboard Setting</h5>
                                <div className="text-right"><Link to="/setting/leaderboard-setting" style={{'fontSize': '14px'}}>{`CONFIGURE >`}</Link></div>
                            </div>
                        </Col>
                        <Col lg="3" >
                            <div className="border card-body rounded bg-light-box mt-3">
                                <h5 className=" mt-4 mb-4 text-center">Withdrawal Setting</h5>
                                <div className="text-right"><Link to="/setting/withdrawal-setting" style={{'fontSize': '14px'}}>{`CONFIGURE >`}</Link></div>
                            </div>
                        </Col>
                        
                    </Row>
                </Container>
            </div>


        </React.Fragment>
    )
}

export default withRouter(AdminForm)

import MetaTags from "react-meta-tags"
import React, { useState, useEffect, useRef } from "react"
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap"
import { useHistory, withRouter, Link } from "react-router-dom"

import Loader from './../../components/Common/Loader';
import SimpleReactValidator from 'simple-react-validator';
import { post } from '../../helpers/api_helper';
import { IsModemAccess } from '../../helpers/helper';
import { POST_GET_OFFERWALL_DETAIL, POST_ADD_EDIT_OFFERWALL } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

const AdminForm = props => {
    let history = useHistory();

    const [isLoader, setLoader] = useState(0);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [title, setTitle] = useState('Add Offerwall');
    const [form_inputs, setInputs] = useState({ id: 0, network: '', network_info: '', network_type: '', image: '', ad_format: '', ad_link: ''});
    const [, forceUpdate] = useState();

    useEffect(() => {
        async function get_detaile() {
            if (props.match.params.id) {
                if (!IsModemAccess('offerwall', 'edit')) {
                    toast('Does not permission this module');
                    history.push("/");
                }

                setTitle('Edit Offerwall');
                var res_data = await post(POST_GET_OFFERWALL_DETAIL, { 'offerwall_id': props.match.params.id }, {});
                if (res_data.status) {
                    await setInputs(res_data.detail);
                } else {
                    toast.error(res_data.message);
                    history.push("/");
                }
            } else {
                if (!IsModemAccess('offerwall', 'add')) {
                    toast('Does not permission this module');
                    history.push("/");
                }
            }
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_ADD_EDIT_OFFERWALL, form_inputs);
            if (res_data.status) {
                toast(res_data.message);
                history.push("/offerwall");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name === 'image') {
            var file = event.target.files[0];
            var reader = new FileReader();
            reader.onloadend = function () {
                setInputs(inputs => ({ ...form_inputs, ['image']: reader.result }));
            }
            reader.readAsDataURL(file);
        } else {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }

    }

    if (!isLoader) {
        return (<Loader></Loader>);
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{title} | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem={title} />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <form className="av-invalid" onSubmit={handleSubmit}>
                                        <Row>
                                            <Col lg="6">
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Network Name</label>
                                                    <input name="network" value={form_inputs.network} onChange={handleInputChange} className="form-control" placeholder="Enter Network Name" type="text" />
                                                    {simpleValidator.current.message('network name', form_inputs.network, 'required')}
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Network Info</label>
                                                    <textarea name="network_info" rows={3} value={form_inputs.network_info} onChange={handleInputChange} className="form-control" placeholder="Enter Network Info" />
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Whitelist Ip Address  <small className="ml-3">ex. 123.123.123.1,123.123.123.2</small></label>
                                                    <textarea name="whitelist_ip_address" rows={2} value={form_inputs.whitelist_ip_address} onChange={handleInputChange} className="form-control" placeholder="Enter Whitelist Ip Address" />
                                                </div>
                                            </Col>
                                            <Col lg="6">
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Offerwall Format</label>
                                                    <select name="ad_format" value={form_inputs.ad_format} onChange={handleInputChange}>
                                                        <option value="">Select Offerwall Format</option>
                                                        <option value="Offerwall">Offerwall</option>
                                                        <option value="Video">Video</option>
                                                        <option value="Surveys">Surveys</option>
                                                    </select>
                                                    {simpleValidator.current.message('Offerwall Format', form_inputs.ad_format, 'required')}
                                                </div>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <label className="form-label text-capitalize">Network Type</label>
                                                        <select name="network_type" value={form_inputs.network_type} onChange={handleInputChange}>
                                                            <option value="">Select Network Type</option>
                                                            <option value="Web">Web</option>
                                                            <option value="AppSDK">AppSDK</option>
                                                        </select>
                                                        {simpleValidator.current.message('network type', form_inputs.network_type, 'required')}
                                                    </div>
                                                    {form_inputs.network_type === 'Web' && <div className="col-9">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label">Url <small className="ml-3">{`{USER_ID}`}</small></label>
                                                            <input name="ad_link" value={form_inputs.ad_link} onChange={handleInputChange} className="form-control" placeholder="Enter Url" type="text" />
                                                        </div>
                                                    </div>}
                                                </div>
                                                <Row>
                                                    <Col lg="8">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label">Image <small>Accept only png, jpg and jpeg image (Size 210px X 120px)</small></label>
                                                            <input name="image" onChange={handleInputChange} className="form-control" placeholder="Select png Image" type="file" accept="image/png,image/jpg,image/jpeg" />
                                                        </div>
                                                    </Col>
                                                    <Col lg="4" className="mt-3">
                                                        {form_inputs.image_url && <img alt="Offerwall" src={form_inputs.image_url} style={{ 'maxWidth': '150px' }} />}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <div className="text-center mt-4">
                                            <Button disabled={ButtonDisabled} type="submit" color="primary">{ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Save</Button>
                                            <Link to="/offerwall" className="btn btn-danger ms-3"> Cancel</Link>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(AdminForm)

import React, { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { post } from '../../helpers/api_helper';
import { GET_DASHBOARD_CHART_DATA, GET_DASHBOARD_USER_DATA } from '../../helpers/url_helper';
import { useHistory } from "react-router-dom"
import Chart from 'react-apexcharts'


const Dashboard = () => {
    let history = useHistory();
    const [isLoader, setLoader] = useState(0);
    const [UserLoader, setUserLoader] = useState(0);
    const [activeTab, setActiveTab] = useState('7day');
    const [state, setState] = React.useState({
        optionsMixedChart: {
            colors: ['#5156be', '#3cb759', '#f43367', '#f69424'],
            chart: {
                id: "basic-bar",
                type: 'area',
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                bar: {
                    columnWidth: "15px"
                }
            },
            stroke: {
                curve: 'smooth',
                width: 3,
                width: [4, 0, 0]
            },
            xaxis: {
                categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
                labels: {
                    style: {
                        fill : '#000'
                    }
                },
            },
            tooltip: {
                y: {
                    formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                        return '$ ' + value
                    }
                }
            },
            markers: {
                size: 6,
                strokeWidth: 3,
                fillOpacity: 0,
                strokeOpacity: 0,
                hover: {
                    size: 8
                }
            },
        },
        seriesMixedChart: [
            {
                name: "Net Income",
                type: "line",
                data: [],
            },
            {
                name: "Income",
                type: "column",
                data: [],
            },
            {
                name: "Chargebacks",
                type: "column",
                data: []
            },
            {
                name: "Withdraw",
                type: "column",
                data: []
            }
        ],
    });

    const [userPieChart] = React.useState({
        series: [0, 0],
        options: {
            chart: {
                width: 380,
                type: 'pie',
            },
            labels: ['Total Referral User', 'Total Direct User'],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        }
    });

    const [userBalancesPieChart] = React.useState({
        series: [0, 0, 0, 0, 0],
        options: {
            chart: {
                width: 380,
                type: 'pie',
            },
            labels: ['Offers', 'Leaderboard', 'Referral', 'Bonus', 'Withdraw'],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        }
    });

    const [OfferWallPieChart] = React.useState({
        series: [0, 0],
        options: {
            chart: {
                width: 380,
                type: 'pie',
            },
            labels: ['Offers Income', 'Chargebacks'],
            colors: ['#4de396', '#f44561'],
            tooltip: {
                y: {
                    formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                        return '$ ' + parseFloat(value).toFixed(2)
                    }
                }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        }
    });

    useEffect(() => {
        async function get_detaile() {
            get_user_data();
            get_home_data();
        }
        get_detaile();
    }, [])

    const get_home_data = async (type = '7day') => {
        setLoader(0);
        var offset = new Date().getTimezoneOffset();
        var res_data = await post(GET_DASHBOARD_CHART_DATA, { offset: offset, type: type });
        if (res_data.status) {
            state.seriesMixedChart[0].data = res_data.data.final_income;
            state.seriesMixedChart[1].data = res_data.data.income;
            state.seriesMixedChart[2].data = res_data.data.chargebacks;
            state.seriesMixedChart[3].data = res_data.data.withdraw;
            state.optionsMixedChart.xaxis.categories = res_data.data.xaxis;
            setState({ ...state })
        }
        setLoader(1);
    }

    const get_user_data = async () => {
        setUserLoader(0);
        var res_data = await post(GET_DASHBOARD_USER_DATA, {});
        if (res_data.status) {
            userPieChart.series = [res_data.data.total_referral_users, res_data.data.total_direct_users];
            userBalancesPieChart.series = [res_data.data.total_offers_balance, res_data.data.total_leaderboard_balance, res_data.data.total_referral_balance, res_data.data.total_bonus_balance, res_data.data.withdraw_balance];
            OfferWallPieChart.series = [res_data.data.total_offers_income, res_data.data.total_offers_chargebacks];
        }
        setUserLoader(1);
    }

    const handleInputChange = (event) => {
        setActiveTab(event.target.value);
        get_home_data(event.target.value);
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Dashboard | Free Money Coins </title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />

                    <Row>
                        <Col xl={10} md={10}></Col>
                        <Col xl={2} md={2} className='mb-3'>
                            <div className='col-ms-2'>
                                <select value={activeTab} onChange={handleInputChange} >
                                    <option value="7day">Last 07 Day</option>
                                    <option value="15day">Last 15 Day</option>
                                    <option value="30day">Last 30 Day</option>
                                    <option value="6month">Last 06 Month</option>
                                    <option value="12month">Last 12 Month</option>
                                </select>
                            </div>
                        </Col>

                        {!isLoader && <Col xl={12} md={12} className='text-center pt-5 mt-5 pb-5 mt-5'>
                            <div role="status" className="ms-2 spinner-border text-dark"><span className="sr-only">Loading...</span></div>
                        </Col>}
                        {isLoader > 0 && <Col xl={12} md={12}>
                            <Card className="card-h-100">
                                <CardBody>
                                    {activeTab == '7day' && <h5 className='mb-4'>Last 07 Day Income</h5>}
                                    {activeTab == '15day' && <h5 className='mb-4'>Last 15 Day Income</h5>}
                                    {activeTab == '30day' && <h5 className='mb-4'>Last 30 Day Income</h5>}
                                    {activeTab == '6month' && <h5 className='mb-4'>Last 06 Month Income</h5>}
                                    {activeTab == '12month' && <h5 className='mb-4'>Last 12 Month Income</h5>}
                                    <Chart options={state.optionsMixedChart} series={state.seriesMixedChart} type="line" width="100%" height="500px" />
                                </CardBody>
                            </Card>
                        </Col>}

                        {UserLoader > 0 && <Col xl={4} md={6} >
                            <Card className="card-h-100">
                                <CardBody>
                                    <h5 className='mb-4'>User Source</h5>
                                    <Chart options={userPieChart.options} series={userPieChart.series} type="pie" width="100%" height="250px" />
                                </CardBody>
                            </Card>
                        </Col>}
                        {UserLoader > 0 && <Col xl={4} md={6} >
                            <Card className="card-h-100">
                                <CardBody>
                                    <h5 className='mb-4'>User Balance</h5>
                                    <Chart options={userBalancesPieChart.options} series={userBalancesPieChart.series} type="pie" width="100%" height="250px" />
                                </CardBody>
                            </Card>
                        </Col>}
                        {UserLoader > 0 && <Col xl={4} md={6} >
                            <Card className="card-h-100">
                                <CardBody>
                                    <h5 className='mb-4'>Offerwall Income</h5>
                                    <Chart options={OfferWallPieChart.options} series={OfferWallPieChart.series} type="pie" width="100%" height="250px" />
                                </CardBody>
                            </Card>
                        </Col>}
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
}

export default Dashboard;
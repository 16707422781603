import MetaTags from "react-meta-tags"
import React, { useState,useEffect, useRef } from "react"
import {Container,Row,Col,Card,CardBody,Button} from "reactstrap"
import { useHistory,withRouter,Link } from "react-router-dom"

import SimpleReactValidator from 'simple-react-validator';
import {post} from '../../helpers/api_helper';
import {POST_GET_CATEGORY_DETAIL,POST_ADD_EDIT_CATEGORY} from '../../helpers/url_helper';
import {IsModemAccess} from '../../helpers/helper';
import { toast } from 'react-toastify';


//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"


const UserProfile = props => {
    let history = useHistory();

    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [title, setTitle] = useState('Add Category');
    const [form_inputs, setInputs] = useState({ category_id: 0, category_name: ''});
    const [, forceUpdate] = useState();

    useEffect(() => {
        async function get_detaile () {
            if (props.match.params.id) {
                if(!IsModemAccess('custom_offers_categories','edit')){
                    toast('Does not permission this module');
                    history.push("/");
                }
                setTitle('Edit Category');
                var res_data = await post(POST_GET_CATEGORY_DETAIL,{'category_id' : props.match.params.id},{});
                if (res_data.status) {
                    setInputs(res_data.detail);
                } else {
                    toast.error(res_data.message);
                    history.push("/");
                }
            }else{
                if(!IsModemAccess('custom_offers_categories','add')){
                    toast('Does not permission this module');
                    history.push("/");
                }
            }
        }
        get_detaile();
    }, [props.match.params.id])

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data =  await post(POST_ADD_EDIT_CATEGORY,form_inputs,{});
            if(res_data.status){
                toast(res_data.message);
                history.push("/category");
            }else{
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{title} | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem={title} />

                    <Row>
                        <Col lg="6">
                            <Card>
                                <CardBody>
                                    <form className="av-invalid" onSubmit={handleSubmit}>
                                        <div className="form-group mb-3">
                                            <label className="form-label">Name</label>
                                            <input name="category_name" value={form_inputs.category_name} onChange={handleInputChange} className="form-control" placeholder="Enter Name" type="text" />
                                            {simpleValidator.current.message('name', form_inputs.category_name, 'required')}
                                        </div>
                                        <div className="text-center mt-4">
                                            <Button disabled={ButtonDisabled} type="submit" color="primary">{ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Save</Button>
                                            <Link to="/category" className="btn btn-danger ms-3"> Cancel</Link>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(UserProfile)

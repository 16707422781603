import MetaTags from "react-meta-tags"
import React, { useState, useEffect, useRef } from "react"
import { Container, Row, Col, Button } from "reactstrap"
import { useHistory, withRouter, Link } from "react-router-dom"

import Loader from '../../components/Common/Loader';
import SimpleReactValidator from 'simple-react-validator';
import { post, get } from '../../helpers/api_helper';
import { POST_UPDATE_SETTING, GET_ALL_SETTING } from '../../helpers/url_helper';
import { IsModemAccess } from '../../helpers/helper';
import { toast } from 'react-toastify';
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"


const AdminForm = props => {
    let history = useHistory();

    if (!IsModemAccess('app_setting', 'edit')) {
        toast('Does not permission this module');
        history.push("/");
    }
    const [isLoader, setLoader] = useState(0);
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState();
    const [option_input, setOptionInputs] = useState();
    const simpleValidator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();

    useEffect(() => {
        async function get_detaile() {
            await get_app_setting();
            setLoader(1);
        }
        get_detaile();
    }, [])

    const get_app_setting = async () => {
        var res_data = await get(GET_ALL_SETTING + "?type=withdrawal-setting");
        if (res_data.status) {
            setOptionInputs((res_data.data.withdrawal_request_setting) ? JSON.parse(res_data.data.withdrawal_request_setting) : []);
            await setInputs(res_data.data);
        } else {
            toast.error(res_data.message);
        }
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            form_inputs.withdrawal_request_setting = JSON.stringify(option_input);
            SetButtonDisabled(true);
            var res_data = await post(POST_UPDATE_SETTING, form_inputs, {});
            if (res_data.status) {
                await get_app_setting();
                toast(res_data.message);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        console.log(event.target.name);
        if (
            event.target.name === 'withdrawal_enable'
        ) {
            console.log((event.target.checked) ? '1' : '0');
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: (event.target.checked) ? '1' : '0' }));
        } else {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    const handleInputChangeMethod = (e, index) => {
        var name = e.target.name;
        var value = e.target.value;
        const list = [...option_input];
        list[index][name] = value;
        setOptionInputs(list);
    };

    const addRow = () => {
        setOptionInputs([...option_input, {
            "request": '',
            "day": "",
        }]);
    }

    let RemoveRow = (i) => {
        const values = [...option_input];
        values.splice(i, 1);
        setOptionInputs(values)
    }

    if (!isLoader) {
        return (<Loader></Loader>);
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Withdrawal Setting | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem="Withdrawal Setting" />

                    <Row>
                        <Col lg="8">
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className="border card-body rounded bg-light-box">
                                    <Row>
                                        <Col lg="4" >
                                            <div className="form-group mb-3">
                                                <label className="form-label text-capitalize">Withdrawal Exchange Rate One USD($) To Coin  <br /><small>Ex. $1 = 100Coins</small></label>
                                                <input name="withdrawal_exchange_rate_on_usd_coin" value={form_inputs.withdrawal_exchange_rate_on_usd_coin} onChange={handleInputChange} className="form-control" placeholder={`Enter Exchange Rate`} type="text" />
                                            </div>
                                            <div className="form-group mb-3">
                                                <label className="form-label text-capitalize">Withdrawal Exchange Rate One INR(₹) To Coin  <br /><small>Ex. ₹1 = 10Coins</small></label>
                                                <input name="withdrawal_exchange_rate_on_inr_coin" value={form_inputs.withdrawal_exchange_rate_on_inr_coin} onChange={handleInputChange} className="form-control" placeholder={`Enter Exchange Rate`} type="text" />
                                            </div>
                                            <div className="row mb-3 mt-4">
                                                <div className="col-8">
                                                    <label className="form-label text-capitalize">Withdrawal Enable</label>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-check form-switch form-switch-md mt-0" dir="ltr">
                                                        <input name="withdrawal_enable" onChange={handleInputChange} defaultChecked={parseInt(form_inputs.withdrawal_enable)} type="checkbox" className="form-check-input" />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg="8">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <td>Request</td>
                                                        <td>Day</td>
                                                        <td>
                                                            <button type="button" onClick={addRow} className="btn btn-primary btn-sm w-xs">+Add</button>
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {option_input && Object.entries(option_input).map(([key, value]) => (
                                                        <tr>
                                                            <td>
                                                                <input name="request" maxLength="16" value={value.request} onChange={e => handleInputChangeMethod(e, key)} className="form-control" placeholder="Enter Request" type="text" />
                                                                {simpleValidator.current.message('request', value.request, 'required|numeric')}
                                                            </td>
                                                            <td>
                                                                <input name="day" maxLength="16" value={value.day} onChange={e => handleInputChangeMethod(e, key)} className="form-control" placeholder="Enter Day" type="text" />
                                                                {simpleValidator.current.message('day', value.day, 'required|numeric')}
                                                            </td>
                                                            <td>
                                                                <button type="button" className="btn btn-danger btn-sm" onClick={() => RemoveRow(key)}>Remove</button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </Col>
                                        <Col lg="12" >
                                            <div className="text-center mt-4">
                                                <Button disabled={ButtonDisabled} type="submit" color="primary">{ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Update</Button>
                                                <Link to="/setting" className="btn btn-danger ms-3"> Cancel</Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </form>
                        </Col>
                    </Row>
                </Container>
            </div>

        </React.Fragment>
    )
}

export default withRouter(AdminForm)

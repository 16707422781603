import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { withRouter, useHistory,Link } from "react-router-dom"

import { getToken, IsModemAccess, DateUTCToLocal } from '../../helpers/helper';
import { get } from '../../helpers/api_helper';
import { POST_VPN_LOG,  GET_SELECT_SEARCH_USER } from '../../helpers/url_helper';
import ReactDOM from 'react-dom';

import $ from 'jquery';

import AsyncSelect from 'react-select/async';

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import Loader from '../../components/Common/Loader';


const VpnLogList = props => {
    let history = useHistory();
    const [isLoader, setLoader] = useState(0);

    const [UserId, setUserId] = useState('');
    var form_inputs = { user_id: ''};
    const [DefaultSelectUser, setDefaultSelectUser] = useState({});

    const [inputValue, setValue] = useState('');


    useEffect(() => {
        async function get_detaile() {
            var default_user_id = (new URLSearchParams(window.location.search)).get("id");
            var default_email = (new URLSearchParams(window.location.search)).get("email");
           
            if(default_user_id > 0){
                form_inputs.user_id = default_user_id;
                setUserId(default_user_id);
                setDefaultSelectUser({'value': default_user_id,'label':default_email});
            }
            setLoader(1);
            get_list();
        }
        get_detaile();
    }, [])

    // get user role list
    const get_list = () => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": POST_VPN_LOG + '?user_id=' + form_inputs.user_id,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [] }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "log_id" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<> {DateUTCToLocal(data.created_at)}</>, td)
                        }
                    },
                    { "data": "username" },
                    { "data": "email" },
                    { "data": "ip_address" },
                    { "data": "provider" },
                    { "data": "country" },
                    { "data": "city" }
                ]
            });
        });
    }

    const applyFilter = async () => {
        form_inputs = { user_id: UserId};
        await get_list();
    }

    const fetchData = async () => {
        return await get(GET_SELECT_SEARCH_USER + inputValue).then(result => {
            const res = result.user_list;
            return res;
        });
    }

    const handleSelectChange = async (name, value) => {
        if (name === 'user_id') {
            await setUserId((value && value.value) ? value.value : '');
        }
    }
     // handle input change event
     const handleInputChange = value => {
        setValue(value);
    };

    if (!isLoader) {
        return (<Loader></Loader>);
    }
    return (

        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>VPN Logs | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem="VPN Logs" />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg="3 mt-3"></Col>
                                        <Col lg="3 mt-3">
                                            <AsyncSelect
                                                name="user_id"
                                                isClearable={true}
                                                isMulti={false}
                                                loadOptions={fetchData}
                                                onInputChange={handleInputChange}
                                                onChange={handleSelectChange.bind(this, 'user_id')}
                                                defaultValue={DefaultSelectUser}
                                                classNamePrefix="select2-selection"
                                                isLoading={false}
                                            />
                                        </Col>
                                        <Col lg="1 mt-3">
                                            <button onClick={applyFilter.bind(this)} className="btn btn-primary w-xs">Apply</button>
                                            {/* <button className="btn btn-danger w-xs ml-3">Clear</button> */}
                                        </Col>
                                    </Row>
                                    <div className="mt-4 mb-5">
                                        {IsModemAccess('vpn_logs', 'view') && <table id="dataTableExample" className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <td>Id</td>
                                                    <td>Date</td>
                                                    <td>User Name</td>
                                                    <td>User Email</td>
                                                    <td>IP Address</td>
                                                    <td>Provider</td>
                                                    <td>Country</td>
                                                    <td>City</td>
                                                </tr>
                                            </thead>
                                            <tbody>

                                            </tbody>
                                        </table>}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
             
            </div>
        </React.Fragment>
    )
}

export default withRouter(VpnLogList)

import React from "react"
import { Container} from "reactstrap"

const Loader = props => {
    return (
        <React.Fragment>
            <div className="page-content text-center">
                <Container fluid>
                    <div role="status" className="ms-2 spinner-border text-dark"><span className="sr-only">Loading...</span></div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Loader

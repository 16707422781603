import MetaTags from "react-meta-tags"
import React, { useState, useEffect, useRef } from "react"
import { Container, Row, Col, Button } from "reactstrap"
import { useHistory, withRouter, Link } from "react-router-dom"

import Loader from '../../components/Common/Loader';
import SimpleReactValidator from 'simple-react-validator';
import { post, get } from '../../helpers/api_helper';
import { POST_UPDATE_SETTING, GET_ALL_SETTING } from '../../helpers/url_helper';
import { IsModemAccess } from '../../helpers/helper';
import { toast } from 'react-toastify';
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"


const AdminForm = props => {
    let history = useHistory();

    if (!IsModemAccess('app_setting', 'edit')) {
        toast('Does not permission this module');
        history.push("/");
    }
    const [isLoader, setLoader] = useState(0);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState();
    const [, forceUpdate] = useState();

    useEffect(() => {
        async function get_detaile() {
            await get_app_setting();
            setLoader(1);
        }
        get_detaile();
    }, [])

    const get_app_setting = async () => {
        var res_data = await get(GET_ALL_SETTING + "?type=smtp-setting");
        if (res_data.status) {
            await setInputs(res_data.data);
        } else {
            toast.error(res_data.message);
        }
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_UPDATE_SETTING, form_inputs, {});
            if (res_data.status) {
                await get_app_setting();
                toast(res_data.message);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }

    if (!isLoader) {
        return (<Loader></Loader>);
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>SMTP Setting | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem="SMTP Setting" />

                    <form className="av-invalid" onSubmit={handleSubmit}>
                        <div className="border card-body rounded bg-light-box">
                            <Row>
                                <Col lg="4">
                                    <Row>
                                        <Col lg="12" >
                                            <div className="form-group mb-3">
                                                <label className="form-label text-capitalize">SMTP Host</label>
                                                <input name="smtp_host" value={form_inputs.smtp_host} onChange={handleInputChange} className="form-control" placeholder={`Enter SMTP Host`} type="text" />
                                            </div>
                                        </Col>
                                        <Col lg="12" >
                                            <div className="form-group mb-3">
                                                <label className="form-label text-capitalize">SMTP Port</label>
                                                <input name="smtp_port" value={form_inputs.smtp_port} onChange={handleInputChange} className="form-control" placeholder={`Enter SMTP Port`} type="text" />
                                            </div>
                                        </Col>
                                        <Col lg="12" >
                                            <div className="form-group mb-3">
                                                <label className="form-label text-capitalize">SMTP FROM</label>
                                                <input name="smtp_from" value={form_inputs.smtp_from} onChange={handleInputChange} className="form-control" placeholder={`Enter SMTP From`} type="text" />
                                            </div>
                                        </Col>
                                        <Col lg="12" >
                                            <div className="form-group mb-3">
                                                <label className="form-label text-capitalize">Reply To</label>
                                                <input name="smtp_reply_to" value={form_inputs.smtp_reply_to} onChange={handleInputChange} className="form-control" placeholder={`Enter ReplyTo`} type="text" />
                                            </div>
                                        </Col>
                                        <Col lg="12" >
                                            <div className="form-group mb-3">
                                                <label className="form-label text-capitalize">SMTP User Name</label>
                                                <input name="smtp_user_name" value={form_inputs.smtp_user_name} onChange={handleInputChange} className="form-control" placeholder={`Enter SMTP User Name`} type="text" />
                                            </div>
                                        </Col>
                                        <Col lg="12" >
                                            <div className="form-group mb-3">
                                                <label className="form-label text-capitalize">SMTP Password</label>
                                                <input name="smtp_password" value={form_inputs.smtp_password} onChange={handleInputChange} className="form-control" placeholder={`Enter SMTP Password`} type="password" />
                                            </div>
                                        </Col>
                                        
                                    </Row>
                                </Col>
                                <Col lg="1"></Col>
                                <Col lg="7">
                                    <Row>
                                        <Col lg="12" >
                                            <div className="form-group mb-3">
                                                <label className="form-label text-capitalize">Profile Verified Subject  <small className="ml-5">[USER_NAME]</small></label>
                                                <input name="user_profile_verified_subject" value={form_inputs.user_profile_verified_subject} onChange={handleInputChange} className="form-control" placeholder={`Enter Subject`} type="text" />
                                            </div>
                                        </Col>
                                        <Col lg="12" >
                                            <div className="form-group mb-3">
                                                <label className="form-label text-capitalize">Profile Verified Body <small className="ml-5">[USER_NAME]</small></label>
                                                <textarea rows={10} name="user_profile_verified_body" value={form_inputs.user_profile_verified_body} onChange={handleInputChange} className="form-control" placeholder={`Enter Subject`}  />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="12" >
                                    <div className="text-center mt-4">
                                        <Button disabled={ButtonDisabled} type="submit" color="primary">{ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Update</Button>
                                        <Link to="/setting" className="btn btn-danger ms-3"> Cancel</Link>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </form>
                </Container>
            </div>

        </React.Fragment>
    )
}

export default withRouter(AdminForm)

import MetaTags from "react-meta-tags"
import React, { useState,useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"

// availity-reactstrap-validation
import { withRouter,useHistory,Link } from "react-router-dom"

import { get,post } from '../../helpers/api_helper';
import { IsModemAccess,DateUTCToLocal,getToken } from '../../helpers/helper';
import { GET_USER_DETAIL,POST_GET_USER_LOG,POST_GET_REFERRAL_USER,GET_AUDIT_DATA,POST_PROFILE_VERIFIED_EMAIL_SEND,POST_UPDATE_USER_STATUS } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import ReactDOM from 'react-dom';
import Loader from './../../components/Common/Loader';
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"


import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";

const UserProfile = props => {
    let history = useHistory();

    const [isLoader, setLoader] = useState(0);
    const [Detail, setDetail] = useState();
    const [ButtonDisabled, SetButtonDisabled] = useState();

    useEffect(() => {
        async function get_detaile() {
            if (props.match.params.id) {
                if(!IsModemAccess('user','view')){
                    toast('Does not permission this module');
                    history.push("/");
                }
                await get_user_detail();
            }
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])

    const get_user_detail = async () => {
        var res_data = await get(GET_USER_DETAIL+'?user_id='+props.match.params.id);
        if (res_data.status) {
            setDetail(res_data.detail);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
        get_user_log();
    }

    const get_user_log = () => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": POST_GET_USER_LOG+'?user_id='+props.match.params.id ,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [] }, { "targets": 0, "visible": false }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "id" },
                    { "data": "log_type" },
                    { "data": "ip_address" },
                    { "data": "device_type" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>{DateUTCToLocal(data.created_at)}</>, td)
                        }
                    }
                ]
            });
            $('#dataTableReferral').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": POST_GET_REFERRAL_USER+'?user_id='+props.match.params.id ,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [] }, { "targets": 0, "visible": false }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "id" },
                    { "data": "username" },
                    { "data": "email" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>{DateUTCToLocal(data.created_at)}</>, td)
                        }
                    }
                ]
            });
        });
    }

    // audit Data
    const auditData = async () => {
        SetButtonDisabled(true);
        var res_data = await get(GET_AUDIT_DATA + "?user_id=" + props.match.params.id);
        if (res_data.status) {
            toast(res_data.message);
            SetButtonDisabled(false);
            await get_user_detail();
        } else {
            toast.error(res_data.message);
            SetButtonDisabled(false);
        }
    }

    // audit Data
    const profileVerifiedEmailSend = async () => {
        SetButtonDisabled(true);
        var res_data = await get(POST_PROFILE_VERIFIED_EMAIL_SEND + "?user_id=" + props.match.params.id);
        if (res_data.status) {
            toast(res_data.message);
            SetButtonDisabled(false);
        } else {
            toast.error(res_data.message);
            SetButtonDisabled(false);
        }
    }

    //UpdateStatus
    const UpdateStatus = async (status) => {
        if (!IsModemAccess('user', 'edit')) {
            toast('Does not permission this module');
            return true;
        }
        var res_data = await post(POST_UPDATE_USER_STATUS, { user_id: props.match.params.id, status: status });
        if (res_data.status) {
            toast(res_data.message);
            await get_user_detail();
        } else {
            toast.error(res_data.message);
        }
    }

    if (!isLoader) {
        return (<Loader></Loader>);
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>User Detail | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem="User Detail" />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">User Id :</h5>
                                            <span>{Detail.uid_public}</span>
                                        </Col>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">User Name :</h5>
                                            <span>{Detail.username}</span>
                                        </Col>
                                        <Col lg="3 mb-3" >
                                            <h5 className="font-size-15 mb-1">Referral Code :</h5>
                                            <span>{Detail.user_referral_code}</span>
                                        </Col>
                                        {Detail.user_referred_by > 0 && <Col lg="3 mb-3" >
                                            <h5 className="font-size-15 mb-1">Referred By :</h5>
                                            <span><Link to={`/user-detail/${Detail.user_referred_by}`}>{Detail.referred_user_name}</Link></span>
                                        </Col>}
                                        <Col lg="3 mb-3" >
                                            <h5 className="font-size-15 mb-1">Register from :</h5>
                                            {Detail.registered_from}
                                        </Col>
                                        <Col lg="3 mb-3" >
                                            <h5 className="font-size-15 mb-1">Registered By :</h5>
                                            {Detail.registered_by}
                                        </Col>
                                        <Col lg="3 mb-3" >
                                            <h5 className="font-size-15 mb-1">Registered Date :</h5>
                                            {DateUTCToLocal(Detail.created_at)}
                                        </Col>
                                        <Col lg="3 mb-3" >
                                            <h5 className="font-size-15 mb-1">Email :</h5>
                                            <span>{Detail.email} {Detail.email_verified > 0 && <i className="fa fa-check-circle text-success" aria-hidden="true"></i>}</span>
                                        </Col>
                                        <Col lg="3 mb-3" >
                                            <h5 className="font-size-15 mb-1">Account Status :</h5>
                                            {Detail.profile_status === 0 && <span className="text-danger"><i className="fa fa-lock mr-1 lock" aria-hidden="true"></i> Private </span>}
                                            {Detail.profile_status === 1 && <span className="text-success"><i className="fa fa-unlock-alt unlock mr-1" aria-hidden="true"></i> Public</span>}
                                        </Col>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">Status :</h5>

                                            {Detail.user_status.toString() === '1' && <button onClick={UpdateStatus.bind(this,1)} className="border-0 badge badge-success fs-6" >Active</button>}
                                            {Detail.user_status.toString() === '0' && <button onClick={UpdateStatus.bind(this,0)} className="border-0 badge badge-danger fs-6" >Deactive</button>}

                                            {Detail.user_status.toString() === '2' && <span className="text-danger">Deleted</span>}
                                        </Col>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">Country :</h5>
                                            {Detail.country_name && <span > <span className="country_flags"><img src={`/flags/${Detail.country_code}.png`}alt='country icon'/> </span>{Detail.country_name}</span>}
                                        </Col>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">Notes :</h5>
                                            {Detail.user_notes}
                                        </Col>
                                        <Col lg="3 mb-3 pt-3">
                                            <button disabled={ButtonDisabled} type="button" onClick={auditData} className="btn btn-primary mr-3 mb-3">{ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Audit Data</button>

                                            <Link className="btn btn-primary mb-3" onClick={() => { window.open('/transactions?id=' + Detail.user_id+'&email='+Detail.email, "_blank").focus(); }}  to="#">View all transactions</Link>

                                            <button disabled={ButtonDisabled} type="button" onClick={profileVerifiedEmailSend} className="btn btn-primary mr-3 mb-3">{ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>}Profile Verified Email Send</button>

                                        </Col>
                                    </Row>
                                    <hr/>
                                    <Row>
                                        <Col lg="2 mb-3 text-center">
                                            <span>{Detail.offers_balance}</span>
                                            <h5 className="font-size-15 mb-1">Offers Balance </h5>
                                        </Col>
                                        <Col lg="2 mb-3 text-center">
                                            <span>{Detail.video_balance}</span>
                                            <h5 className="font-size-15 mb-1">Video Balance </h5>
                                        </Col>
                                        <Col lg="2 mb-3 text-center">
                                            <span>{Detail.leaderboard_balance}</span>
                                            <h5 className="font-size-15 mb-1">Leaderboard Balance </h5>
                                        </Col>
                                        <Col lg="2 mb-3 text-center">
                                            <span>{Detail.referral_balance}</span>
                                            <h5 className="font-size-15 mb-1">Referral Balance </h5>
                                        </Col>
                                        <Col lg="2 mb-3 text-center">
                                            <span>{Detail.bonus_balance}</span>
                                            <h5 className="font-size-15 mb-1">Bonus Balance </h5>
                                        </Col>
                                        <Col lg="2 mb-3 text-center">
                                            <span>{Detail.points_balance}</span>
                                            <h5 className="font-size-15 mb-1">Points Balance </h5>
                                        </Col>
                                        <Col lg="2 mb-3 text-center">
                                            <span>{Detail.credit_balance}</span>
                                            <h5 className="font-size-15 mb-1">Credit Balance </h5>
                                        </Col>
                                        <Col lg="2 mb-3 text-center">
                                            <span>{Detail.debit_balance}</span>
                                            <h5 className="font-size-15 mb-1">Debit Balance </h5>
                                        </Col>
                                        <Col lg="2 mb-3 text-center">
                                            <span>{Detail.total_balance}</span>
                                            <h5 className="font-size-15 mb-1">Total Balance </h5>
                                        </Col>
                                        <Col lg="2 mb-3 text-center">
                                            <span>{Detail.total_referrals}</span>
                                            <h5 className="font-size-15 mb-1">Total Referrals User</h5>
                                        </Col>
                                        <Col lg="2 mb-3 text-center">
                                            <span>{Detail.total_completed_offers}</span>
                                            <h5 className="font-size-15 mb-1">Total Completed Offers</h5>
                                        </Col>
                                    </Row>
                                    <hr/>
                                    <Row>
                                        <Col lg="3 mb-3 text-center">
                                            <span>{Detail.withdrawal_request_count.total_cash_request}</span>
                                            <h5 className="font-size-15 mb-1">Total Cash Request</h5>
                                        </Col>
                                        <Col lg="3 mb-3 text-center">
                                            <span>{Detail.withdrawal_request_count.total_crypto_request}</span>
                                            <h5 className="font-size-15 mb-1">Total Crypto Request</h5>
                                        </Col>
                                        <Col lg="3 mb-3 text-center">
                                            <span>{Detail.withdrawal_request_count.total_gift_card_request}</span>
                                            <h5 className="font-size-15 mb-1">Total Gift Card Request</h5>
                                        </Col>
                                        <Col lg="3 mb-3 text-center">
                                            <span>{Detail.withdrawal_request_count.total_mobile_top_up_request}</span>
                                            <h5 className="font-size-15 mb-1">Total Mobile Topup Request</h5>
                                        </Col>
                                    </Row>
                                    {Detail.vpn_log && <> <hr/>
                                    <Row>
                                        <Col lg="3 mb-3">
                                            <h5 className="mb-2 text-danger"><i class="fa fa-exclamation-triangle mr-2" aria-hidden="true"></i> Last VPN Detected Info</h5>
                                            <span><b>Date & Time  &nbsp; : </b> {DateUTCToLocal(Detail.vpn_log.created_at)}</span><br/>
                                            <span><b>IP Address  &nbsp; &nbsp; : </b> <Link onClick={() => { window.open('/vpn-logs?id=' + Detail.user_id+'&email='+Detail.email, "_blank").focus(); }}  to="#">{Detail.vpn_log.ip_address}</Link></span><br/>
                                            <span><b>Country &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : </b> {Detail.vpn_log.country}</span><br/>
                                            <span><b>Total Count &nbsp; &nbsp;: </b> {Detail.vpn_log.vpn_count}</span>
                                        </Col>
                                    </Row>
                                    </>}
                                    <hr/>
                                    <Row>
                                        <Col lg="5">
                                            <Row>
                                                <Col lg="12">
                                                    <h5 className="mb-3">Referral User List:</h5>
                                                    <table id="dataTableReferral" className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <td>Id</td>
                                                                <td>User Name</td>
                                                                <td>Email</td>
                                                                <td>Registered Date</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        </tbody>
                                                    </table>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg="7">
                                            <Row>
                                                <Col lg="12">
                                                    <h5 className="mb-3">User Activity Log:</h5>
                                                        <table id="dataTableExample" className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <td>Id</td>
                                                                    <td>Title</td>
                                                                    <td>Ip Address</td>
                                                                    <td>Device Type</td>
                                                                    <td>Date</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            </tbody>
                                                        </table>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(UserProfile)

import React, { useEffect, useState, useCallback } from "react";
import MetaTags from "react-meta-tags";
import { isEmpty, map } from "lodash";
import moment from "moment";
import {Button, Card, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown} from "reactstrap";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { POST_GET_CHAT_MESSAGE, POST_UPDATE_CHAT_STATUS, POST_SEND_MESSAGE} from '../../helpers/url_helper';
import {IsModemAccess} from '../../helpers/helper';
import { toast } from 'react-toastify';
import { post } from '../../helpers/api_helper';
import { useHistory, Link } from "react-router-dom"
import Loader from './../../components/Common/Loader';

const Chat = props => {
    let history = useHistory();

    const [messagesList, setMessages] = useState([]);
    const [isLoader, setLoader] = useState(0);
    const [curMessage, setcurMessage] = useState("");
    const [ButtonDisabled, SetButtonDisabled] = useState(false);


    useEffect(() => {
        async function get_detaile() {
            if (!IsModemAccess('chat', 'view')) {
                toast('Does not permission this module');
                history.push("/");
            }

           await get_all_message();
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])

     //get admin form option
     const get_all_message = async () => {
        var res_data = await post(POST_GET_CHAT_MESSAGE, {});
        if (res_data.status) {
            await setMessages(res_data.data.message_list.reverse());
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

       //UpdateStatus
    const UpdateStatus = async (chate_id, status) => {
        var res_data = await post(POST_UPDATE_CHAT_STATUS, { chate_id: chate_id, status: status });
        if (res_data.status) {
            get_all_message();
        } else {
            toast.error(res_data.message);
        }
    }

    const [messageBox, setMessageBox] = useState(null);


    const scrollToBottom = useCallback(() => {
        if (messageBox) {
            messageBox.scrollTop = messageBox.scrollHeight + 1000;
        }
    }, [messageBox]);

    useEffect(() => {
        if (!isEmpty(messagesList)) scrollToBottom();
    }, [messagesList, scrollToBottom]);


    const addMessage = async () => {
        var message = curMessage.trim();
        if(message){
            SetButtonDisabled(true);
            var res_data = await post(POST_SEND_MESSAGE, { message: message});
            if (res_data.status) {
                await get_all_message();
                setcurMessage('');
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }else{
            toast.error('Please enter your message.');
        }

    };

    const onKeyPress = (e) => {
        const { key, value } = e;
        if (key === "Enter") {
            setcurMessage(value);
        }
    };

    if (!isLoader) {
        return (<Loader></Loader>);
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Chat | Minia - React Admin & Dashboard Template</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="Apps" breadcrumbItem="Chat" />

                    <Row>
                        <Col md="3"></Col>
                        <Col md="6">
                            <div className="d-lg-flex">
                                <div className="w-100 user-chat">
                                    <Card>
                                        <div className="p-3 border-bottom ">
                                            <Row>
                                                <Col md="4" xs="9">
                                                    <h5 className="font-size-15 mb-1">Chat Room</h5>
                                                </Col>
                                                <Col md="8" xs="3">
                                                </Col>
                                            </Row>
                                        </div>

                                        <div>
                                            <PerfectScrollbar
                                                className="chat-conversation p-3 px-2"
                                                style={{ height: "600px" }}
                                                containerRef={(ref) => setMessageBox(ref)}
                                            >
                                                <ul className="list-unstyled">
                                                    {messagesList &&
                                                        map(messagesList, (message) => {
                                                            return (
                                                                <li key={"test_k" + message.id} className="right">
                                                                    <div className="conversation-list">
                                                                        <div className="ctext-wrap">
                                                                            <div className="ctext-wrap-content">
                                                                                <h5 className="conversation-name d-flex align-items-start">
                                                                                    <div className="flex-shrink-0 user-img align-self-center me-3">
                                                                                        <img src={message.profile_image} class="rounded-circle avatar-sm" alt="" />
                                                                                    </div>
                                                                                    <div className="flex-grow-1 overflow-hidden text-left">
                                                                                        <Link to="#" className="user-name">{message.user_name}</Link><br />
                                                                                        <span className="time">
                                                                                            {moment(message.created_at).format("DD-MM-YY hh:mm")}
                                                                                        </span>
                                                                                    </div>
                                                                                </h5>
                                                                                <p className="mb-0">{message.message}</p>
                                                                            </div>

                                                                            <UncontrolledDropdown className="align-self-start">
                                                                                <DropdownToggle
                                                                                    href="#"
                                                                                    className="btn nav-btn"
                                                                                    tag="i"
                                                                                >
                                                                                    <i className="bx bx-dots-vertical-rounded" />
                                                                                </DropdownToggle>
                                                                                <DropdownMenu direction="right">
                                                                                    <DropdownItem onClick={UpdateStatus.bind(this,message.id , 'Active')} to="#">Hide</DropdownItem>
                                                                                    {/* <DropdownItem to="#">Delete</DropdownItem> */}
                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            );
                                                        })}
                                                </ul>
                                            </PerfectScrollbar>

                                            <div className="p-3 border-top">
                                                <Row>
                                                    <Col>
                                                        <div className="position-relative">
                                                            <input
                                                                type="text"
                                                                value={curMessage}
                                                                // onKeyPress={onKeyPress}
                                                                onChange={(e) => setcurMessage(e.target.value)}
                                                                className="form-control border bg-soft-light"
                                                                placeholder="Enter Message..."
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col className="col-auto">
                                                        <Button
                                                            type="button"
                                                            color="primary"
                                                            onClick={addMessage}
                                                            className="btn btn-primary chat-send w-md"
                                                            disabled={ButtonDisabled}
                                                        >
                                                            <span className="d-none d-sm-inline-block me-2">Send</span>
                                                            {ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>}
                                                            {!ButtonDisabled &&  <i className="mdi mdi-send float-end" />}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Chat;

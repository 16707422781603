// availity-reactstrap-validation
import PropTypes from "prop-types"
import React,{ useState, useRef } from "react"
import MetaTags from "react-meta-tags"
//redux
import { Link, withRouter } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"

import SimpleReactValidator from 'simple-react-validator';
import { useHistory } from 'react-router-dom';
import {post} from '../../helpers/api_helper';
import {POST_FORGOT_PASSWORD,POST_RESET_PASSWORD} from '../../helpers/url_helper';
import {setUserSession,getToken} from '../../helpers/helper';
import { toast } from 'react-toastify';

// import images
import logo from "../../assets/images/logoweb.svg"
// action
import CarouselPage from "./CarouselPage"


const ForgetPasswordPage = props => {
    let history = useHistory();

    if(getToken()){
        history.push("/dashboard");
    }

    const simpleValidator = useRef(new SimpleReactValidator());
    const OtpValidator = useRef(new SimpleReactValidator());

    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [otp_screen, SetOTP_screen] = useState(false);
    const [form_inputs, setInputs] = useState({ email: '', password: '',c_password : '' ,otp : '' });
    const [, forceUpdate] = useState();

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data =  await post(POST_FORGOT_PASSWORD,{'email':form_inputs.email},{});
            if(res_data.status){
                SetOTP_screen(true);
                toast(res_data.message);
            }else{
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // form submit event
    const verifyOtp = async (event) => {
        event.preventDefault();
        const formValid = OtpValidator.current.allValid()
        if (!formValid) {
            OtpValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data =  await post(POST_RESET_PASSWORD,form_inputs,{});
            if(res_data.status){
                setUserSession(res_data.data.token, res_data.data);
                history.push("/dashboard");
            }else{
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }
 
    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }

    return (
        <React.Fragment>
            <MetaTags>
                <title>
                    Forget Password | Free Money Coins
                </title>
            </MetaTags>
            <div className="auth-page">
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <Col lg={3} md={5} className="col-xxl-3">
                            <div className="auth-full-page-content d-flex p-sm-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        <div className="mb-4 mb-md-5 text-center">
                                            <Link to="/dashboard" className="d-block auth-logo">
                                                <img src={logo} alt="" /> <span className="logo-txt"></span>
                                            </Link>
                                        </div>
                                        <div className="auth-content my-auto">
                                            <div className="text-center">
                                                <h5 className="mb-0">Reset Password</h5>
                                                <p className="text-muted mt-2">Reset Password with Free Money Coins.</p>
                                            </div>
                                            {!otp_screen &&
                                            <form className="custom-form mt-4" onSubmit={handleSubmit}>
                                                <div className="mb-3">
                                                    <label className="form-label">Email Address</label>
                                                    <input name="email" className="form-control" value={form_inputs.email} onChange={handleInputChange} placeholder="Enter Email Address" type="text"/>
                                                    {simpleValidator.current.message('email', form_inputs.email, 'required|email')}
                                                </div>
                                                <div className="mb-3 mt-4">
                                                    <button disabled={ButtonDisabled} className="btn btn-primary w-100 waves-effect waves-light" type="submit">{ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Reset</button>
                                                </div>
                                            </form>}

                                            {otp_screen &&
                                            <form className="custom-form mt-4" onSubmit={verifyOtp}>
                                                <div className="mb-3">
                                                    <label className="form-label">OTP</label>
                                                    <input name="otp" className="form-control" maxLength="6" value={form_inputs.otp} onChange={handleInputChange} placeholder="Enter OTP" type="text"/>
                                                    {OtpValidator.current.message('otp', form_inputs.otp, 'required|numeric|min:6')}
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Password</label>
                                                    <input name="password" className="form-control"  value={form_inputs.password} onChange={handleInputChange} placeholder="Enter New Password" type="password"/>
                                                    {OtpValidator.current.message('password', form_inputs.password, 'required')}
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Confirm Password</label>
                                                    <input name="c_password" className="form-control" value={form_inputs.c_password} onChange={handleInputChange} placeholder="Enter Confirm Password" type="password"/>
                                                    {OtpValidator.current.message('confirm password', form_inputs.c_password, `required|in:${form_inputs.password}`, { messages: { in: `Passwords don't match.` } })}
                                                </div>
                                                <div className="mb-3 mt-4">
                                                    <button disabled={ButtonDisabled} className="btn btn-primary w-100 waves-effect waves-light" type="submit">{ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Change</button>
                                                </div>
                                            </form>}

                                            <div className="mt-5 text-center">
                                                <p className="text-muted mb-0">Remember It ?  <Link to="login" className="text-primary fw-semibold"> Sign In </Link> </p>
                                            </div>
                                        </div>
                                        <div className="mt-4 mt-md-5 text-center">
                                            <p className="mb-0">Copyright © {new Date().getFullYear()} FreeMoneyCoins.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <CarouselPage />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

ForgetPasswordPage.propTypes = {
    history: PropTypes.object,
}

export default withRouter(ForgetPasswordPage)

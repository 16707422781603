import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { withRouter } from "react-router-dom"

import { getToken, IsModemAccess } from '../../helpers/helper';
import { post } from '../../helpers/api_helper';

import { POST_GET_CHARGEBACKS_REPORT,POST_UPDATE_USER_STATUS } from '../../helpers/url_helper';
import ReactDOM from 'react-dom';
import { AmountFormat } from '../../helpers/helper';
import { toast } from 'react-toastify';

import $ from 'jquery';

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Moment from 'moment';

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

const ChargebacksReport = props => {

    var start = Moment().format('YYYY-MM-01 00:00:01').toLocaleString();
    start = new Moment(start, "YYYY-MM-DD HH:mm:ss").utc();

    var end = Moment().format('YYYY-MM-DD 23:59:59').toLocaleString();
    end = new Moment(end, "YYYY-MM-DD HH:mm:ss").utc();

    const [StartDate, setStartDate] = useState(start);
    const [EndDate, setEndDate] = useState(end);
    var form_inputs = { start_date: start, end_date: end };
    const [total, setTotal] = useState({ 'total_coins': 0.00, 'total_usd_amount': 0.00 });


    useEffect(() => {
        async function get_detaile() {
            get_list();
        }
        get_detaile();
    }, [])

    // get user role list
    const get_list = () => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                // 'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": POST_GET_CHARGEBACKS_REPORT + '?start_date=' + StartDate + '&end_date=' + EndDate,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [3] },{ "targets": 0, "visible": false }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        // "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                        success: function (result) {
                            setTotal(result.total_amount);
                            fnCallback(result);
                        }
                    });
                },
                "columns": [
                    { "data": "user_id" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<><span className="text-primary cursor-pointer" onClick={() => { window.open('/user-detail/' + data.user_id, "_blank").focus(); }}>{data.username}</span>
                            </>, td)
                        }
                    },
                    { "data": "email" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {data.vpn_count <= 0 && <button className="border-0 badge badge-success fs-6">No</button>}
                                {data.vpn_count > 0 && <button className="border-0 badge badge-danger fs-6" >Yes</button>}
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                               <span className="text-primary cursor-pointer" onClick={() => { window.open('/transactions?id=' + data.user_id+'&email='+data.email+'&transactions_type=Debit', "_blank").focus(); }}>{data.total_tr}</span>
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<> {AmountFormat(data.coins)} </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<> ${AmountFormat(data.usd_amount)} </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<> {AmountFormat(data.total_balance)} </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {data.user_status === 1 && <button onClick={UpdateStatus.bind(this, data.user_id, data.user_status)} className="border-0 badge badge-success fs-6"  >Active</button>}
                                {data.user_status === 0 && <button onClick={UpdateStatus.bind(this, data.user_id, data.user_status)} className="border-0 badge badge-danger fs-6" >Deactive</button>}
                                {data.user_status === 2 && <button className="border-0 badge badge-danger fs-6" >Deleted</button>}
                            </>, td)
                        }
                    },
                ]
            });
        });
    }

    //UpdateStatus
    const UpdateStatus = async (user_id, status) => {
        if (!IsModemAccess('user', 'edit')) {
            toast('Does not permission this module');
            return true;
        }
        var res_data = await post(POST_UPDATE_USER_STATUS, { user_id: user_id, status: status });
        if (res_data.status) {
            toast(res_data.message);
            get_list();
        } else {
            toast.error(res_data.message);
        }
    }

    const applyFilter = async () => {
        form_inputs = { start_date: StartDate, end_date: EndDate };
        await get_list();
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Chargebacks Report | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem="Chargebacks Report" />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg="3 mt-3"></Col>
                                        <Col lg="2 mt-3">
                                            <Flatpickr
                                                className="form-control d-block"
                                                placeholder="Select Start Date"
                                                onChange={(selectedDates, dateStr, instance) => {
                                                    var date = Moment(dateStr).valueOf();
                                                    setStartDate(date);
                                                }}
                                                options={{
                                                    enableTime: true,
                                                    altInput: true,
                                                    altFormat: "d-m-Y H:i",
                                                    dateFormat: "Y-m-d H:i",
                                                    defaultDate: [Moment().format('YYYY-MM-01 00:00:01')]
                                                }}
                                            />
                                        </Col>
                                        <Col lg="2 mt-3">
                                            <Flatpickr
                                                className="form-control d-block"
                                                placeholder="Select End Date"
                                                onChange={(selectedDates, dateStr, instance) => {
                                                    var date = Moment(dateStr).valueOf();
                                                    setEndDate(date);
                                                }}
                                                options={{
                                                    enableTime: true,
                                                    altInput: true,
                                                    altFormat: "d-m-Y H:i",
                                                    dateFormat: "Y-m-d H:i",
                                                    defaultDate: [Moment().format('YYYY-MM-DD 23:59:59')]
                                                }}
                                            />
                                        </Col>
                                        <Col lg="4 mt-3">
                                            <button onClick={applyFilter.bind(this)} className="btn btn-primary w-xs mr-2">Apply</button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="8 mt-3"></Col>
                                        <Col lg="4 mt-3 text-right">
                                            Total Coisn : <b>{total.total_coins}</b> &nbsp; &nbsp;
                                            Total USD : <b>$ {AmountFormat(total.total_usd_amount)}</b>
                                        </Col>
                                    </Row>
                                    <div className="mt-4 mb-5">
                                        {IsModemAccess('offerwall_report', 'view') && <table id="dataTableExample" className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <td>Id</td>
                                                    <td>User Name</td>
                                                    <td>User Email</td>
                                                    <td>VPN</td>
                                                    <td>Total Tr</td>
                                                    <td>Total Coins</td>
                                                    <td>Total Amount USD</td>
                                                    <td>Total Balance</td>
                                                    <td>Action</td>
                                                </tr>
                                            </thead>
                                            <tbody>

                                            </tbody>
                                        </table>}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

        </React.Fragment>

    )
}

export default withRouter(ChargebacksReport)

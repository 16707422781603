import MetaTags from "react-meta-tags"
import React, { useState, useEffect, useRef } from "react"
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap"
import { useHistory, withRouter, Link } from "react-router-dom"

import Loader from './../../components/Common/Loader';
import SimpleReactValidator from 'simple-react-validator';
import { post,get } from '../../helpers/api_helper';
import { IsModemAccess,SingalSelectValueArray,MultiSelectValueArray,arrayColumn } from '../../helpers/helper';
import { POST_GET_CUSTOME_OFFERWALL_DETAIL, POST_ADD_EDIT_CUSTOME_OFFERWALL,POST_GET_CUSTOME_OFFERWALL_FORM_OPTION } from '../../helpers/url_helper';
import { toast } from 'react-toastify';

// Form Editor
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import Select from "react-select"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

const AdminForm = props => {
    let history = useHistory();

    const [isLoader, setLoader] = useState(0);

    const [CountriesList, setCountriesList] = useState([]);
    const [SelectCountries, setSelectCountries] = useState([]);

    const [NetworksList, setNetworksList] = useState([]);
    const [SelectNetworks, setSelectNetworks] = useState([]);
    
    const [CategoryList, setCategoryList] = useState([]);
    const [SelectCategory, setSelectCategory] = useState([]);

    var PlatformList = [
        {'label' : 'Android','value' : 'Android'},
        {'label' : 'iOS','value' : 'iOS'},
        {'label' : 'Desktop','value' : 'Desktop'},
    ];
    const [SelectPlatform, setSelectPlatform] = useState([]);

    const [ButtonDisabled, SetButtonDisabled] = useState(false);

    const [title, setTitle] = useState('Add Custom Offerwall');
    const [form_inputs, setInputs] = useState({ id: 0, title_main: '', title_sub: '', desc_main: '', icon_url: '', image_url: '', offer_network_id: '',offer_country : '',offer_url : '',price :'',platform : '', category_id : ''});

    const simpleValidator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();

    var networks_list = [];
    var countrie_list = [];
    var category_list = [];
    

    useEffect(() => {
        async function get_detaile() {
            await get_form_option();
            if (props.match.params.id) {
                if (!IsModemAccess('custom_offers', 'edit')) {
                    toast('Does not permission this module');
                    history.push("/");
                }

                setTitle('Edit Custom Offerwall');
                var res_data = await post(POST_GET_CUSTOME_OFFERWALL_DETAIL, { 'offerwall_id': props.match.params.id }, {});
                if (res_data.status) {
                    await setInputs(res_data.detail);

                    await setSelectNetworks(await SingalSelectValueArray(networks_list,res_data.detail.offer_network_id));
                    await setSelectCountries(await MultiSelectValueArray(countrie_list,res_data.detail.offer_country));
                    await setSelectPlatform(await MultiSelectValueArray(PlatformList,res_data.detail.platform));
                    await setSelectCategory(await SingalSelectValueArray(category_list,res_data.detail.category_id));

                } else {
                    toast.error(res_data.message);
                    history.push("/");
                }
            } else {
                if (!IsModemAccess('offerwall', 'add')) {
                    toast('Does not permission this module');
                    history.push("/");
                }
            }
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_ADD_EDIT_CUSTOME_OFFERWALL, form_inputs);
            if (res_data.status) {
                toast(res_data.message);
                history.push("/custom-offerwall");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    //get form option
    const get_form_option = async () => {
        var res_data = await get(POST_GET_CUSTOME_OFFERWALL_FORM_OPTION);
        if (res_data.status) {
            networks_list = res_data.networks_list;
            countrie_list = res_data.countrie_list;
            category_list = res_data.categories_list;
            setCountriesList(countrie_list);
            setNetworksList(networks_list);
            setCategoryList(category_list);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name === 'image') {
            var file = event.target.files[0];
            var reader = new FileReader();
            reader.onloadend = function () {
                setInputs(inputs => ({ ...form_inputs, ['image']: reader.result }));
            }
            reader.readAsDataURL(file);
        } else {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }

    }

    const handleEditorChange = (event) => {
        setInputs(inputs => ({ ...form_inputs, ['desc_main']: event }));
    }

    const handleSelectChange = (name,value) => {
        setInputs(inputs => ({ ...form_inputs, [name]: value.value }));
    }

    const handleMultiSelectChange = (name, value) => {
        var values = arrayColumn(value, 'value');
        setInputs(inputs => ({ ...form_inputs, [name]: values.join() }));
    }

    if (!isLoader) {
        return (<Loader></Loader>);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{title} | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem={title} />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <form className="av-invalid" onSubmit={handleSubmit}>
                                        <Row>
                                            <Col lg="6">
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Title</label>
                                                    <input name="title_main" value={form_inputs.title_main} onChange={handleInputChange} className="form-control" placeholder="Enter Title" type="text" />
                                                    {simpleValidator.current.message('title', form_inputs.title_main, 'required')}
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Sub Title</label>
                                                    <input name="title_sub" value={form_inputs.title_sub} onChange={handleInputChange} className="form-control" placeholder="Enter Sub Title" type="text" />
                                                    {simpleValidator.current.message('sub title', form_inputs.title_sub, 'required')}
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Offer Url <small className="ml-2">{`{USER_ID}`}</small></label>
                                                    <input name="offer_url" value={form_inputs.offer_url} onChange={handleInputChange} className="form-control" placeholder="Enter Offer Url" type="text" />
                                                    {simpleValidator.current.message('sub title', form_inputs.offer_url, 'required')}
                                                </div>
                                                <Row>
                                                    <div className="col-sm-6">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label">Price</label>
                                                            <input name="price" value={form_inputs.price} onChange={handleInputChange} className="form-control" placeholder="Enter Price" type="text" />
                                                            {simpleValidator.current.message('price', form_inputs.price, 'required')}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label">Select Platform</label>
                                                            <Select
                                                                name = "platform" 
                                                                defaultValue={SelectPlatform}
                                                                isMulti={true}
                                                                options={PlatformList}
                                                                onChange={handleMultiSelectChange.bind(this,'platform')}
                                                                classNamePrefix="select2-selection"
                                                                isLoading={false}
                                                            />
                                                        </div>
                                                    </div>
                                                </Row>
                                                <Row>
                                                    <div className="col-sm-6">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label">Network</label>
                                                            <Select
                                                                name = "offer_network_id" 
                                                                defaultValue={SelectNetworks}
                                                                isMulti={false}
                                                                options={NetworksList}
                                                                onChange={handleSelectChange.bind(this,'offer_network_id')}
                                                                classNamePrefix="select2-selection"
                                                                isLoading={false}
                                                            />
                                                            {simpleValidator.current.message('network', form_inputs.offer_network_id, 'required')}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label">Countries</label>
                                                            <Select
                                                                name = "offer_country" 
                                                                defaultValue={SelectCountries}
                                                                isMulti={true}
                                                                options={CountriesList}
                                                                onChange={handleMultiSelectChange.bind(this,'offer_country')}
                                                                classNamePrefix="select2-selection"
                                                                isLoading={false}
                                                            />
                                                        </div>
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Col lg="6">
                                                <Row className="mb-4 none">
                                                    <Col lg="8">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label">Icon <small>Accept only png, jpg and jpeg image</small></label>
                                                            <input name="icon" onChange={handleInputChange} className="form-control" placeholder="Select png Image" type="file" accept="image/png,image/jpg,image/jpeg" />
                                                        </div>
                                                    </Col>
                                                    <Col lg="4" className="mt-3">
                                                        {form_inputs.icon_url && <img alt="Offerwall" src={form_inputs.icon_url} style={{ 'maxWidth': '150px' }} />}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="8">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label">Image <small>Accept only png, jpg and jpeg image (Size 210px X 120px)</small></label>
                                                            <input name="image" onChange={handleInputChange} className="form-control" placeholder="Select png Image" type="file" accept="image/png,image/jpg,image/jpeg" />
                                                        </div>
                                                    </Col>
                                                    <Col lg="4" className="mt-3">
                                                        {form_inputs.image_url && <img alt="Offerwall" src={form_inputs.image_url} style={{ 'maxWidth': '150px' }} />}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <div className="col-sm-6">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label">Select Category</label>
                                                            <Select
                                                                name = "category_id" 
                                                                defaultValue={SelectCategory}
                                                                isMulti={false}
                                                                options={CategoryList}
                                                                onChange={handleSelectChange.bind(this,'category_id')}
                                                                classNamePrefix="select2-selection"
                                                                isLoading={false}
                                                            />
                                                            {simpleValidator.current.message('category', form_inputs.category_id, 'required')}
                                                        </div>
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12 mt-5">
                                                <ReactQuill
                                                    theme='snow'
                                                    value={form_inputs.desc_main}
                                                    onChange={handleEditorChange}
                                                />
                                            </Col>
                                        </Row>
                                        <div className="text-center mt-4">
                                            <Button disabled={ButtonDisabled} type="submit" color="primary">{ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Save</Button>
                                            <Link to="/custom-offerwall" className="btn btn-danger ms-3"> Cancel</Link>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(AdminForm)

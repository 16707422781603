import MetaTags from "react-meta-tags"
import React, { useState,useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"

// availity-reactstrap-validation
import { withRouter,useHistory } from "react-router-dom"

import { get } from '../../helpers/api_helper';
import { IsModemAccess,DateUTCToLocal } from '../../helpers/helper';
import { POST_GET_API_LOG_DETAIL_OPTION } from '../../helpers/url_helper';
import { toast } from 'react-toastify';

import Loader from '../../components/Common/Loader';
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import ReactJson from 'react-json-view'

const UserProfile = props => {
    let history = useHistory();

    const [isLoader, setLoader] = useState(0);
    const [Detail, setDetail] = useState();

    useEffect(() => {
        async function get_detaile() {
            if (props.match.params.id) {
                if(!IsModemAccess('api_logs','view')){
                    toast('Does not permission this module');
                    history.push("/");
                }
                
                var res_data = await get(POST_GET_API_LOG_DETAIL_OPTION+'?log_id='+props.match.params.id);
                if (res_data.status) {
                    setDetail(res_data.detail);
                } else {
                    toast.error(res_data.message);
                    history.push("/");
                }
            }
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])

    if (!isLoader) {
        return (<Loader></Loader>);
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Api Log Detail | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem="Api Log Detail" />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">Log Id :</h5>
                                            <span>{Detail.id}</span>
                                        </Col>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">User Name :</h5>
                                            <span>{Detail.game_type} ({Detail.user_name})</span>
                                        </Col>
                                        <Col lg="3 mb-3">
                                            <h5 className="font-size-15 mb-1">Date Time :</h5>
                                            <span>{DateUTCToLocal(Detail.created_at)}</span>
                                        </Col>
                                    </Row>
                                    <hr/>
                                    <Row>
                                        <Col lg="12">
                                            <h5 className="mb-3">Body :</h5>
                                        </Col> 
                                        <Col lg="12 mb-3">
                                            <h6>Url</h6>
                                            <p className="mb-0">{Detail.url}</p>
                                        </Col>
                                        <Col lg="12 mb-3">
                                            <h6>Device Type</h6>
                                            <p className="mb-0">{Detail.device_type}</p>
                                        </Col>
                                        <Col lg="12 mb-3">
                                            <h6>Method</h6>
                                            <p className="mb-0">{Detail.method}</p>
                                        </Col>
                                        <Col lg="12 mb-3">
                                            <h6>Api Respons</h6>
                                            <ReactJson src={JSON.parse(Detail.data)} />
                                        </Col>
                                    </Row>
                                    
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(UserProfile)

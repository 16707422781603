import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { withRouter } from "react-router-dom"

import { getToken, IsModemAccess, DateUTCToLocal } from '../../helpers/helper';
import { post } from '../../helpers/api_helper';
import { POST_GET_OFFERWALL_REPORT, POST_GET_NETWORKS_LIST } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import ReactDOM from 'react-dom';
import { AmountFormat } from '../../helpers/helper';

import $ from 'jquery';

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Moment from 'moment';

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import jsPDF from "jspdf";
import "jspdf-autotable";
import Select from "react-select"

const UserProfile = props => {

    var start = Moment().format('YYYY-MM-01 00:00:01').toLocaleString();
    start = new Moment(start, "YYYY-MM-DD HH:mm:ss").utc();

    var end = Moment().format('YYYY-MM-DD 23:59:59').toLocaleString();
    end = new Moment(end, "YYYY-MM-DD HH:mm:ss").utc();

    const [StartDate, setStartDate] = useState(start);
    const [EndDate, setEndDate] = useState(end);
    const [networkId, setNetworkId] = useState('');
    const [NetworksList, setNetworksList] = useState([]);
    const [total, setTotal] = useState({ 'total_coins': 0.00, 'total_usd_amount': 0.00 });
    var form_inputs = { start_date: start, end_date: end, network_id: '' };

    const [ButtonDisabled, SetButtonDisabled] = useState(false);

    useEffect(() => {
        async function get_detaile() {
            get_form_option();
            get_list();
        }
        get_detaile();
    }, [])

    // get user role list
    const get_list = () => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                // 'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": POST_GET_OFFERWALL_REPORT + '?start_date=' + StartDate + '&end_date=' + EndDate + '&network_id=' + networkId,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [] }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        // "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                        success: function (result) {
                            setTotal(result.total_amount);
                            fnCallback(result);
                        }
                    });
                },
                "columns": [
                    { "data": "txn_id" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<> {DateUTCToLocal(data.created_at)}</>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<><span className="text-primary cursor-pointer" onClick={() => { window.open('/user-detail/' + data.user_id, "_blank").focus(); }}>{data.username}</span>
                            </>, td)
                        }
                    },
                    { "data": "network" },
                    { "data": "txn_for" },
                    { "data": "txn_type" },
                    { "data": "coins" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<> $ {parseFloat(data.usd_amount).toFixed(4)} </>, td)
                        }
                    }
                ]
            });
        });
    }

    const exportToCSV = async () => {
        SetButtonDisabled(true);
        var res_data = await post(POST_GET_OFFERWALL_REPORT + '?start_date=' + form_inputs.start_date + '&end_date=' + form_inputs.end_date + '&is_export=1'+ '&network_id=' + networkId);
        if (res_data.status) {
            var export_data = [];
            if (res_data.aaData) {
                for (const data of res_data.aaData) {
                    export_data.push({
                        'Transaction Id': data.txn_id,
                        'Date': DateUTCToLocal(data.created_at),
                        'User Name': data.username,
                        'Network': data.network,
                        'Txn For': data.txn_for,
                        'Txn Type': data.txn_type,
                        'Coins': data.coins,
                        'USD Amount ($)': parseFloat(data.usd_amount).toFixed(4),
                    });
                }
            }
            export_data.push({
                'Transaction Id': ' ',
                'Date': ' ',
                'User Name': ' ',
                'Network': ' ',
                'Txn For': ' ',
                'Txn Type': 'Total',
                'Coins': res_data.total_amount.total_coins,
                'USD Amount ($)': parseFloat(res_data.total_amount.total_usd_amount).toFixed(4),
            });
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            var fileName = 'Offerwall-report.xlsx';
            const ws = XLSX.utils.json_to_sheet(export_data);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName);
        } else {
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    }

    const exportToPDF = async () => {
        SetButtonDisabled(true);
        var res_data = await post(POST_GET_OFFERWALL_REPORT + '?start_date=' + StartDate + '&end_date=' + EndDate + '&is_export=1'+ '&network_id=' + networkId);
        if (res_data.status) {
            const doc = new jsPDF({ "orientation": "landscape" });

            const tableColumn = ["Transaction Id", "Date", "User Name", "Network", "Txn For", "Txn Type", "Coins", "USD ($)"];
            const tableRows = [];

            if (res_data.aaData) {
                for (const data of res_data.aaData) {
                    tableRows.push([
                        data.txn_id,
                        DateUTCToLocal(data.created_at),
                        data.username,
                        data.network,
                        data.txn_for,
                        data.txn_type,
                        data.coins,
                        parseFloat(data.usd_amount).toFixed(4),
                    ]);
                }
            }
            tableRows.push([
                ' ',
                ' ',
                ' ',
                ' ',
                ' ',
                'Total',
                res_data.total_amount.total_coins,
                parseFloat(res_data.total_amount.total_usd_amount).toFixed(4),
            ]);

            doc.autoTable(tableColumn, tableRows, { startY: 10 });
            // doc.text("Offerwall Report.", 14, 15);
            doc.save(`Offerwall-report.pdf`);

        } else {
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    }

    const handleSelectChange = (name, value) => {
        setNetworkId(value.value)
    }

    const applyFilter = async () => {
        form_inputs = { start_date: StartDate, end_date: EndDate };
        await get_list();
    }

    const get_form_option = async () => {
        var res_data = await post(POST_GET_NETWORKS_LIST);
        if (res_data.status) {
            setNetworksList(res_data.data.networks_list);
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Offerwall Report | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem="Offerwall Report" />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg="1 mt-3"></Col>
                                        <Col lg="2 mt-3">
                                            <Flatpickr
                                                className="form-control d-block"
                                                placeholder="Select Start Date"
                                                onChange={(selectedDates, dateStr, instance) => {
                                                    var date = Moment(dateStr).valueOf();
                                                    setStartDate(date);
                                                }}
                                                options={{
                                                    enableTime: true,
                                                    altInput: true,
                                                    altFormat: "d-m-Y H:i",
                                                    dateFormat: "Y-m-d H:i",
                                                    defaultDate: [Moment().format('YYYY-MM-01 00:00:01')]
                                                }}
                                            />
                                        </Col>
                                        <Col lg="2 mt-3">
                                            <Flatpickr
                                                className="form-control d-block"
                                                placeholder="Select End Date"
                                                onChange={(selectedDates, dateStr, instance) => {
                                                    var date = Moment(dateStr).valueOf();
                                                    setEndDate(date);
                                                }}
                                                options={{
                                                    enableTime: true,
                                                    altInput: true,
                                                    altFormat: "d-m-Y H:i",
                                                    dateFormat: "Y-m-d H:i",
                                                    defaultDate: [Moment().format('YYYY-MM-DD 23:59:59')]
                                                }}
                                            />
                                        </Col>
                                        <Col lg="2 mt-3">
                                            <Select
                                                name="network_id"
                                                isMulti={false}
                                                options={NetworksList}
                                                onChange={handleSelectChange.bind(this, 'network_id')}
                                                classNamePrefix="select2-selection"
                                                isLoading={false}
                                            />
                                        </Col>
                                        <Col lg="4 mt-3">
                                            <button onClick={applyFilter.bind(this)} className="btn btn-primary w-xs mr-2">Apply</button>
                                            <button type="button" disabled={ButtonDisabled} className="btn btn-info mr-2 w-xs" onClick={(e) => exportToCSV()}> {ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Export XLSX</button>
                                            <button type="button" disabled={ButtonDisabled} className="btn btn-info w-xs" onClick={(e) => exportToPDF()}> {ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Export PDF</button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="8 mt-3"></Col>
                                        <Col lg="4 mt-3 text-right">
                                            Total Coisn : <b>{total.total_coins}</b> &nbsp; &nbsp;
                                            Total USD : <b>$ {AmountFormat(total.total_usd_amount)}</b>
                                        </Col>
                                    </Row>
                                    <div className="mt-4 mb-5">
                                        {IsModemAccess('offerwall_report', 'view') && <table id="dataTableExample" className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <td>Transaction Id</td>
                                                    <td>Date</td>
                                                    <td>User Name</td>
                                                    <td>Network</td>
                                                    <td>Txn For</td>
                                                    <td>Txn Type</td>
                                                    <td>Coins</td>
                                                    <td>USD Amount</td>
                                                </tr>
                                            </thead>
                                            <tbody>

                                            </tbody>
                                        </table>}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

        </React.Fragment>

    )
}

export default withRouter(UserProfile)

import MetaTags from "react-meta-tags"
import React, { useState, useEffect, useRef } from "react"
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap"
import { useHistory, withRouter, Link } from "react-router-dom"

import Loader from './../../components/Common/Loader';
import SimpleReactValidator from 'simple-react-validator';
import { post } from '../../helpers/api_helper';
import { IsModemAccess } from '../../helpers/helper';
import {  POST_ADD_EDIT_BANNER } from '../../helpers/url_helper';

import { toast } from 'react-toastify';
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

const AdminForm = props => {
    let history = useHistory();

    const [isLoader, setLoader] = useState(0);
    const simpleValidator = useRef(new SimpleReactValidator());

    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [title, setTitle] = useState('Add Banner');
    const [form_inputs, setInputs] = useState({ banners_id: 0, banner_image : '', banner_url: ''});
    const [, forceUpdate] = useState();

    useEffect(() => {
        async function get_detaile() {
            if (props.match.params.id) {
                if(!IsModemAccess('banner','edit')){
                    toast('Does not permission this module');
                    history.push("/");
                }
                setTitle('Edit Banner');
            }else{
                if(!IsModemAccess('banner','add')){
                    toast('Does not permission this module');
                    history.push("/");
                }
            }
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_ADD_EDIT_BANNER, form_inputs);
            if (res_data.status) {
                toast(res_data.message);
                history.push("/banner");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if(event.target.name === 'image'){
            var file = event.target.files[0];  
            var reader = new FileReader();  
            reader.onloadend = function() { 
                setInputs(inputs => ({ ...form_inputs, ['image']: reader.result }));
            }  
            reader.readAsDataURL(file); 
        }else{
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }
    
    if (!isLoader) {
        return (<Loader></Loader>);
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{title} | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem={title} />

                    <Row>
                        <Col lg="6">
                            <Card>
                                <CardBody style={{'minHeight': 'calc(100vh - 215px)'}}>
                                    <form className="av-invalid" onSubmit={handleSubmit}>
                                        <Row>
                                            <Col lg="12">
                                                <Row>
                                                    <Col lg="8">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label">Image <small>Accept only png, jpg and jpeg image</small> <small className="ml-3">Size (750px X 200px)</small></label>
                                                            <input name="image" onChange={handleInputChange} className="form-control" placeholder="Select png Image" type="file" accept="image/png,image/jpg,image/jpeg"/>
                                                        </div>
                                                    </Col>
                                                    <Col lg="4" className="mt-3">
                                                        {form_inputs.banner_image && <img alt="Banner" src={form_inputs.banner_image} style={{'maxWidth' : '200px'}}/>}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <div className="text-center mt-4">
                                            <Button disabled={ButtonDisabled} type="submit" color="primary">{ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Save</Button>
                                            <Link to="/banner" className="btn btn-danger ms-3"> Cancel</Link>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(AdminForm)

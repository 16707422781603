import MetaTags from "react-meta-tags"
import React, { useState,useEffect } from "react"
import {Container,Row,Col,Card,CardBody,Button} from "reactstrap"
import { useHistory,withRouter,Link } from "react-router-dom"

import {post} from '../../helpers/api_helper';
import {GET_ROLE_ASSIGNED,POST_UPDATE_ROLE_ASSIGEND} from '../../helpers/url_helper';
import {IsModemAccess} from '../../helpers/helper';
import { toast } from 'react-toastify';


//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import Loader from './../../components/Common/Loader';

const UserProfile = props => {
    let history = useHistory();

    if(!IsModemAccess('use_role','edit')){
        toast('Does not permission this module');
        history.push("/");
    }

    const [isLoader, setLoader] = useState(0);
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState([]);
    const [roles_id, setRolesId] = useState(0);

    useEffect(() => {
        async function get_detaile () {
            if (props.match.params.id) {
                setRolesId(props.match.params.id);
                var res_data = await post(GET_ROLE_ASSIGNED,{'user_role_id' : props.match.params.id},{});
                if (res_data.status) {
                    setInputs(res_data.role);
                } else {
                    toast.error(res_data.message);
                    history.push("/");
                }
            }
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
       
        SetButtonDisabled(true);
        var res_data =  await post(POST_UPDATE_ROLE_ASSIGEND,{'roles_id':roles_id,'role':form_inputs},{});
        if(res_data.status){
            toast(res_data.message);
            history.push("/user-roles");
        }else{
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    }

    // input text change handler
    const handleInputChange = (key,event) => {
        event.persist();
        var data = form_inputs;
        data[key].module[event.target.name] = (event.target.checked)? 1 : 0;
        setInputs(data);
    }

    if (!isLoader) {
        return (<Loader></Loader>);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Role Assigned | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem="Role Assigned" />

                    <Row>
                        <Col lg="8">
                            <Card>
                                <CardBody>
                                    <form className="av-invalid" onSubmit={handleSubmit}>
                                        <div className="table-responsive">
                                            <table className="align-middle mb-0 table">
                                                <thead>
                                                    <tr>
                                                        <th>Module Name</th>
                                                        <th>Add</th>
                                                        <th>Edit</th>
                                                        <th>Delete</th>
                                                        <th>View</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Object.entries(form_inputs).map(([key, value]) => ( 
                                                    <tr>
                                                        <td>{value.name}</td>
                                                        <td>
                                                            {value.module.add === 3 && "-"}
                                                            {value.module.add !== 3 && 
                                                            <div className="form-check form-switch form-switch-md mt-0" dir="ltr">
                                                                <input name="add" onChange={(event) => handleInputChange(key,event)} defaultChecked={value.module.add} type="checkbox" className="form-check-input" />
                                                            </div>}
                                                        </td>
                                                        <td>
                                                            {value.module.edit === 3 && "-"}
                                                            {value.module.edit !== 3 && <div className="form-check form-switch form-switch-md mt-0" dir="ltr">
                                                                <input name="edit" onChange={(event) => handleInputChange(key,event)} defaultChecked={value.module.edit} type="checkbox" className="form-check-input" />
                                                            </div>}
                                                        </td>
                                                        <td>
                                                            {value.module.delete === 3 && "-"}
                                                            {value.module.delete !== 3 && <div className="form-check form-switch form-switch-md mt-0" dir="ltr">
                                                                <input name="delete" onChange={(event) => handleInputChange(key,event)} defaultChecked={value.module.delete} type="checkbox" className="form-check-input" />
                                                            </div>}
                                                        </td>
                                                        <td>
                                                            {value.module.view === 3 && "-"}
                                                            {value.module.view !== 3 && <div className="form-check form-switch form-switch-md mt-0" dir="ltr">
                                                                <input name="view" onChange={(event) => handleInputChange(key,event)} defaultChecked={value.module.view} type="checkbox" className="form-check-input" />
                                                            </div>}
                                                        </td>
                                                        <td>
                                                            {value.module.action === 3 && "-"}
                                                            {value.module.action !== 3 && <div className="form-check form-switch form-switch-md mt-0" dir="ltr">
                                                                <input name="action" onChange={(event) => handleInputChange(key,event)} defaultChecked={value.module.action} type="checkbox" className="form-check-input" />
                                                            </div>}
                                                        </td>
                                                        
                                                    </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <div className="text-center mt-4">
                                                <Button disabled={ButtonDisabled} type="submit" color="primary">{ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Save</Button>
                                                <Link to="/user-roles" className="btn btn-danger ms-3"> Cancel</Link>
                                            </div>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(UserProfile)

import MetaTags from "react-meta-tags"
import React, { useState, useEffect, useRef } from "react"
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap"
import { useHistory, withRouter } from "react-router-dom"

import Loader from '../../components/Common/Loader';
import SimpleReactValidator from 'simple-react-validator';
import { post, get } from '../../helpers/api_helper';
import { POST_CREATE_BOT, GET_PRO_SETTING, POST_UPDATE_PRO_SETTING } from '../../helpers/url_helper';
import { IsModemAccess } from '../../helpers/helper';
import { toast } from 'react-toastify';
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"


const ProSettingForm = props => {
    let history = useHistory();

    if (!IsModemAccess('pro_setting', 'edit')) {
        toast('Does not permission this module');
        history.push("/");
    }

    const [isLoader, setLoader] = useState(0);
    const [OfferwallOptionList, setOfferwallOptionList] = useState([]);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({});
    const [, forceUpdate] = useState();

    useEffect(() => {
        async function get_detaile() {
            await get_app_setting();
            setLoader(1);
        }
        get_detaile();
    }, [])

    const get_app_setting = async () => {
        var res_data = await get(GET_PRO_SETTING);
        if (res_data.status) {
            await setInputs(res_data.data.setting_list);
            await setOfferwallOptionList(res_data.data.offerwall_option);
        } else {
            toast.error(res_data.message);
        }
    }

    // form submit event
    const Create_pro = async (event) => {
        event.preventDefault();
        SetButtonDisabled(true);

        var data = { 'generate_bot': form_inputs.generate_bot }
        var res_data = await post(POST_CREATE_BOT, data, {});
        if (res_data.status) {
            setInputs(inputs => ({ ...form_inputs, ['generate_bot']: '' }));
            toast(res_data.message);
        } else {
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_UPDATE_PRO_SETTING, form_inputs, {});
            if (res_data.status) {
                await get_app_setting();
                toast(res_data.message);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if (
            event.target.name === 'pro_enable' ||
            event.target.name === 'leaderboard_user_disabled'
        ) {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: (event.target.checked) ? 1 : 0 }));
        } else {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    if (!isLoader) {
        return (<Loader></Loader>);
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Pro Setting | FreeMoneyCoins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem="Pro Setting" />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <form className="av-invalid" onSubmit={handleSubmit}>
                                        <Row>
                                            <Col lg="4" >
                                                <div className="border card-body rounded bg-light-box">
                                                    <h4 className="card-title">Pro Generator</h4>
                                                    <Row>
                                                        <Col sm="8">
                                                            <div className="form-group mb-3">
                                                                <input name="generate_bot" value={form_inputs.generate_bot} onChange={handleInputChange} className="form-control" placeholder={`Enter Number`} type="text" />
                                                            </div>
                                                        </Col>
                                                        <Col sm="4">
                                                            <Button type="button" onClick={Create_pro} color="primary" disabled={ButtonDisabled} >{ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>}Create Pro</Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="border card-body rounded bg-light-box mt-3">
                                                    <div className="row mb-3">
                                                        <div className="col-8">
                                                            <label className="form-label text-capitalize">Pro Enable</label>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="form-check form-switch form-switch-md mt-0" dir="ltr">
                                                                <input name="pro_enable" onChange={handleInputChange} defaultChecked={parseInt(form_inputs.pro_enable)} type="checkbox" className="form-check-input" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-group mb-3">
                                                                <label className="form-label text-capitalize">Min Coins</label>
                                                                <input name="pro_min_coins" value={form_inputs.pro_min_coins} onChange={handleInputChange} className="form-control" placeholder={`Enter Coins`} type="text" />
                                                                {simpleValidator.current.message('coins', form_inputs.pro_min_coins, 'required|numeric')}
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-group mb-3">
                                                                <label className="form-label text-capitalize">Max Coins</label>
                                                                <input name="pro_max_coins" value={form_inputs.pro_max_coins} onChange={handleInputChange} className="form-control" placeholder={`Enter Coins`} type="text" />
                                                                {simpleValidator.current.message('coins', form_inputs.pro_max_coins, 'required|numeric')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <label className="form-label text-capitalize">Select Offerwall</label>
                                                        <select name="pro_offerwall_id" value={form_inputs.pro_offerwall_id} onChange={handleInputChange} className="form-control">
                                                            <option value="">Select Offerwall Gateway</option>
                                                            {OfferwallOptionList && OfferwallOptionList.map((option, index) => (
                                                                <><option value={option.id}>{option.network}</option></>
                                                            ))}

                                                        </select>
                                                        {simpleValidator.current.message('Offerwall gateway', form_inputs.pro_offerwall_id, 'required')}
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <label className="form-label text-capitalize">Number Of Complete Offer</label>
                                                        <input name="pro_number_of_complete_offer" value={form_inputs.pro_number_of_complete_offer} onChange={handleInputChange} className="form-control" placeholder={`Enter Number`} type="text" />
                                                        {simpleValidator.current.message('amount', form_inputs.pro_number_of_complete_offer, 'required|numeric')}
                                                    </div>
                                                   
                                                    <div className="row mb-3 mt-5">
                                                        <div className="col-8">
                                                            <label className="form-label text-capitalize">Leaderboard User Disabled</label>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="form-check form-switch form-switch-md mt-0" dir="ltr">
                                                                <input name="leaderboard_user_disabled" onChange={handleInputChange} defaultChecked={parseInt(form_inputs.leaderboard_user_disabled)} type="checkbox" className="form-check-input" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-group mb-3">
                                                                <label className="form-label text-capitalize">Min Range</label>
                                                                <input name="leaderboard_user_min_range" value={form_inputs.leaderboard_user_min_range} onChange={handleInputChange} className="form-control" placeholder={`Enter Range`} type="text" />
                                                                {simpleValidator.current.message('range', form_inputs.leaderboard_user_min_range, 'required|numeric')}
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-group mb-3">
                                                                <label className="form-label text-capitalize">Max Range</label>
                                                                <input name="leaderboard_user_max_range" value={form_inputs.leaderboard_user_max_range} onChange={handleInputChange} className="form-control" placeholder={`Enter Range`} type="text" />
                                                                {simpleValidator.current.message('range', form_inputs.leaderboard_user_max_range, 'required|numeric')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-center mt-4">
                                                    <Button disabled={ButtonDisabled} type="submit" color="primary">{ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Update</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(ProSettingForm)

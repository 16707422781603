import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"

// availity-reactstrap-validation
import { withRouter, useHistory } from "react-router-dom"
import { get } from '../../helpers/api_helper';
import { getToken } from '../../helpers/helper';
import { POST_GET_LEADERBOARD_DETAIL, POST_GET_LEADERBOARD_USER } from '../../helpers/url_helper';
import ReactDOM from 'react-dom';
import Loader from '../../components/Common/Loader';
import { toast } from 'react-toastify';

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"


import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";

const UserProfile = props => {
    let history = useHistory();

    const [isLoader, setLoader] = useState(0);
    const [Detail, setDetail] = useState(0);

    useEffect(() => {
        async function get_detaile() {
            if (props.match.params.id) {
                get_detail();
                get_user();
            }
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])

    const get_detail = async () => {
        var res_data = await get(POST_GET_LEADERBOARD_DETAIL + '?id=' + props.match.params.id);
        if (res_data.status) {
            setDetail(res_data.data.detail);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

    const get_user = () => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": POST_GET_LEADERBOARD_USER + '?id=' + props.match.params.id,
                "order": [[0, 'asc']],
                columnDefs: [{ orderable: false, targets: [] }, { "targets": 0, "visible": false }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "leaderboard_results_detail_id" },
                    { "data": "rank" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<><a href="javascript:;" onClick={() => { window.open('/user-detail/' + data.user_id, "_blank").focus(); history.push(); }}>{data.username}</a></>, td)
                        }
                    },
                    { "data": "prize" },
                ]
            });
        });
    }



    if (!isLoader) {
        return (<Loader></Loader>);
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Leaderboard Detail | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem="Leaderboard Detail" />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg="8">
                                            <div className="row">
                                                <div className="col-lg-3 mb-3">
                                                    <h5 className="font-size-15 mb-1">Type :</h5>
                                                    <span>{Detail.type}</span>
                                                </div>
                                                <div className="col-lg-3 mb-3">
                                                    <h5 className="font-size-15 mb-1">Start Date :</h5>
                                                    <span>{Detail.start_date}</span>
                                                </div>
                                                <div className="col-lg-3 mb-3">
                                                    <h5 className="font-size-15 mb-1">End Date :</h5>
                                                    <span>{Detail.end_date}</span>
                                                </div>
                                                <div className="col-lg-3 mb-3">
                                                    <h5 className="font-size-15 mb-1">Total User :</h5>
                                                    <span>{Detail.total_user}</span>
                                                </div>
                                                <div className="col-lg-3 mb-3">
                                                    <h5 className="font-size-15 mb-1">Total Coins :</h5>
                                                    <span>{Detail.total_coins}</span>
                                                </div>
                                                <div className="col-lg-3 mb-3">
                                                    <h5 className="font-size-15 mb-1">Result Date :</h5>
                                                    <span>{Detail.created_at}</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg="4">
                                            <table className="table">
                                                <tr>
                                                    <th>Rank</th>
                                                    <th>Prize</th>
                                                </tr>
                                                {Detail.leaderboard_prize_detail && Object.entries(Detail.leaderboard_prize_detail).map(([key, value]) => {
                                                    return (
                                                        <tr>
                                                            {value.start_range === value.end_range && <td>{value.start_range} </td>}
                                                            {value.start_range !== value.end_range && <td>{value.start_range} - {value.end_range}</td>}
                                                            <td>{value.prize} Coins</td>
                                                        </tr>
                                                    )
                                                })}
                                            </table>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <table id="dataTableExample" className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <td>Id</td>
                                                        <td>Rank</td>
                                                        <td>User Name</td>
                                                        <td>Prize</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(UserProfile)

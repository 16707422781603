import MetaTags from "react-meta-tags"
import React, { useState, useEffect, useRef } from "react"
import { Container, Row, Col, Button } from "reactstrap"
import { useHistory, withRouter, Link } from "react-router-dom"

import Loader from '../../components/Common/Loader';
import SimpleReactValidator from 'simple-react-validator';
import { post, get } from '../../helpers/api_helper';
import { POST_UPDATE_SETTING, GET_ALL_SETTING } from '../../helpers/url_helper';
import { IsModemAccess } from '../../helpers/helper';
import { toast } from 'react-toastify';
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"


const AdminForm = props => {
    let history = useHistory();

    if (!IsModemAccess('app_setting', 'edit')) {
        toast('Does not permission this module');
        history.push("/");
    }
    const [isLoader, setLoader] = useState(0);
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState();
    const simpleValidator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();

    useEffect(() => {
        async function get_detaile() {
            await get_app_setting();
            setLoader(1);
        }
        get_detaile();
    }, [])

    const get_app_setting = async () => {
        var res_data = await get(GET_ALL_SETTING + "?type=common-setting"); 
        if (res_data.status) {
            await setInputs(res_data.data);
        } else {
            toast.error(res_data.message);
        }
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_UPDATE_SETTING, form_inputs, {});
            if (res_data.status) {
                await get_app_setting();
                toast(res_data.message);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        console.log(event.target.name);
        if (
            event.target.name === 'enable_user_activity' ||
            event.target.name === 'save_admin_api_log' || 
            event.target.name === 'enable_registration' ||
            event.target.name === 'user_chat_enable' ||
            event.target.name === 'chat_enable' ||
            event.target.name === 'home_page_show_dummy_data' ||
            event.target.name === 'social_media_login_enable' ||
            event.target.name === 'social_media_register_enable' ||
            event.target.name === 'enable_google_recapacha' ||
            event.target.name === 'enable_vpn_check' ||
            event.target.name === 'enable_login' 
        ) {
            console.log((event.target.checked) ? '1' : '0');
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: (event.target.checked) ? '1' : '0' }));
        } else {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    if (!isLoader) {
        return (<Loader></Loader>);
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Common Setting | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem="Common Setting" />

                    <Row>
                        <Col lg="6">
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className="border card-body rounded bg-light-box">
                                    <Row>
                                        <Col lg="6" >
                                            <div className="form-group mb-3">
                                                <label className="form-label text-capitalize">Exchange Rate One USD($) To Coin  
                                                <br/><small>Ex. $1 = 100Coins</small></label>
                                                <input name="exchange_rate_on_usd_coin" value={form_inputs.exchange_rate_on_usd_coin} onChange={handleInputChange} className="form-control" placeholder={`Enter Exchange Rate`} type="text" />
                                            </div>
                                            <div className="form-group mb-3">
                                                <label className="form-label text-capitalize">Exchange Rate One Xp To Coin  
                                                <br/><small>Ex. 1xp = 10Coins</small></label>
                                                <input name="exchange_rate_one_xp_coin" value={form_inputs.exchange_rate_one_xp_coin} onChange={handleInputChange} className="form-control" placeholder={`Enter Xp Rate`} type="text" />
                                            </div>
                                            <div className="form-group mb-3">
                                                <label className="form-label text-capitalize">GST (%)</label>
                                                <input name="gst_percentage" value={form_inputs.gst_percentage} onChange={handleInputChange} className="form-control" placeholder={`GST %`} type="text" />
                                            </div>
                                            <div className="form-group mb-3">
                                                <label className="form-label text-capitalize">Twitter Follow Coins</label>
                                                <input name="twitter_follow_coins" value={form_inputs.twitter_follow_coins} onChange={handleInputChange} className="form-control" placeholder={`Enter Coins`} type="text" />
                                            </div>
                                            <div className="form-group mb-3">
                                                <label className="form-label text-capitalize">Download APK Coins</label>
                                                <input name="download_apk_coins" value={form_inputs.download_apk_coins} onChange={handleInputChange} className="form-control" placeholder={`Enter Coins`} type="text" />
                                            </div>
                                            <div className="form-group mb-3">
                                                <label className="form-label text-capitalize">App Android Version</label>
                                                <input name="app_version_android" value={form_inputs.app_version_android} onChange={handleInputChange} className="form-control" placeholder={`Enter Version`} type="text" />
                                            </div>
                                            <div className="form-group mb-3">
                                                <label className="form-label text-capitalize">Android Version Hard Update <small> (0 - Normal , 1 - Hard Update)</small></label>
                                                <input name="app_android_forcefully_update" value={form_inputs.app_android_forcefully_update} onChange={handleInputChange} className="form-control" placeholder={`Enter Android Version`} type="text" />
                                                {simpleValidator.current.message('android version', form_inputs.app_android_forcefully_update, 'required')}
                                            </div>
                                            <div className="form-group mb-3">
                                                <label className="form-label text-capitalize">App Update Note</label>
                                                <input name="app_note" value={form_inputs.app_note} onChange={handleInputChange} className="form-control" placeholder={`Enter note`} type="text" />
                                            </div>
                                           
                                        </Col>
                                        <Col lg="6">
                                            <div className="form-group mb-3">
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <label className="form-label text-capitalize">Signup Bonus</label>
                                                    </div>
                                                    <div className="col-sm-6 text-right">
                                                        <Link to="signup-winning-amount">Set Winning Amount</Link>
                                                    </div>
                                                </div>
                                                <input name="signup_bonus" value={form_inputs.signup_bonus} onChange={handleInputChange} className="form-control" placeholder={`Enter Signup Bonus`} type="text" />
                                            </div>
                                            <div className="form-group mb-3">
                                                <label className="form-label text-capitalize">Referral Bonus</label>
                                                <input name="referral_bonus" value={form_inputs.referral_bonus} onChange={handleInputChange} className="form-control" placeholder={`Enter Referral Bonus`} type="text" />
                                            </div>
                                            <div className="form-group mb-3">
                                                <label className="form-label text-capitalize">Referral Commission Percentage</label>
                                                <input name="referral_commission" value={form_inputs.referral_commission} onChange={handleInputChange} className="form-control" placeholder={`Enter Percentage`} type="text" />
                                            </div>
                                            <div className="row mb-3 mt-4">
                                                <div className="col-8">
                                                    <label className="form-label text-capitalize">Api Log Enable</label>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-check form-switch form-switch-md mt-0" dir="ltr">
                                                        <input name="save_admin_api_log" onChange={handleInputChange} defaultChecked={parseInt(form_inputs.save_admin_api_log)} type="checkbox" className="form-check-input" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3 mt-4">
                                                <div className="col-8">
                                                    <label className="form-label text-capitalize">User Activity Log Enable</label>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-check form-switch form-switch-md mt-0" dir="ltr">
                                                        <input name="enable_user_activity" onChange={handleInputChange} defaultChecked={parseInt(form_inputs.enable_user_activity)} type="checkbox" className="form-check-input" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3 mt-4">
                                                <div className="col-8">
                                                    <label className="form-label text-capitalize">Login Enable</label>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-check form-switch form-switch-md mt-0" dir="ltr">
                                                        <input name="enable_login" onChange={handleInputChange} defaultChecked={parseInt(form_inputs.enable_login)} type="checkbox" className="form-check-input" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3 mt-4">
                                                <div className="col-8">
                                                    <label className="form-label text-capitalize">Registration Enable</label>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-check form-switch form-switch-md mt-0" dir="ltr">
                                                        <input name="enable_registration" onChange={handleInputChange} defaultChecked={parseInt(form_inputs.enable_registration)} type="checkbox" className="form-check-input" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3 mt-4">
                                                <div className="col-8">
                                                    <label className="form-label text-capitalize">Social Media Login Enable</label>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-check form-switch form-switch-md mt-0" dir="ltr">
                                                        <input name="social_media_login_enable" onChange={handleInputChange} defaultChecked={parseInt(form_inputs.social_media_login_enable)} type="checkbox" className="form-check-input" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3 mt-4">
                                                <div className="col-8">
                                                    <label className="form-label text-capitalize">Social Media Register Enable</label>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-check form-switch form-switch-md mt-0" dir="ltr">
                                                        <input name="social_media_register_enable" onChange={handleInputChange} defaultChecked={parseInt(form_inputs.social_media_register_enable)} type="checkbox" className="form-check-input" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3 mt-4">
                                                <div className="col-8">
                                                    <label className="form-label text-capitalize">Google Recapacha Enable</label>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-check form-switch form-switch-md mt-0" dir="ltr">
                                                        <input name="enable_google_recapacha" onChange={handleInputChange} defaultChecked={parseInt(form_inputs.enable_google_recapacha)} type="checkbox" className="form-check-input" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3 mt-4">
                                                <div className="col-8">
                                                    <label className="form-label text-capitalize">Chat Enable</label>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-check form-switch form-switch-md mt-0" dir="ltr">
                                                        <input name="chat_enable" onChange={handleInputChange} defaultChecked={parseInt(form_inputs.chat_enable)} type="checkbox" className="form-check-input" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3 mt-4">
                                                <div className="col-8">
                                                    <label className="form-label text-capitalize">User Chat Enable</label>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-check form-switch form-switch-md mt-0" dir="ltr">
                                                        <input name="user_chat_enable" onChange={handleInputChange} defaultChecked={parseInt(form_inputs.user_chat_enable)} type="checkbox" className="form-check-input" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3 mt-4">
                                                <div className="col-8">
                                                    <label className="form-label text-capitalize">Home Page Show Dummy Data</label>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-check form-switch form-switch-md mt-0" dir="ltr">
                                                        <input name="home_page_show_dummy_data" onChange={handleInputChange} defaultChecked={parseInt(form_inputs.home_page_show_dummy_data)} type="checkbox" className="form-check-input" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3 mt-4">
                                                <div className="col-8">
                                                    <label className="form-label text-capitalize">VPN Check Enable</label>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-check form-switch form-switch-md mt-0" dir="ltr">
                                                        <input name="enable_vpn_check" onChange={handleInputChange} defaultChecked={parseInt(form_inputs.enable_vpn_check)} type="checkbox" className="form-check-input" />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg="12" >
                                            <div className="text-center mt-4">
                                                <Button disabled={ButtonDisabled} type="submit" color="primary">{ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Update</Button>
                                                <Link to="/setting" className="btn btn-danger ms-3"> Cancel</Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </form>
                        </Col>
                    </Row>
                </Container>
            </div>

        </React.Fragment>
    )
}

export default withRouter(AdminForm)

import MetaTags from "react-meta-tags"
import React, { useState, useEffect, useRef } from "react"
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap"
import { useHistory, withRouter, Link } from "react-router-dom"

import SimpleReactValidator from 'simple-react-validator';
import { post } from '../../helpers/api_helper';
import { POST_GET_LEADERBOARD_PRIZE, POST_UPDATE_LEADERBOARD_PRIZE } from '../../helpers/url_helper';
import { toast } from 'react-toastify';


//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"


const UserProfile = props => {
    let history = useHistory();

    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [title, setTitle] = useState('Leaderboard Prize');
    const [form_inputs, setInputs] = React.useState([]);
    const [totalPrize, setTotalPrize] = React.useState();
    const [, forceUpdate] = useState();

    useEffect(() => {
        async function get_detaile() {
            if (props.match.params.type) {
                if (props.match.params.type === 'daily') {
                    setTitle('Leaderboard Prize Daily')
                } else if (props.match.params.type === 'weekly') {
                    setTitle('Leaderboard Prize Weekly')
                } else if (props.match.params.type === 'monthly') {
                    setTitle('Leaderboard Prize Monthly')
                } else {
                    history.push("/");
                }
                get_prize();

            }
        }
        get_detaile();
    }, [props.match.params.id])

    const get_prize = async () => {
        var res_data = await post(POST_GET_LEADERBOARD_PRIZE, { 'type': props.match.params.type }, {});
        if (res_data.status) {
            setInputs(res_data.prize_list);
            setTotalPrize(res_data.total_prize);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_UPDATE_LEADERBOARD_PRIZE, { 'type': props.match.params.type, prize: form_inputs, total_prize: totalPrize }, {});
            if (res_data.status) {
                toast(res_data.message);
                get_prize();
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    const handleInputChangeMethod = (e, index) => {
        var name = e.target.name;
        var value = e.target.value;
        const list = [...form_inputs];
        list[index][name] = value;
        setInputs(list);
    };

    const addRow = () => {
        setInputs([...form_inputs, {
            "leaderboard_type": props.match.params.type,
            "start_range": "",
            "end_range": "",
            "prize": "",
        }]);
    }

    let RemoveRow = (i) => {
        const values = [...form_inputs];
        values.splice(i, 1);
        setInputs(values)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{title} | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem={title} />

                    <Row>
                        <Col lg="8">
                            <Card>
                                <CardBody>
                                    <form className="av-invalid" onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <div className="form-group mb-3">
                                                    <label className="form-label text-capitalize">Total Prize In USD</label>
                                                    <input name="totalPrize" value={totalPrize} onChange={(e) => {setTotalPrize(e.target.value); }} className="form-control" placeholder={`Enter Total Prze`} type="text" />
                                                </div>
                                            </div>
                                        </div>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <td>Start Range</td>
                                                    <td>End Range</td>
                                                    <td>Prize</td>
                                                    <td><button type="button" onClick={addRow} className="btn btn-primary btn-sm w-xs">+Add</button></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {form_inputs && Object.entries(form_inputs).map(([key, value]) => (
                                                    <tr>
                                                        <td>
                                                            <input name="start_range" maxLength="16" value={value.start_range} onChange={e => handleInputChangeMethod(e, key)} className="form-control" placeholder="Enter Start Range" type="text" />
                                                            {simpleValidator.current.message('start range', value.start_range, 'required|numeric')}
                                                        </td>
                                                        <td>
                                                            <input name="end_range" maxLength="16" value={value.end_range} onChange={e => handleInputChangeMethod(e, key)} className="form-control" placeholder="Enter End Range" type="text" />
                                                            {simpleValidator.current.message('end range', value.end_range, 'required|numeric')}
                                                        </td>
                                                        <td>
                                                            <input name="prize" maxLength="16" value={value.prize} onChange={e => handleInputChangeMethod(e, key)} className="form-control" placeholder="Enter Prize" type="text" />
                                                            {simpleValidator.current.message('prize', value.prize, 'required|numeric')}
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => RemoveRow(key)}>Remove</button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <div className="text-center mt-4">
                                            <Button disabled={ButtonDisabled} type="submit" color="primary">{ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Save</Button>
                                            <Link to="/setting/leaderboard-setting" className="btn btn-danger ms-3"> Cancel</Link>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(UserProfile)

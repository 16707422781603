import MetaTags from "react-meta-tags"
import React, { useState, useEffect,useRef } from "react"
import { Container, Row, Col, Card, CardBody, Button, } from "reactstrap"

import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import { getToken } from './../../helpers/helper';
import { get,post } from '../../helpers/api_helper';
import { GET_PROFILE_DETAILS ,POST_UPDATE_PROFILE} from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

const UserProfile = props => {

    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setUserInfo] = useState({ username: '', email: ''});
    const [, forceUpdate] = useState();

    useEffect(() => {
        async function get_user_detaile () {
            if (getToken()) {
                var res_data = await get(GET_PROFILE_DETAILS, {});
                if (res_data.status) {
                    setUserInfo({
                        email: res_data.data.email,
                        username: res_data.data.username,
                    });
                } else {
                    toast.error(res_data.message);
                }
            }
        }
        get_user_detaile();
    }, [])

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_UPDATE_PROFILE, form_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if(event.target.name === 'username'){
            var value = event.target.value.replace(' ','');
            setUserInfo(inputs => ({ ...form_inputs, [event.target.name]: value }));
        }else{
            setUserInfo(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Profile | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem="Profile" />

                    <Row>
                        <Col lg="6">
                            <Card>
                                <CardBody>
                                    <form className="av-invalid" onSubmit={handleSubmit}>
                                        <div className="form-group mb-3">
                                            <label className="form-label">User Name</label>
                                            <input name="username" value={form_inputs.username} onChange={handleInputChange} className="form-control" placeholder="Enter User Name" type="text" />
                                            {simpleValidator.current.message('user name', form_inputs.username, 'required')}
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label">Email</label>
                                            <input name="email" value={form_inputs.email} onChange={handleInputChange} className="form-control" placeholder="Enter Email Address" type="text" />
                                            {simpleValidator.current.message('email', form_inputs.email, 'required|email')}
                                        </div>
                                        
                                        <div className="text-center mt-4">
                                            <Button disabled={ButtonDisabled} type="submit" color="primary">{ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Update Profile</Button>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(UserProfile)

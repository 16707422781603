import PropTypes from "prop-types";
import React, { useEffect, useRef, useCallback } from "react";

//Import Icons
import FeatherIcon from "feather-icons-react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

//i18n
import { withTranslation } from "react-i18next";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

import {IsModemAccess} from '../../helpers/helper';

const SidebarContent = (props) => {
    const ref = useRef();

    const activateParentDropdown = useCallback(item => {
        item.classList.add("active");
        const parent = item.parentElement;
        const parent2El = parent.childNodes[1];
        if (parent2El && parent2El.id !== "side-menu") {
            parent2El.classList.add("mm-show");
        }

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show"); // ul tag

                const parent3 = parent2.parentElement; // li tag

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement; // ul
                    if (parent4) {
                        parent4.classList.add("mm-show"); // ul
                        const parent5 = parent4.parentElement;
                        if (parent5) {
                            parent5.classList.add("mm-show"); // li
                            parent5.childNodes[0].classList.add("mm-active"); // a tag
                        }
                    }
                }
            }
            scrollElement(item);
            return false;
        }
        scrollElement(item);
        return false;
    }, []);

    // Use ComponentDidMount and ComponentDidUpdate method symultaniously
    useEffect(() => {
        const pathName = props.location.pathname;

        const initMenu = () => {
            new MetisMenu("#side-menu");
            let matchingMenuItem = null;
            const ul = document.getElementById("side-menu");
            const items = ul.getElementsByTagName("a");
            for (let i = 0; i < items.length; ++i) {
                if (pathName === items[i].pathname) {
                    matchingMenuItem = items[i];
                    break;
                }
            }
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        };
        initMenu();
    }, [props.location.pathname, activateParentDropdown]);

    useEffect(() => {
        ref.current.recalculate();
    });

    function scrollElement(item) {
        if (item) {
            const currentPosition = item.offsetTop;
            if (currentPosition > window.innerHeight) {
                ref.current.getScrollElement().scrollTop = currentPosition - 300;
            }
        }
    }
    return (
        <React.Fragment>
            <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className="menu-title">Menu</li>
                        <li>
                            <Link to="/dashboard" className="">
                                <FeatherIcon icon="home" />
                                <span>Dashboard</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/dashboard-chart" className="">
                                <FeatherIcon icon="home" />
                                <span>Dashboard Chart</span>
                            </Link>
                        </li>

                        {(IsModemAccess('admin','action') || IsModemAccess('user','action') || IsModemAccess('use_role','action') || IsModemAccess('influencers','action')) && <>
                        <li>
                            <Link to="/" className="has-arrow">
                                <FeatherIcon icon="users" />
                                <span>User</span>
                            </Link>
                            <ul className="sub-menu">
                                {IsModemAccess('admin','action') && <li>
                                    <Link exact to="/admin" >Sub Admin List</Link>
                                </li>}
                                {IsModemAccess('user','action') && <li>
                                    <Link exact to="/user">User List</Link>
                                </li>}
                                {IsModemAccess('use_role','action') && <li>
                                    <Link exact to="/user-roles">User Roles</Link>
                                </li>}
                            </ul>
                        </li></>}

                        {(IsModemAccess('transactions','action') || IsModemAccess('withdrawal_requests','action')) && <>
                        <li>
                            <Link to="/#" className="has-arrow ">
                                <i className="fas fa-rupee-sign"></i>
                                <span>Transactions</span>
                            </Link>
                            <ul className="sub-menu">
                                {IsModemAccess('transactions','action') && <li>
                                    <Link to="/transactions">Transactions</Link>
                                </li>}
                                {IsModemAccess('withdrawal_requests','action') && <li>
                                    <Link to="/withdrawal-requests">Withdrawal Requests</Link>
                                </li>}
                            </ul>
                        </li></>}

                        {(IsModemAccess('offerwall','action') || IsModemAccess('custom_offers','action') || IsModemAccess('custom_offers_categories','action')) && <>
                        <li>
                            <Link to="/#" className="has-arrow ">
                                <i className="fas fa-tag"></i>
                                <span>Offerwall</span>
                            </Link>
                            <ul className="sub-menu">
                                {IsModemAccess('offerwall','action') && <li>
                                    <Link to="/offerwall">Offerwall</Link>
                                </li>}
                                {IsModemAccess('custom_offers','action') && <li>
                                    <Link to="/custom-offerwall">Custom Offerwall</Link>
                                </li>}
                                {IsModemAccess('custom_offers_categories','action') && <li>
                                    <Link to="/category">Categories</Link>
                                </li>}
                            </ul>
                        </li></>}

                        {(IsModemAccess('leaderboard','action')) && <>
                        <li>
                            <Link to="/leaderboard" className="">
                                <i className="fa fa-trophy" aria-hidden="true"></i>
                                <span>Leaderboard</span>
                            </Link>
                        </li></>}
                        {(IsModemAccess('blog','action')) && <>
                        <li>
                            <Link to="/blog" className="">
                                <FeatherIcon icon="edit" />
                                <span>Blog</span>
                            </Link>
                        </li></>}
                        {(IsModemAccess('notification','action')) && <>
                        <li>
                            <Link to="/notification" >
                                <i className="fas fa-bell"></i>
                                <span>Notification</span>
                            </Link>
                        </li></>}
                        {(IsModemAccess('chat','action')) && <>
                        <li>
                            <Link to="/chat" >
                                <i className="fab fa-rocketchat"></i>
                                <span>Chat</span>
                            </Link>
                        </li></>}
                        {(IsModemAccess('offerwall_report','action') || IsModemAccess('account_report','action') || IsModemAccess('chargebacks_report','action')) && <>
                        <li>
                            <Link to="/#" className="has-arrow ">
                                <FeatherIcon icon="hard-drive" />
                                <span>Report</span>
                            </Link>
                            <ul className="sub-menu">
                                {IsModemAccess('offerwall_report','action') && <li>
                                    <Link to="/offerwall-report">Offerwall Report</Link>
                                </li>}
                                {IsModemAccess('account_report','action') && <li>
                                    <Link to="/account-report">Account Report</Link>
                                </li>}
                                {IsModemAccess('chargebacks_report','action') && <li>
                                    <Link to="/chargebacks-report">Chargebacks Report</Link>
                                </li>}
                            </ul>
                        </li></>}
                        {(IsModemAccess('api_logs','action') || IsModemAccess('offerwall_logs','action') || IsModemAccess('vpn_logs','action')) && <>
                        <li>
                            <Link to="/#" className="has-arrow ">
                                <FeatherIcon icon="hard-drive" />
                                <span>Logs</span>
                            </Link>
                            <ul className="sub-menu">
                                {IsModemAccess('api_logs','action') && <li>
                                    <Link to="/api-logs">API Logs</Link>
                                </li>}
                                {IsModemAccess('offerwall_logs','action') && <li>
                                    <Link to="/offerwall-logs">Offerwall Logs</Link>
                                </li>}
                                {IsModemAccess('vpn_logs','action') && <li>
                                    <Link to="/vpn-logs">VPN Logs</Link>
                                </li>}
                            </ul>
                        </li></>}

                        {(IsModemAccess('app_setting','action') || IsModemAccess('pro_setting','action') || IsModemAccess('country','action') || IsModemAccess('withdrawal_options','action') || IsModemAccess('banner','action') || IsModemAccess('rewards','action') )&& <>
                        <li>
                            <Link to="/#" className="has-arrow ">
                                <FeatherIcon icon="settings" />
                                <span>Setting</span>
                            </Link>
                            <ul className="sub-menu">
                                {IsModemAccess('withdrawal_options','action') && <li>
                                    <Link to="/withdrawal-options">Withdrawal Options</Link>
                                </li>}
                                {IsModemAccess('banner','action') && <li>
                                    <Link to="/banner">Banner</Link>
                                </li>}
                                {IsModemAccess('country','action') && <li>
                                    <Link to="/country">Country</Link>
                                </li>}
                                {IsModemAccess('rewards','action') && <li>
                                    <Link to="/rewards">Rewards</Link>
                                </li>}
                                {IsModemAccess('app_setting','action') && <li>
                                    <Link to="/setting">App Setting</Link>
                                </li>}
                                {IsModemAccess('pro_setting','action') && <li>
                                    <Link to="/pro-setting">Pro Setting</Link>
                                </li>}
                            </ul>
                        </li></>}
                    </ul>
                </div>
            </SimpleBar>
        </React.Fragment>
    );
};

SidebarContent.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withTranslation()(withRouter(SidebarContent));

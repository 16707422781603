import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { withRouter, useHistory,Link } from "react-router-dom"

import { getToken, IsModemAccess, DateUTCToLocal } from '../../helpers/helper';
import { get } from '../../helpers/api_helper';
import { POST_TRANSACTION_LIST, POST_TRANSACTION_FILTER_OPTION, GET_SELECT_SEARCH_USER, POST_GET_TRANSACTION_DETAIL } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import ReactDOM from 'react-dom';

import $ from 'jquery';

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Moment from 'moment';

import Select from "react-select";
import AsyncSelect from 'react-select/async';

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import Loader from './../../components/Common/Loader';


const UserProfile = props => {
    let history = useHistory();
    const [isLoader, setLoader] = useState(0);

    const [StartDate, setStartDate] = useState('');
    const [EndDate, setEndDate] = useState('');
    const [TransactionsType, setTransactionsType] = useState('');
    const [TransactionsFor, setTransactionsFor] = useState('');
    const [UserId, setUserId] = useState('');
    const [UserType, setUserType] = useState('user');
    const [NetworksId, setNetworksId] = useState('');
    var form_inputs = { start_date: '', end_date: '', transactions_type: '', transactions_for: '', user_id: '',user_type : 'user', networks_id : '' };
    const [Detail, setDetail] = useState();
    const [DefaultSelectUser, setDefaultSelectUser] = useState({});
    const [DefaultSelectTransactions, setDefaultSelectTransactions] = useState({});

    const [TransactionsTypeList, setTransactionsTypeList] = useState();
    const [TransactionsForList, setTransactionsForList] = useState();
    const [UserTypeList, setUserTypeList] = useState();
    const [NetworksList, setNetworksList] = useState();
    const [inputValue, setValue] = useState('');


    useEffect(() => {
        async function get_detaile() {
            var default_user_id = (new URLSearchParams(window.location.search)).get("id");
            var default_email = (new URLSearchParams(window.location.search)).get("email");
            var transactions_type = (new URLSearchParams(window.location.search)).get("transactions_type");
            var res_data = await get(POST_TRANSACTION_FILTER_OPTION);
            if (res_data.status) {
                setTransactionsForList(res_data.transactions_for);
                setTransactionsTypeList(res_data.transactions_type);
                setUserTypeList(res_data.user_type);
                setNetworksList(res_data.networks_list);
                setUserType('user');
                if(default_user_id > 0){
                    form_inputs.user_id = default_user_id;
                    setUserId(default_user_id);
                    setDefaultSelectUser({'value': default_user_id,'label':default_email});
                }
                if(transactions_type){
                    form_inputs.transactions_type = transactions_type;
                    setTransactionsType(transactions_type);
                    setDefaultSelectTransactions({'value': transactions_type,'label': transactions_type});
                }
                setLoader(1);
            } else {
                toast.error(res_data.message);
                history.push("/");
            }
            get_list();
        }
        get_detaile();
    }, [])

    const get_detaile = async (id) => {
        var res_data = await get(POST_GET_TRANSACTION_DETAIL + '?transactions_id=' + id);
        if (res_data.status) {
            setDetail(res_data.detail);
        } else {
            toast.error(res_data.message);
        }
    }

    // get user role list
    const get_list = () => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": POST_TRANSACTION_LIST + '?start_date=' + form_inputs.start_date + '&end_date=' + form_inputs.end_date + '&transactions_type=' + form_inputs.transactions_type + '&transactions_for=' + form_inputs.transactions_for + '&user_id=' + form_inputs.user_id +'&user_type='+UserType+'&networks_id='+form_inputs.networks_id,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [10] }, { "targets": 0, "visible": false }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "id" },
                    { "data": "txn_id" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<> {DateUTCToLocal(data.created_at)}</>, td)
                        }
                    },
                    { "data": "username" },
                    { "data": "email" },
                    { "data": "network" },
                    { "data": "txn_title" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<> <span className="text-capitalize">{data.txn_for}</span></>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<> <span className="text-capitalize">{data.txn_type}</span></>, td)
                        }
                    },
                    { "data": "amount" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {IsModemAccess('transactions', 'view') && <button className="btn btn-soft-info waves-effect waves-light p-1 mr-2" onClick={() => { get_detaile(data.id); }} data-toggle="modal" data-target="#viewModal"  ><i className="fas fa-eye align-middle"></i></button>}
                            </>, td)
                        }
                    },
                ]
            });
        });
    }

    const handleSelectChange = async (name, value) => {
        if (name === 'transactions_type') {
            await setTransactionsType(value.value);
        } else if (name === 'transactions_for') {
            await setTransactionsFor(value.value);
        } else if (name === 'user_id') {
            await setUserId((value && value.value) ? value.value : '');
        }else if(name === 'user_type'){
            await setUserType(value.value);
        }else if(name === 'networks_id'){
            await setNetworksId(value.value);
        }
    }

    const applyFilter = async () => {
        form_inputs = { start_date: StartDate, end_date: EndDate, transactions_type: TransactionsType, transactions_for: TransactionsFor, user_id: UserId, user_type : UserType , networks_id : NetworksId};
        await get_list();
    }

    const fetchData = async () => {
        return await get(GET_SELECT_SEARCH_USER + inputValue).then(result => {
            const res = result.user_list;
            return res;
        });
    }

    // handle input change event
    const handleInputChange = value => {
        setValue(value);
    };

    if (!isLoader) {
        return (<Loader></Loader>);
    }
    return (

        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Transactions | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem="Transactions" />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg="2 mt-3">
                                            <Flatpickr
                                                className="form-control d-block"
                                                placeholder="Select Start Date"
                                                onChange={(selectedDates, dateStr, instance) => {
                                                    var date = Moment(dateStr).valueOf();
                                                    setStartDate(date);
                                                }}
                                                options={{
                                                    enableTime: false,
                                                    altInput: true,
                                                    altFormat: "d-m-Y",
                                                    dateFormat: "Y-m-d",
                                                }}
                                            />
                                        </Col>
                                        <Col lg="2 mt-3">
                                            <Flatpickr
                                                className="form-control d-block"
                                                placeholder="Select End Date"
                                                onChange={(selectedDates, dateStr, instance) => {
                                                    var date = Moment(dateStr).valueOf();
                                                    setEndDate(date);
                                                }}
                                                options={{
                                                    enableTime: false,
                                                    altInput: true,
                                                    altFormat: "d-m-Y",
                                                    dateFormat: "Y-m-d"
                                                }}
                                            />
                                        </Col>
                                        <Col lg="2 mt-3">
                                            <Select
                                                name="transactions_type"
                                                isMulti={false}
                                                options={TransactionsTypeList}
                                                defaultValue={DefaultSelectTransactions}
                                                onChange={handleSelectChange.bind(this, 'transactions_type')}
                                                classNamePrefix="select2-selection"
                                                isLoading={false}
                                            />
                                        </Col>
                                        <Col lg="2 mt-3">
                                            <Select
                                                name="transactions_for"
                                                isMulti={false}
                                                options={TransactionsForList}
                                                onChange={handleSelectChange.bind(this, 'transactions_for')}
                                                classNamePrefix="select2-selection"
                                                isLoading={false}
                                            />
                                        </Col>
                                        <Col lg="2 mt-3">
                                            <Select
                                                name="networks_id"
                                                isMulti={false}
                                                options={NetworksList}
                                                onChange={handleSelectChange.bind(this, 'networks_id')}
                                                classNamePrefix="select2-selection"
                                                isLoading={false}
                                            />
                                        </Col>
                                        <Col lg="3 mt-3">
                                            <AsyncSelect
                                                name="user_id"
                                                isClearable={true}
                                                isMulti={false}
                                                loadOptions={fetchData}
                                                onInputChange={handleInputChange}
                                                onChange={handleSelectChange.bind(this, 'user_id')}
                                                defaultValue={DefaultSelectUser}
                                                classNamePrefix="select2-selection"
                                                isLoading={false}
                                            />
                                        </Col>
                                        <Col lg="2 mt-3">
                                            <Select
                                                name="user_type"
                                                isMulti={false}
                                                defaultValue={{'value':'user','label':'User'}}
                                                options={UserTypeList}
                                                onChange={handleSelectChange.bind(this, 'user_type')}
                                                classNamePrefix="select2-selection"
                                                isLoading={false}
                                            />
                                        </Col>
                                        <Col lg="1 mt-3">
                                            <button onClick={applyFilter.bind(this)} className="btn btn-primary w-xs">Apply</button>
                                            {/* <button className="btn btn-danger w-xs ml-3">Clear</button> */}
                                        </Col>
                                    </Row>
                                    <div className="mt-4 mb-5">
                                        {IsModemAccess('transactions', 'view') && <table id="dataTableExample" className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <td>Id</td>
                                                    <td>Transaction Id</td>
                                                    <td>Date</td>
                                                    <td>User Name</td>
                                                    <td>Email / Mobile</td>
                                                    <td>Network</td>
                                                    <td>Title</td>
                                                    <td>Transaction For</td>
                                                    <td>Type</td>
                                                    <td>Coins</td>
                                                    <td>Action</td>
                                                </tr>
                                            </thead>
                                            <tbody>

                                            </tbody>
                                        </table>}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <div className="modal fade" id="viewModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Transactions Detail</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ "font-size": "20px", "top": "15px", "right": "10px" }}>
                                    <b>X</b>
                                </button>
                            </div>
                            <div className="modal-body">
                                {Detail && <Row>
                                    <Col lg="4 mb-3">
                                        <h5 className="font-size-15 mb-1">Transaction Id :</h5>
                                        <span>{Detail.txn_id}</span>
                                    </Col>
                                    <Col lg="4 mb-3">
                                        <h5 className="font-size-15 mb-1">Transaction Date :</h5>
                                        <span>{DateUTCToLocal(Detail.created_at)}</span>
                                    </Col>
                                    <Col lg="4 mb-3">
                                        <h5 className="font-size-15 mb-1">User Name :</h5>
                                        <span><Link onClick={() => { window.open('/user-detail/' + Detail.user_id, "_blank").focus(); }} to="#">{Detail.username}</Link></span>
                                    </Col>
                                    {Detail.offer_log_id > 0 && <Col lg="4 mb-3">
                                        <h5 className="font-size-15 mb-1">Offer Log Id :</h5>
                                        <span><Link onClick={() => { window.open('/offerwall-logs-detail/' + Detail.offer_log_id, "_blank").focus(); }} to="#">{Detail.offer_log_id}</Link></span>
                                    </Col>}
                                    {Detail.withdrawal_req_id > 0 && <Col lg="4 mb-3">
                                        <h5 className="font-size-15 mb-1">Withdrawal Request Id :</h5>
                                        <span><Link onClick={() => { window.open('/withdrawal-requests-detail/' + Detail.withdrawal_req_id, "_blank").focus(); }} to="#">{Detail.withdrawal_req_id}</Link></span>
                                    </Col>}
                                    <Col lg="4 mb-3">
                                        <h5 className="font-size-15 mb-1">Email Address :</h5>
                                        <span>{Detail.email}</span>
                                    </Col>
                                    <Col lg="4 mb-3">
                                        <h5 className="font-size-15 mb-1">Title :</h5>
                                        <span>{Detail.txn_title}</span>
                                    </Col>
                                    <Col lg="4 mb-3">
                                        <h5 className="font-size-15 mb-1">Transaction For :</h5>
                                        <span className="text-capitalize">{Detail.txn_for}</span>
                                    </Col>
                                    <Col lg="4 mb-3">
                                        <h5 className="font-size-15 mb-1">Transaction Type :</h5>
                                        <span className="text-capitalize">{Detail.txn_type}</span>
                                    </Col>
                                    <Col lg="4 mb-3">
                                        <h5 className="font-size-15 mb-1">Amount :</h5>
                                        <span>{Detail.amount}</span>
                                    </Col>
                                    <Col lg="4 mb-3">
                                        <h5 className="font-size-15 mb-1">Status :</h5>
                                        {Detail.status === 0 && <span className="text-danger">Pending</span>}
                                        {Detail.status === 1 && <span className="text-success">Completed</span>}
                                        {Detail.status === 2 && <span className="text-danger">Cancelled</span>}
                                        {Detail.status === 3 && <span className="text-danger">Deleted</span>}
                                    </Col>
                                </Row>}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}

export default withRouter(UserProfile)

import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { withRouter, useHistory } from "react-router-dom"

import { getToken, IsModemAccess, DateUTCToLocal } from '../../helpers/helper';
import { get } from '../../helpers/api_helper';
import { POST_WITHDRAWAL_REQUEST_LIST, POST_WITHDRAWAL_REQUEST_FILTER_OPTION,GET_SELECT_SEARCH_USER } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import ReactDOM from 'react-dom';

import $ from 'jquery';

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Moment from 'moment';

import Select from "react-select";
import AsyncSelect from 'react-select/async';
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"


const UserProfile = props => {
    let history = useHistory();

    const [StartDate, setStartDate] = useState('');
    const [EndDate, setEndDate] = useState('');
    const [Status, setStatus] = useState('');
    const [UserId, setUserId] = useState('');
    const [WithdrawalType, setWithdrawalType] = useState('');
    const [WithdrawalIn, setWithdrawalIn] = useState('');
    var form_inputs = {
        start_date: '',
        end_date: '',
        status: '',
        user_id: '',
        withdrawal_type: '',
        withdrawal_in: '',
    };

    const [StatusList, setStatusList] = useState();
    const [inputValue, setValue] = useState('');
    const [WithdrawalTypeList] = useState([
        { 'label': 'All', 'value': '' },
        { 'label': 'cash', 'value': 'Cash' },
        { 'label': 'crypto', 'value': 'Crypto' }
    ]);
    
    const [WithdrawalInOption, SetWithdrawalInOption] = useState();


    useEffect(() => {
        async function get_detaile() {
            var res_data = await get(POST_WITHDRAWAL_REQUEST_FILTER_OPTION);
            if (res_data.status) {
                await setStatusList(res_data.status_list);
                var withdrawal_list = [{ 'value': '', 'label': 'All' }];
                withdrawal_list = withdrawal_list.concat(res_data.WithdrawalInOption);
                await SetWithdrawalInOption(withdrawal_list);
            } else {
                toast.error(res_data.message);
                history.push("/");
            }
            get_list();
        }
        get_detaile();
    }, [])

    // get user role list
    const get_list = () => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": POST_WITHDRAWAL_REQUEST_LIST + '?start_date=' + form_inputs.start_date + '&end_date=' + form_inputs.end_date + '&status=' + form_inputs.status + '&user_id=' + form_inputs.user_id + '&withdrawal_type=' + form_inputs.withdrawal_type + '&withdrawal_in=' + WithdrawalIn,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [10,11] }, { "targets": 0, "visible": false }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "id" },
                    { "data": "transaction_id" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<> {DateUTCToLocal(data.created_at)}</>, td)
                        }
                    },
                    { "data": "username" },
                    { "data": "email" },
                    { "data": "name" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<span className="text-capitalize">{data.withdraw_type}</span>, td)
                        }
                    },
                    { "data": "category" },
                    { "data": "coins" },
                    { "data": "amount" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {data.status === 0 && <button className="border-0 badge badge-secondary fs-6" >Pending</button>}
                                {data.status === 1 && <button className="border-0 badge badge-warning fs-6" >Prosseing</button>}
                                {data.status === 2 && <button className="border-0 badge badge-success fs-6" >Success</button>}
                                {data.status === 3 && <button className="border-0 badge badge-danger fs-6" >Cancelled</button>}
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {IsModemAccess('withdrawal_requests', 'view') && <button className="btn btn-soft-info waves-effect waves-light p-1 mr-2" onClick={() => { window.open('/withdrawal-requests-detail/' + data.id, "_blank").focus(); history.push(); }} ><i className="fas fa-eye align-middle"></i></button>}
                            </>, td)
                        }
                    },
                ]
            });
        });
    }

    const handleSelectChange = async (name, value) => {
        if (name === 'status') {
            await setStatus(value.value);
        } else if (name === 'user_id') {
            var values = (value && value.value) ? value.value : '';
            await setUserId(values);
        } else if (name === 'withdrawal_type') {
            await setWithdrawalType(value.value);
        }  else if (name === 'withdrawal_in') {
            await setWithdrawalIn(value.value);
        }
    }

    const applyFilter = async () => {
        form_inputs = {
            start_date: StartDate,
            end_date: EndDate,
            status: Status,
            user_id: UserId,
            withdrawal_type: WithdrawalType,
            withdrawal_in: WithdrawalIn
        };
        await get_list();
    }

    const fetchData = async () => {
        return await get(GET_SELECT_SEARCH_USER + inputValue).then(result => {
            const res = result.user_list;
            return res;
        });
    }
    // handle input change event
    const handleInputChange = value => {
        setValue(value);
    };
    
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Withdrawal Requests | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem="Withdrawal Requests" />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg="1 mt-3"></Col>
                                        <Col lg="2 mt-3">
                                            <Flatpickr
                                                className="form-control d-block"
                                                placeholder="Select Start Date"
                                                onChange={(selectedDates, dateStr, instance) => {
                                                    var date = Moment(dateStr).valueOf();
                                                    setStartDate(date);
                                                }}
                                                options={{
                                                    enableTime: false,
                                                    altInput: true,
                                                    altFormat: "d-m-Y",
                                                    dateFormat: "Y-m-d",
                                                }}
                                            />
                                        </Col>
                                        <Col lg="2 mt-3">
                                            <Flatpickr
                                                className="form-control d-block"
                                                placeholder="Select End Date"
                                                onChange={(selectedDates, dateStr, instance) => {
                                                    var date = Moment(dateStr).valueOf();
                                                    setEndDate(date);
                                                }}
                                                options={{
                                                    enableTime: false,
                                                    altInput: true,
                                                    altFormat: "d-m-Y",
                                                    dateFormat: "Y-m-d"
                                                }}
                                            />
                                        </Col>
                                        <Col lg="2 mt-3">
                                            <Select
                                                name="withdrawal_type"
                                                placeholder="Select Withdrawal Type"
                                                isMulti={false}
                                                options={WithdrawalTypeList}
                                                onChange={handleSelectChange.bind(this, 'withdrawal_type')}
                                                classNamePrefix="select2-selection"
                                                isLoading={false}
                                            />
                                        </Col>
                                        <Col lg="2 mt-3">
                                            <Select
                                                name="withdrawal_in"
                                                placeholder="Select Withdrawal In"
                                                isMulti={false}
                                                options={WithdrawalInOption}
                                                onChange={handleSelectChange.bind(this, 'withdrawal_in')}
                                                classNamePrefix="select2-selection"
                                                isLoading={false}
                                            />
                                        </Col>
                                        <Col lg="2 mt-3">
                                            <Select
                                                name="status"
                                                isMulti={false}
                                                placeholder="Select Status"
                                                options={StatusList}
                                                onChange={handleSelectChange.bind(this, 'status')}
                                                classNamePrefix="select2-selection"
                                                isLoading={false}
                                            />
                                        </Col>
                                        <Col lg="3 mt-3"></Col>
                                        <Col lg="4 mt-3">
                                            <AsyncSelect
                                                name="user_id"
                                                isClearable={true}
                                                isMulti={false}
                                                loadOptions={fetchData}
                                                onInputChange={handleInputChange}
                                                onChange={handleSelectChange.bind(this, 'user_id')}
                                                classNamePrefix="select2-selection"
                                                isLoading={false}
                                            />
                                        </Col>
                                        <Col lg="1 mt-3">
                                            <button onClick={applyFilter.bind(this)} className="btn btn-primary w-xs">Apply</button>
                                            {/* <button className="btn btn-danger w-xs ml-3">Clear</button> */}
                                        </Col>
                                    </Row>
                                    <div className="mt-4 mb-5">
                                        {IsModemAccess('withdrawal_requests', 'view') && <table id="dataTableExample" className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <td>Id</td>
                                                    <td>Txn No.</td>
                                                    <td>Date</td>
                                                    <td>User Name</td>
                                                    <td>Email</td>
                                                    <td>From</td>
                                                    <td>Type</td>
                                                    <td>Category</td>
                                                    <td>Coins</td>
                                                    <td>Amount</td>
                                                    <td>Status</td>
                                                    <td>Action</td>
                                                </tr>
                                            </thead>
                                            <tbody>

                                            </tbody>
                                        </table>}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(UserProfile)

import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { withRouter ,useHistory} from "react-router-dom"

import { post } from '../../helpers/api_helper';
import { POST_ACCOUNT_REPORT, POST_ACCOUNT_PDF_REPORT } from '../../helpers/url_helper';
import { AmountFormat } from '../../helpers/helper';
import { toast } from 'react-toastify';

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Moment from 'moment';

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"


const UserProfile = props => {
    let history = useHistory();

    var start = Moment().format('YYYY-MM-DD').toLocaleString();
    start = new Moment(start, "YYYY-MM-DD").utc();
    
    var end = Moment().format('YYYY-MM-DD').toLocaleString();
    end = new Moment(end, "YYYY-MM-DD").utc(); 

    const [StartDate, setStartDate] = useState(start);
    const [EndDate, setEndDate] = useState(end);
    var form_inputs = { start_date: start, end_date: end};

    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [AccountReport, SetAccountReport] = useState({});
    const [ShowReport, SetShowReport] = useState(false);

    useEffect(() => {
        async function get_detaile() {
        }
        get_detaile();
    }, [])

    // get user role list
    const get_list = async() => {
        SetButtonDisabled(true);
        var res_data = await post(POST_ACCOUNT_REPORT,{start_date : Moment(StartDate).valueOf(),end_date : Moment(EndDate).valueOf()});
        if (res_data.status) {
            SetShowReport(true);
            SetAccountReport(res_data.data);
            SetButtonDisabled(false);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

    const exportToPDF = async (type) => {
        SetButtonDisabled(true);
        AccountReport.StartDate =  Moment(StartDate).valueOf();
        AccountReport.EndDate = Moment(EndDate).valueOf();
        AccountReport.type = type;
        var res_data = await post(POST_ACCOUNT_PDF_REPORT,AccountReport);
        if (res_data.status) {
            SetButtonDisabled(false);
            window.open(process.env.REACT_APP_API_URL.replace('api/v1/','')+res_data.data.url, "_blank");
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

    const applyFilter = async () => {
        form_inputs = { start_date: StartDate, end_date: EndDate };
        await get_list();
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Account Report | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem="Account Report" />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg="2 mt-3">
                                        </Col>
                                        <Col lg="2 mt-3">
                                            <Flatpickr
                                                className="form-control d-block"
                                                placeholder="Select Start Date"
                                                onChange={(selectedDates, dateStr, instance) => {
                                                    var date = Moment(dateStr).valueOf();
                                                    setStartDate(date);
                                                }}
                                                options={{
                                                    enableTime: false,
                                                    altInput: true,
                                                    altFormat: "d-m-Y",
                                                    dateFormat: "Y-m-d",
                                                    defaultDate: [Moment().format('YYYY-MM-01')]
                                                }}
                                            />
                                        </Col>
                                        <Col lg="2 mt-3">
                                            <Flatpickr
                                                className="form-control d-block"
                                                placeholder="Select End Date"
                                                onChange={(selectedDates, dateStr, instance) => {
                                                    var date = Moment(dateStr).valueOf();
                                                    setEndDate(date);
                                                }}
                                                options={{
                                                    enableTime: false,
                                                    altInput: true,
                                                    altFormat: "d-m-Y",
                                                    dateFormat: "Y-m-d",
                                                    defaultDate: [Moment().format('YYYY-MM-DD')]
                                                }}
                                            />
                                        </Col>
                                        <Col lg="4 mt-3"> {ShowReport}
                                            <button onClick={applyFilter.bind(this)} className="btn btn-primary w-xs mr-2"> {ButtonDisabled && <i className="fas fa-spinner mr-3 fa-spin"></i>} Apply</button>
                                            {ShowReport && <>
                                                <button type="button" disabled={ButtonDisabled} className="btn btn-info w-xs me-2" onClick={(e) => exportToPDF('account')}> {ButtonDisabled && <i className="fas fa-spinner mr-3 fa-spin"></i>} Export Account</button>
                                                <button type="button" disabled={ButtonDisabled} className="btn btn-info w-xs me-2" onClick={(e) => exportToPDF('all')}> {ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Export All</button>
                                            </>}
                                        </Col>
                                       
                                        <Col lg="2 mt-3">
                                           
                                        </Col>
                                    </Row>
                                    {ShowReport && <div className="mt-4 mb-5">
                                        <h5>Account report</h5>
                                        <table className="table table-bordered">
                                            <tbody>
                                                 <tr>
                                                    <th colSpan={5}><b>Income</b></th>
                                                </tr>
                                                <tr>
                                                    <td><b>Info</b></td>
                                                    <td className="text-center"><b>Player Coins</b></td>
                                                    <td className="text-center"><b>Player Amount ($)</b></td>
                                                    <td className="text-center"><b>Pro Coins</b></td>
                                                    <td className="text-center"><b>Pro Amount ($)</b></td>
                                                </tr>
                                                <tr>
                                                    <td>Offerwall</td>
                                                    <td className="text-center">{AccountReport.total_player_coins}</td>
                                                    <td className="text-center">$ {AmountFormat(AccountReport.total_player_usd_amount)}</td>
                                                    <td className="text-center">{AccountReport.total_pro_coins}</td>
                                                    <td className="text-center">$ {AmountFormat(AccountReport.total_pro_usd_amount)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Withdrawal Fees</td>
                                                    <td className="text-center">{AccountReport.withdrawal_fees_coin}</td>
                                                    <td className="text-center">$ {AmountFormat(AccountReport.withdrawal_fees_coin / AccountReport.withdrawal_exchange_rate)}</td>
                                                    <td className="text-center">-</td>
                                                    <td className="text-center">-</td>
                                                </tr>
                                                <tr>
                                                    <th colSpan={5}><b>Expenses</b></th>
                                                </tr>
                                                <tr>
                                                    <td><b>Info</b></td>
                                                    <td className="text-center"><b>Player Coins</b></td>
                                                    <td className="text-center"><b>Player Amount ($)</b></td>
                                                    <td className="text-center"><b>Pro Coins</b></td>
                                                    <td className="text-center"><b>Pro Amount ($)</b></td>
                                                </tr>
                                                <tr>
                                                    <td>Signup Bonus</td>
                                                    <td className="text-center">{AccountReport?.bonus_transactions?.player_total_signup_bonus}</td>
                                                    <td className="text-center">$ {AmountFormat(AccountReport?.bonus_transactions?.player_total_signup_bonus / AccountReport.withdrawal_exchange_rate)}</td>
                                                    <td className="text-center">{AccountReport?.bonus_transactions?.pro_total_signup_bonus}</td>
                                                    <td className="text-center">$ {AmountFormat(AccountReport?.bonus_transactions?.pro_total_signup_bonus / AccountReport.withdrawal_exchange_rate)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Referral Bonus</td>
                                                    <td className="text-center">{AccountReport?.bonus_transactions?.player_total_referral_bonus}</td>
                                                    <td className="text-center">$ {AmountFormat(AccountReport?.bonus_transactions?.player_total_referral_bonus / AccountReport.withdrawal_exchange_rate)}</td>
                                                    <td className="text-center">{AccountReport?.bonus_transactions?.pro_total_referral_bonus}</td>
                                                    <td className="text-center">$ {AmountFormat(AccountReport?.bonus_transactions?.pro_total_referral_bonus / AccountReport.withdrawal_exchange_rate)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Day Rewards</td>
                                                    <td className="text-center">{AccountReport?.bonus_transactions?.total_rewards_claim}</td>
                                                    <td className="text-center">$ {AmountFormat(AccountReport?.bonus_transactions?.total_rewards_claim / AccountReport.withdrawal_exchange_rate)}</td>
                                                    <td className="text-center">-</td>
                                                    <td className="text-center">-</td>
                                                </tr>
                                                <tr>
                                                    <td>Download APK Bonus</td>
                                                    <td className="text-center">{AccountReport?.bonus_transactions?.total_download_apk_bonus}</td>
                                                    <td className="text-center">$ {AmountFormat(AccountReport?.bonus_transactions?.total_download_apk_bonus / AccountReport.withdrawal_exchange_rate)}</td>
                                                    <td className="text-center">-</td>
                                                    <td className="text-center">-</td>
                                                </tr>
                                                <tr>
                                                    <td>Twitter Follow Bonus</td>
                                                    <td className="text-center">{AccountReport?.bonus_transactions?.total_twitter_bonus}</td>
                                                    <td className="text-center">$ {AmountFormat(AccountReport?.bonus_transactions?.total_twitter_bonus / AccountReport.withdrawal_exchange_rate)}</td>
                                                    <td className="text-center">-</td>
                                                    <td className="text-center">-</td>
                                                </tr>
                                                <tr>
                                                    <td>Leaderboard Bonus</td>
                                                    <td className="text-center">{AccountReport?.leaderboard_coins}</td>
                                                    <td className="text-center">$ {AmountFormat(AccountReport?.leaderboard_coins / AccountReport.withdrawal_exchange_rate)}</td>
                                                    <td className="text-center">-</td>
                                                    <td className="text-center">-</td>
                                                </tr>
                                                <tr>
                                                    <th colSpan={5}><b>Commission</b></th>
                                                </tr>
                                                <tr>
                                                    <td><b>Info</b></td>
                                                    <td className="text-center"><b>Player Coins</b></td>
                                                    <td className="text-center"><b>Player Amount ($)</b></td>
                                                    <td className="text-center"><b>Pro Coins</b></td>
                                                    <td className="text-center"><b>Pro Amount ($)</b></td>
                                                </tr>
                                                <tr>
                                                    <td>Offerwall Referral Commission</td>
                                                    <td className="text-center">{AccountReport?.commission_transactions?.player_total_commission}</td>
                                                    <td className="text-center">$ {AmountFormat(AccountReport?.commission_transactions?.player_total_commission / AccountReport.withdrawal_exchange_rate)}</td>
                                                    <td className="text-center">{AccountReport?.commission_transactions?.pro_total_commission}</td>
                                                    <td className="text-center">$ {AmountFormat(AccountReport?.commission_transactions?.pro_total_commission / AccountReport.withdrawal_exchange_rate)}</td>
                                                </tr>
                                                <tr>
                                                    <th colSpan={5}><b>GST</b></th>
                                                </tr>
                                                <tr>
                                                    <td><b>Info</b></td>
                                                    <td className="text-center"><b>Player Coins</b></td>
                                                    <td className="text-center"><b>Player Amount ($)</b></td>
                                                    <td className="text-center"><b>Pro Coins</b></td>
                                                    <td className="text-center"><b>Pro Amount ($)</b></td>
                                                </tr>
                                                <tr>
                                                    <td>Offerwall GST - {AccountReport?.gst_percentage}%</td>
                                                    <td className="text-center">-</td>
                                                    <td className="text-center">$ {AmountFormat(AccountReport?.total_player_gst)}</td>
                                                    <td className="text-center">-</td>
                                                    <td className="text-center">$ {AmountFormat(AccountReport?.total_pro_gst)}</td>
                                                </tr>
                                                <tr>
                                                    <td>All Over GST - {AccountReport?.gst_percentage}%</td>
                                                    <td className="text-center">-</td>
                                                    <td className="text-center">$ {AmountFormat(AccountReport?.all_over_gst)}</td>
                                                    <td className="text-center">-</td>
                                                    <td className="text-center">-</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <table className="table table-bordered mt-5">
                                            <tbody>
                                                <tr>
                                                    <th colSpan={5}><b>Offerwall Report</b></th>
                                                </tr>
                                                <tr>
                                                    <td><b>Info</b></td>
                                                    <td className="text-center"><b>Player Income (Coins)</b></td>
                                                    <td className="text-center"><b>Total Credit ($)</b></td>
                                                    <td className="text-center"><b>Total Debit ($)</b></td>
                                                    <td className="text-center"><b>Company Income ($)</b></td>
                                                    <td className="text-center"><b>Pro Income (Coins)</b></td>
                                                    <td className="text-center"><b>Company Income ($)</b></td>
                                                </tr>
                                                {AccountReport.offerwall_transactions && Object.entries(AccountReport.offerwall_transactions).map(([key, value]) => {
                                                return (<tr key={key}>
                                                    <td>{value.network}</td>
                                                    <td className="text-center">{AmountFormat(value.player_total_coins)}</td>
                                                    <td className="text-center text-success">$ {AmountFormat(value.player_total_credit_usd_amount)}</td>
                                                    <td className="text-center text-danger">-$ {AmountFormat(value.player_total_debit_usd_amount)}</td>
                                                    <td className="text-center">$ {AmountFormat(value.player_total_usd_amount)}</td>
                                                    <td className="text-center">{AmountFormat(value.pro_total_coins)}</td>
                                                    <td className="text-center">$ {AmountFormat(value.pro_total_usd_amount)}</td>
                                                </tr>)
                                                 })}
                                                  <tr>
                                                    <td><b>Total</b></td>
                                                    <td className="text-center"><b>{AmountFormat(AccountReport.total_player_coins)}</b></td>
                                                    <td className="text-center text-success"><b>$ {AmountFormat(AccountReport.total_player_credit_usd_amount)}</b></td>
                                                    <td className="text-center text-danger"><b>-$ {AmountFormat(AccountReport.total_player_debit_usd_amount)}</b></td>
                                                    <td className="text-center"><b>$ {AmountFormat(AccountReport.total_player_usd_amount)}</b></td>
                                                    <td className="text-center"><b>{AmountFormat(AccountReport.total_pro_coins)}</b></td>
                                                    <td className="text-center"><b>$ {AmountFormat(AccountReport.total_pro_usd_amount)}</b></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

        </React.Fragment>

    )
}

export default withRouter(UserProfile)

import MetaTags from "react-meta-tags"
import React, { useState,useEffect, useRef } from "react"
import {Container,Row,Col,Card,CardBody,Button} from "reactstrap"
import { useHistory,withRouter,Link } from "react-router-dom"

import SimpleReactValidator from 'simple-react-validator';
import {post,get} from '../../helpers/api_helper';
import {POST_SEND_NOTIFICATION,GET_FORM_OPTION_NOTIFICATION} from '../../helpers/url_helper';
import {IsModemAccess} from '../../helpers/helper';
import { toast } from 'react-toastify';
import Loader from './../../components/Common/Loader';

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import Select from "react-select"

const UserProfile = props => {
    let history = useHistory();

    const [isLoader, setLoader] = useState(0);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({ 
        notification_title: '', 
        notification_message: '',
        notification_criteria:'',
        countries : [],
        user : [],
    });
    const [, forceUpdate] = useState();
    const [SelectCountries, setSelectCountries] = useState();
    const [SelectUser, setSelectUser] = useState();
    const [showUser,setShowUser] = useState(0);
    const [showCountries,setShowCountries] = useState(0);

    useEffect(() => {
        async function get_detaile () {
            if(!IsModemAccess('notification','add')){
                toast('Does not permission this module');
                history.push("/");
            }
            var res_data = await get(GET_FORM_OPTION_NOTIFICATION);
            if (res_data.status) {
                setSelectCountries(res_data.countries_list);
                setSelectUser(res_data.user_list);
            } else {
                toast.error(res_data.message);
                history.push("/");
            }
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data =  await post(POST_SEND_NOTIFICATION,form_inputs);
            if(res_data.status){
                toast(res_data.message);
                history.push("/notification");
            }else{
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if(event.target.name === 'notification_criteria'){
            setShowUser(0);
            setShowCountries(0);

           if(event.target.value === 'countries'){
                setShowCountries(1);
            }else if(event.target.value === 'manual'){
                setShowUser(1);
            }
        }
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }

    const handleSelectChange = (name,value) => {
        setInputs(inputs => ({ ...form_inputs, [name]: value }));
    }
    
    if (!isLoader) {
        return (<Loader></Loader>);
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Send Notification | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem="Send Notification" />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <form className="av-invalid" onSubmit={handleSubmit}>
                                        <Row>
                                            <Col lg="6">
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Title</label>
                                                    <input name="notification_title" value={form_inputs.notification_title} onChange={handleInputChange} className="form-control" placeholder="Enter Title" type="text" />
                                                    {simpleValidator.current.message('title', form_inputs.notification_title, 'required')}
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Message</label>
                                                    <textarea rows="5" name="notification_message" value={form_inputs.notification_message} onChange={handleInputChange} className="form-control" placeholder="Enter message"/>
                                                    {simpleValidator.current.message('message', form_inputs.notification_message, 'required')}
                                                </div>
                                            </Col>
                                            <Col lg="6">
                                                <Row>
                                                    <Col lg="12">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label">Select notification criteria</label>
                                                            <select name="notification_criteria" value={form_inputs.notification_criteria} onChange={handleInputChange}>
                                                                <option value="">Select Criteria</option>
                                                                <option value="all-user">All User</option>
                                                                <option value="countries">Countries</option>
                                                                <option value="manual">Manual</option>
                                                                {/* <option value="coins">Coins</option>
                                                                <option value="fiat">Fiat</option>
                                                                <option value="crypto">Crypto</option>
                                                                <option value="withdrawal-request">Withdrawal Request</option>
                                                                <option value="device">Device</option> */}
                                                            </select>
                                                            {simpleValidator.current.message('type', form_inputs.notification_criteria, 'required')}
                                                        </div>
                                                    </Col>
                                                    {showCountries > 0 && <Col lg="12">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label">Select Countries</label>
                                                            <Select
                                                                name = "countries" 
                                                                isMulti={true}
                                                                options={SelectCountries}
                                                                onChange={handleSelectChange.bind(this,'countries')}
                                                                classNamePrefix="select2-selection"
                                                                isLoading={false}
                                                            />
                                                        </div>
                                                    </Col>}
                                                    {showUser > 0 && <Col lg="12">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label">Select User</label>
                                                            <Select
                                                                name = "user" 
                                                                isMulti={true}
                                                                options={SelectUser}
                                                                onChange={handleSelectChange.bind(this,'user')}
                                                                classNamePrefix="select2-selection"
                                                                isLoading={false}
                                                            />
                                                        </div>
                                                    </Col>}
                                                </Row>
                                            </Col>
                                        </Row>
                                        <div className="text-center mt-4">
                                            <Button disabled={ButtonDisabled} type="submit" color="primary">{ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Save</Button>
                                            <Link to="/notification" className="btn btn-danger ms-3"> Cancel</Link>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(UserProfile)

// auth
export const POST_LOGIN = "/auth/login"
export const POST_VERIFY_OTP = "/auth/verify-otp"
export const POST_FORGOT_PASSWORD = "/auth/forgot-password"
export const POST_RESET_PASSWORD = "/auth/reset-password"

export const GET_DASHBOARD_DATA = "/dashboard/dashboard"
export const GET_DASHBOARD_CHART_DATA = "/dashboard/dashboard-chart"
export const GET_DASHBOARD_USER_DATA = "/dashboard/dashboard-user-data"

//user
export const POST_CHANGE_PASSWORD = "/user/change-password"
export const GET_PROFILE_DETAILS = "/user/get-profile-details"
export const POST_UPDATE_PROFILE = "/user/update-profile"

// user Admin
export const POST_GET_ALL_ADMIN_USER = process.env.REACT_APP_API_URL+"admin/get-all-user"
export const POST_ADD_EDIT_ADMIN_USER = "/admin/add-edit-admin"
export const POST_GET_ADMIN_USER_DETAIL = "/admin/get-user-detail"
export const POST_UPDATE_ADMIN_STATUS = "/admin/update-user-status"
export const DELETE_ADMIN_USER = "/admin/delete-user"
export const GET_ADMIN_FORM_OPTION = "/admin/get-admin-form-option"

// user list
export const POST_GET_ALL_USER = process.env.REACT_APP_API_URL+"user/get-all-user"
export const POST_UPDATE_USER_STATUS = "/user/update-user-status"
export const POST_UPDATE_USER_PROFILE_STATUS = "/user/update-user-profile-status"
export const DELETE_USER = "/user/delete-user"
export const GET_USER_DETAIL = "/user/user-detail"
export const GET_AUDIT_DATA = "/user/audit-data"
export const POST_PROFILE_VERIFIED_EMAIL_SEND = "/user/profile-verified-email-send"
export const GET_SELECT_SEARCH_USER = "/user/search-user?s="
export const POST_GET_USER_LOG = process.env.REACT_APP_API_URL+"user/get-all-log"
export const POST_GET_REFERRAL_USER = process.env.REACT_APP_API_URL+"user/get-referral-user"
export const POST_GET_USER_CHAT = process.env.REACT_APP_API_URL+"user/get-user-chat"
export const POST_GET_USER_CHAT_UPDATE_STATUS = process.env.REACT_APP_API_URL+"user/update-user-chat-status"
export const POST_UPDATE_CHAT_STATUS = "/user/update-chat-status"

export const POST_GET_CHAT_MESSAGE = "/user/get-chat-message"
export const POST_SEND_MESSAGE = "/user/send-message"

// user role
export const POST_GET_ALL_USER_ROLE = process.env.REACT_APP_API_URL+"roles/get-all-user-role"
export const POST_ADD_EDIT_USER_ROLE = "/roles/add-edit-user-role"
export const POST_GET_USER_ROLE_DETAIL = "/roles/get-user-role-detail"
export const POST_UPDATE_STATUS = "/roles/update-role-status"
export const DELETE_USER_ROLE = "/roles/delete-user-role"
export const GET_ROLE_ASSIGNED = "/roles/get-role-assigned"
export const POST_UPDATE_ROLE_ASSIGEND = "/roles/update-role-assigned"


//transactions
export const POST_TRANSACTION_LIST = process.env.REACT_APP_API_URL+"transactions/get-transactions"
export const POST_TRANSACTION_FILTER_OPTION = "/transactions/get-filter-option"
export const POST_GET_TRANSACTION_DETAIL = "/transactions/detail"

// vpn log
export const POST_VPN_LOG = process.env.REACT_APP_API_URL+"vpn-log/get-log"

//withdrawal request
export const POST_WITHDRAWAL_REQUEST_LIST = process.env.REACT_APP_API_URL+"withdrawal-requests/get-withdrawal-requests"
export const POST_WITHDRAWAL_REQUEST_FILTER_OPTION = "/withdrawal-requests/get-filter-option"
export const POST_GET_WITHDRAWAL_REQUEST_DETAIL = "/withdrawal-requests/detail"
export const POST_UPDATE_WITHDRAWAL_REQUEST_STATUS = "/withdrawal-requests/update-status"
export const POST_SEND_GIFT_CARD = "/withdrawal-requests/send-gift-card"
export const POST_SEND_GIFT_CARD_EMAIL = "/withdrawal-requests/send-gift-card-email"
export const POST_MOBILE_RECHARGE = "/withdrawal-requests/mobile-recharge"
export const POST_PAYOUT_CRYPTO = "/withdrawal-requests/payout-crypto"
export const POST_PAYOUT_PAYPAL = "/withdrawal-requests/payout-paypal"

//offerwall
export const POST_OFFERWALL_LIST = process.env.REACT_APP_API_URL+"offerwall/get-offerwall"
export const DELETE_OFFERWALL = "/offerwall/delete"
export const POST_UPDATE_OFFERWALL_STATUS = "/offerwall/update-status"
export const POST_GET_OFFERWALL_DETAIL = "/offerwall/detail"
export const POST_ADD_EDIT_OFFERWALL = "/offerwall/add-edit"

// offerwall category
export const POST_GET_ALL_CATEGORY = process.env.REACT_APP_API_URL+"category/get-all-categories"
export const POST_ADD_EDIT_CATEGORY = "/category/add-edit"
export const POST_GET_CATEGORY_DETAIL = "/category/detail"
export const POST_UPDATE_CATEGORY_STATUS = "/category/update-category-status"
export const DELETE_CATEGORY = "/category/delete-category"



//custom offerwall
export const POST_CUSTOME_OFFERWALL_LIST = process.env.REACT_APP_API_URL+"custom-offers/get-offerwall"
export const DELETE_CUSTOME_OFFERWALL = "/custom-offers/delete"
export const POST_UPDATE_CUSTOME_OFFERWALL_STATUS = "/custom-offers/update-status"
export const POST_GET_CUSTOME_OFFERWALL_DETAIL = "/custom-offers/detail"
export const POST_ADD_EDIT_CUSTOME_OFFERWALL = "/custom-offers/add-edit"
export const POST_GET_CUSTOME_OFFERWALL_FORM_OPTION = "/custom-offers/get-form-option"

//Leaderboard
export const POST_GET_LEADERBOARD = process.env.REACT_APP_API_URL+"leaderboard/get-leaderboard"
export const POST_GET_LEADERBOARD_DETAIL = "/leaderboard/get-detail"
export const POST_GET_LEADERBOARD_USER = process.env.REACT_APP_API_URL+"leaderboard/get-user"

// api log
export const POST_API_LOG_LIST = process.env.REACT_APP_API_URL+"api-log/get-log-list"
export const GET_API_LOG_FILTER_OPTION = "/api-log/get-filter-option"
export const POST_GET_API_LOG_DETAIL_OPTION = "/api-log/detail"

//blog
export const POST_BLOG_LIST = process.env.REACT_APP_API_URL+"blog/get-blog"
export const DELETE_BLOG = "/blog/delete"
export const POST_UPDATE_BLOG_STATUS = "/blog/update-status"
export const POST_GET_BLOG_DETAIL = "/blog/detail"
export const POST_ADD_EDIT_BLOG = "/blog/add-edit"

// offerwall-logs
export const POST_OFFERWALL_LOG_LIST = process.env.REACT_APP_API_URL+"offerwall-logs/get-log-list"
export const POST_GET_OFFERWALL_LOG_DETAIL_OPTION = "/offerwall-logs/detail"

// setting
export const GET_ALL_SETTING = "/setting/get-all-setting"
export const POST_UPDATE_SETTING = "/setting/update-setting"
export const POST_UPDATE_LEADERBOARD_PRIZE = "/setting/update-leaderboard-prize"
export const POST_GET_LEADERBOARD_PRIZE = "/setting/get-leaderboard-prize"

//bot
export const POST_CREATE_BOT = "/bot/create-bot-user"
export const GET_PRO_SETTING = "/bot/pro-setting"
export const POST_UPDATE_PRO_SETTING = "/bot/update-pro-setting"


// withdrawal option
export const POST_GET_ALL_WITHDRAWAL_OPTIONS = process.env.REACT_APP_API_URL+"withdrawal-options/get-all-withdrawal-options"
export const POST_ADD_EDIT_WITHDRAWAL_OPTIONS = "/withdrawal-options/add-edit"
export const POST_GET_WITHDRAWAL_OPTIONS_DETAIL = "/withdrawal-options/detail"
export const POST_UPDATE_STATUS_WITHDRAWAL_OPTIONS = "/withdrawal-options/update-status"
export const DELETE_WITHDRAWAL_OPTIONS = "/withdrawal-options/delete"
export const POST_GET_WITHDRAWAL_FORM_OPTION = "/withdrawal-options/get-form-option"
export const POST_GET_PRODUCT_TREMENDOUS = "/withdrawal-options/get-product-tremendous"
export const POST_GET_PRODUCT_RELOADLY = "/withdrawal-options/get-product-reloadly"

// banner
export const POST_GET_ALL_BANNER = process.env.REACT_APP_API_URL+"banner/get-all-banners"
export const POST_ADD_EDIT_BANNER = "/banner/add-edit"
export const POST_UPDATE_BANNER_STATUS = "/banner/update-status"
export const DELETE_BANNER = "/banner/delete"

// country
export const POST_GET_ALL_COUNTRY = process.env.REACT_APP_API_URL+"country/get-all-countries"
export const POST_ADD_EDIT_COUNTRY = "/country/add-edit"
export const POST_GET_COUNTRY_DETAIL = "/country/detail"
export const POST_UPDATE_STATUS_COUNTRY = "/country/update-status"
export const DELETE_COUNTRY = "/country/delete"

// reward
export const POST_GET_ALL_REWARDS = process.env.REACT_APP_API_URL+"reward/get-all-rewards"
export const POST_ADD_EDIT_REWARDS = "/reward/add-edit"
export const POST_GET_REWARDS_DETAIL = "/reward/detail"
export const POST_UPDATE_STATUS_REWARDS = "/reward/update-status"
export const DELETE_REWARDS = "/reward/delete"


// notification
export const POST_GET_ALL_NOTIFICATION = process.env.REACT_APP_API_URL+"notification/get-all-notifications"
export const POST_SEND_NOTIFICATION = "/notification/send"
export const GET_FORM_OPTION_NOTIFICATION = "/notification/get-form-option"
export const DELETE_NOTIFICATION = "/notification/delete"

// report
export const POST_GET_OFFERWALL_REPORT = process.env.REACT_APP_API_URL+"report/offerwall-report"
export const POST_ACCOUNT_REPORT = process.env.REACT_APP_API_URL+"report/account-report"
export const POST_ACCOUNT_PDF_REPORT = process.env.REACT_APP_API_URL+"pdf/account-report"
export const POST_GET_NETWORKS_LIST = "report/get-networks"
export const POST_GET_CHARGEBACKS_REPORT = process.env.REACT_APP_API_URL+"report/user-chargebacks-report"









//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//Mails
export const GET_INBOX_MAILS = "/inboxmails"
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail"
export const DELETE_INBOX_MAIL = "/delete/inboxmail"

//starred mail
export const GET_STARRED_MAILS = "/starredmails"

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails"

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails"

//Send mail
export const GET_SENT_MAILS = "/sentmails"

//Trash mail
export const GET_TRASH_MAILS = "/trashmails"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//ORDERS
export const GET_ORDERS = "/orders"
export const ADD_NEW_ORDER = "/add/order"
export const UPDATE_ORDER = "/update/order"
export const DELETE_ORDER = "/delete/order"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers"
export const ADD_NEW_CUSTOMER = "/add/customer"
export const UPDATE_CUSTOMER = "/update/customer"
export const DELETE_CUSTOMER = "/delete/customer"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"

//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"
export const ADD_NEW_PROJECT = "/add/project"
export const UPDATE_PROJECT = "/update/project"
export const DELETE_PROJECT = "/delete/project"

//TASKS
export const GET_TASKS = "/tasks"

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"
export const ADD_NEW_USER = "/add/user"
export const UPDATE_USER = "/update/user"
// export const DELETE_USER = "/delete/user"

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data"
export const GET_YEARLY_DATA = "/yearly-data"
export const GET_MONTHLY_DATA = "/monthly-data"

export const TOP_SELLING_DATA = "/top-selling-data"

export const GET_EARNING_DATA = "/earning-charts-data"

export const GET_PRODUCT_COMMENTS = "/comments-product"

export const ON_LIKNE_COMMENT = "/comments-product-action"

export const ON_ADD_REPLY = "/comments-product-add-reply"

export const ON_ADD_COMMENT = "/comments-product-add-comment"

import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Button } from "reactstrap"
import { useHistory, withRouter, Link } from "react-router-dom"

import Loader from '../../components/Common/Loader';
import { post, get } from '../../helpers/api_helper';
import { POST_UPDATE_SETTING, GET_ALL_SETTING } from '../../helpers/url_helper';
import { IsModemAccess } from '../../helpers/helper';
import { toast } from 'react-toastify';
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"


const AdminForm = props => {
    let history = useHistory();

    if (!IsModemAccess('app_setting', 'edit')) {
        toast('Does not permission this module');
        history.push("/");
    }
    const [isLoader, setLoader] = useState(0);
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState();

    useEffect(() => {
        async function get_detaile() {
            await get_app_setting();
            setLoader(1);
        }
        get_detaile();
    }, [])

    const get_app_setting = async () => {
        var res_data = await get(GET_ALL_SETTING + "?type=leaderboard-setting"); 
        if (res_data.status) {
            await setInputs(res_data.data);
        } else {
            toast.error(res_data.message);
        }
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
            SetButtonDisabled(true);
            var res_data = await post(POST_UPDATE_SETTING, form_inputs, {});
            if (res_data.status) {
                await get_app_setting();
                toast(res_data.message);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        console.log(event.target.name);
        if (
            event.target.name === 'daily_leaderboard_enable' ||
            event.target.name === 'weekly_leaderboard_enable' || 
            event.target.name === 'monthly_leaderboard_enable'
        ) {
            console.log((event.target.checked) ? '1' : '0');
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: (event.target.checked) ? '1' : '0' }));
        } else {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    if (!isLoader) {
        return (<Loader></Loader>);
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Leaderboard Setting | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem="Leaderboard Setting" />

                    <Row>
                        <Col lg="7">
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className="border card-body rounded bg-light-box">
                                    <Row>
                                        <Col lg="5" >
                                            <div className="form-group mb-3">
                                                <label className="form-label text-capitalize">Exchange Rate One Coin To Point  
                                                <br/><small>Ex. 1Coin = 2Point</small></label>
                                                <input name="exchange_rate_on_coin_point" value={form_inputs.exchange_rate_on_coin_point} onChange={handleInputChange} className="form-control" placeholder={`Enter Exchange Rate`} type="text" />
                                            </div>
                                        </Col>
                                        <Col lg="7">
                                            <div className="row mb-3 mt-4">
                                                <div className="col-6">
                                                    <label className="form-label text-capitalize">Daily Leaderboard Enable</label>
                                                </div>
                                                <div className="col-2">
                                                    <div className="form-check form-switch form-switch-md mt-0" dir="ltr">
                                                        <input name="daily_leaderboard_enable" onChange={handleInputChange} defaultChecked={parseInt(form_inputs.daily_leaderboard_enable)} type="checkbox" className="form-check-input" />
                                                    </div>
                                                </div>
                                                <div className="col-2">
                                                    <Link to="/setting/leaderboard-setting/daily">SetPrize</Link>
                                                </div>
                                            </div>
                                            <div className="row mb-3 mt-4">
                                                <div className="col-6">
                                                    <label className="form-label text-capitalize">Weekly Leaderboard Enable</label>
                                                </div>
                                                <div className="col-2">
                                                    <div className="form-check form-switch form-switch-md mt-0" dir="ltr">
                                                        <input name="weekly_leaderboard_enable" onChange={handleInputChange} defaultChecked={parseInt(form_inputs.weekly_leaderboard_enable)} type="checkbox" className="form-check-input" />
                                                    </div>
                                                </div>
                                                <div className="col-2">
                                                    <Link to="/setting/leaderboard-setting/weekly">SetPrize</Link>
                                                </div>
                                            </div>
                                            <div className="row mb-3 mt-4">
                                                <div className="col-6">
                                                    <label className="form-label text-capitalize">Monthly Leaderboard Enable</label>
                                                </div>
                                                <div className="col-2">
                                                    <div className="form-check form-switch form-switch-md mt-0" dir="ltr">
                                                        <input name="monthly_leaderboard_enable" onChange={handleInputChange} defaultChecked={parseInt(form_inputs.monthly_leaderboard_enable)} type="checkbox" className="form-check-input" />
                                                    </div>
                                                </div>
                                                <div className="col-2">
                                                    <Link to="/setting/leaderboard-setting/monthly">SetPrize</Link>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg="12" >
                                            <div className="text-center mt-4">
                                                <Button disabled={ButtonDisabled} type="submit" color="primary">{ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Update</Button>
                                                <Link to="/setting" className="btn btn-danger ms-3"> Cancel</Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </form>
                        </Col>
                    </Row>
                </Container>
            </div>

        </React.Fragment>
    )
}

export default withRouter(AdminForm)

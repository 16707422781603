import React,{ useEffect } from 'react';
import MetaTags from 'react-meta-tags';

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Card,CardBody,Col,Container,Row} from "reactstrap";
import CountUp from "react-countup";
import {get} from '../../helpers/api_helper';
import { GET_DASHBOARD_DATA } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import { useHistory} from "react-router-dom"


const Dashboard = () => {
    let history = useHistory();
    const [DashboardData, setDashboardData] = React.useState([]);

    useEffect(() => {
        async function get_detaile () {
            var res_data = await get(GET_DASHBOARD_DATA+"?offset="+new Date().getTimezoneOffset());
            if (res_data.status) {
                setDashboardData(res_data.data);
            } else {
                toast.error(res_data.message);
                history.push("/");
            }
        }
        get_detaile();
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Dashboard | Free Money Coins </title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />
                    <Row>
                        <Col xl={2} md={6}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Total Active User</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            <CountUp start={0} end={DashboardData.total_active_user} duration={1}/>
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={2} md={6}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Total Deactive User</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            <CountUp start={0} end={DashboardData.total_deactive_user} duration={1}/>
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={2} md={6}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Total User</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            <CountUp start={0} end={DashboardData.total_users} duration={1} />
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={6}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Total Referral User</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            <CountUp start={0} end={DashboardData.total_referral_users} duration={1} />
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={6}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Total Direct User</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            <CountUp start={0} end={DashboardData.total_direct_users} duration={1} />
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xl={3} md={6}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Total Offers Balance</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            <CountUp start={0} end={DashboardData.total_user_balances?.total_offers_balance} duration={1} />
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>
                        {/* <Col xl={3} md={6}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Total Video Balance</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            <CountUp start={0} end={DashboardData.total_user_balances?.total_video_balance} duration={1} />
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col> */}
                        <Col xl={3} md={6}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Total Leaderboard Balance</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            <CountUp start={0} end={DashboardData.total_user_balances?.total_leaderboard_balance} duration={1} />
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={6}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Total Bonus Balance</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            <CountUp start={0} end={DashboardData.total_user_balances?.total_bonus_balance} duration={1} />
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={6}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Total Balance</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            <CountUp start={0} end={DashboardData.total_user_balances?.total_balance} duration={1} />
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={2} md={6}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Pending Withdrawal Request</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            <CountUp start={0} end={DashboardData.total_pending_withdrawal_reques} duration={1} />
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={2} md={6}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Pending Withdrawal</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            $ {DashboardData.total_pending_withdrawal_reques_amount}
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={2} md={6}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Total Income</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            $ {DashboardData.total_credit_usd_amount}
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={2} md={6}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Total Chargebacks</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            <span className="counter-value"> - $ {DashboardData?.total_chargebacks_usd}</span>
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={2} md={6}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Total Net Income</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value"> $ {DashboardData.total_income_usd}
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={2} md={6}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Total Withdraw</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            <span className="counter-value"> $ {DashboardData.total_user_balances?.withdraw_balance}</span>
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>
                        

                        {/* <Col xl={3} md={6}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Total Points Balance</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            <CountUp start={0} end={DashboardData.total_user_balances?.total_points_balance} duration={1} />
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col> */}
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <h5>Today Data</h5>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={3} md={3}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Today Income</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            $ {DashboardData.today_income_usd}
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={3}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Yesterday Income</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            $ {DashboardData.yesterday_income_usd}
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={3}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Last 7 Day Income</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            $ {DashboardData.last_seven_day_income_usd}
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={3}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Last 30 Day Income</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            $ {DashboardData.last_thirty_day_income_usd}
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={3} md={3}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Today Chargebacks</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            -$ {DashboardData.today_chargebacks_usd}
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={3}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Yesterday Chargebacks</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            -$ {DashboardData.yesterday_chargebacks_usd}
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={3}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Last 7 Day Chargebacks</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            -$ {DashboardData.last_seven_day_chargebacks_usd}
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={3}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Last 30 Day Chargebacks</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            -$ {DashboardData.last_thirty_day_chargebacks_usd}
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={3} md={3}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Total Referral User</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            <CountUp start={0} end={DashboardData.today_total_referral_users} duration={1}/>
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={3}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Total Direct User</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            <CountUp start={0} end={DashboardData.today_total_direct_users} duration={1}/>
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={3}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Total User</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            <CountUp start={0} end={DashboardData.today_total_users} duration={1}/>
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={3}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Withdrawal Request</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            <CountUp start={0} end={DashboardData.today_withdrawal_request} duration={1}/>
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={3} md={3}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Withdrawal Approved Request</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            <CountUp start={0} end={DashboardData.today_withdrawal_approved_request} duration={1}/>
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={3}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Today Withdrawal Pending USD</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            $ {DashboardData.total_withdrawal_pending_usd}
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={3}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Today Withdrawal Completed USD</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            $ {DashboardData.total_withdrawal_completed_usd}
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={3}>
                            <Card className="card-h-100">
                                <CardBody className='text-center'>
                                    <span className="text-muted mb-3 lh-1 d-block text-truncate">Today Withdrawal Cancel USD</span>
                                    <h4 className="mb-3">
                                        <span className="counter-value">
                                            $ {DashboardData.total_withdrawal_cancel_usd}
                                        </span>
                                    </h4>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Dashboard;
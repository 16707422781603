import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap"

// availity-reactstrap-validation
import { withRouter, useHistory } from "react-router-dom"
import { post, get } from '../../helpers/api_helper';
import { DateUTCToLocal, getToken } from '../../helpers/helper';
import { POST_GET_USER_CHAT, POST_GET_USER_CHAT_UPDATE_STATUS, GET_USER_DETAIL,POST_UPDATE_CHAT_STATUS } from '../../helpers/url_helper';
import ReactDOM from 'react-dom';
import Loader from '../../components/Common/Loader';
import { toast } from 'react-toastify';

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"


import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";

const UserProfile = props => {
    let history = useHistory();

    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({ 'user_chat_disabled': 0, 'user_notes': '', 'user_id': 0 });
    const [isLoader, setLoader] = useState(0);

    useEffect(() => {
        async function get_detaile() {
            if (props.match.params.id) {
                get_user_detail();
                get_user_log();
            }
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])

    const get_user_detail = async () => {
        var res_data = await get(GET_USER_DETAIL + '?user_id=' + props.match.params.id);
        if (res_data.status) {
            setInputs({ 'user_chat_disabled': res_data.detail.user_chat_disabled, 'user_notes': res_data.detail.user_notes, 'user_id': props.match.params.id });
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

    //UpdateStatus
    const UpdateStatus = async (chate_id, status) => {
        
        var res_data = await post(POST_UPDATE_CHAT_STATUS, { chate_id: chate_id, status: status });
        if (res_data.status) {
            // toast(res_data.message);
            get_user_log();
        } else {
            toast.error(res_data.message);
        }
    }

    const get_user_log = () => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": POST_GET_USER_CHAT + '?user_id=' + props.match.params.id,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [4] }, { "targets": 0, "visible": false }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "chate_id" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>{DateUTCToLocal(data.created_at)}</>, td)
                        }
                    },
                    { "data": "ip_address" },
                    { "data": "message" },
                    {
                        "data": null, 'createdCell': (td, data) => { 
                            ReactDOM.render(<>
                                {data.status === 'Active' && <button onClick={UpdateStatus.bind(this, data.chate_id, data.status)} className="border-0 badge badge-success fs-6"  >Show</button>}
                                {data.status === 'Deactive' && <button onClick={UpdateStatus.bind(this, data.chate_id, data.status)} className="border-0 badge badge-danger fs-6" >Hide</button>}
                            </>, td)
                        }
                    },
                ]
            });
        });
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        console.log(event.target.name);
        if (event.target.name === 'user_chat_disabled') {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: (event.target.checked) ? '1' : '0' }));
        } else {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();

        SetButtonDisabled(true);
        var res_data = await post(POST_GET_USER_CHAT_UPDATE_STATUS, form_inputs, form_inputs);
        if (res_data.status) {
            get_user_detail();
            toast(res_data.message);
        } else {
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    }

    if (!isLoader) {
        return (<Loader></Loader>);
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>User Chat | Free Money Coins</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Dashboard" breadcrumbItem="User chat" />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <form className="av-invalid" onSubmit={handleSubmit}>
                                        <div className="row mb-3 mt-4">
                                            <div className="col-2">
                                                <label className="form-label text-capitalize">User Chat Deactive</label>
                                                <div className="form-check form-switch form-switch-md mt-0" dir="ltr">
                                                    <input name="user_chat_disabled" onChange={handleInputChange} defaultChecked={parseInt(form_inputs.user_chat_disabled)} type="checkbox" className="form-check-input" />
                                                </div>
                                            </div>
                                            {form_inputs.user_chat_disabled > 0 && <div className="col-3">
                                                <label className="form-label">Reason</label>
                                                <textarea name="user_notes" onChange={handleInputChange} defaultChecked={parseInt(form_inputs.user_notes)} className="form-control" />
                                            </div>}
                                            <div className="col-3 pt-5">
                                                <Button disabled={ButtonDisabled} type="submit" color="primary">{ButtonDisabled && <i className="fas fa-spinner me-2 fa-spin"></i>} Save</Button>
                                            </div>
                                        </div>
                                    </form>
                                    <hr />
                                    <Row>
                                        <Col lg="12">
                                            <table id="dataTableExample" className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <td>Id</td>
                                                        <td>Date</td>
                                                        <td>Ip Address</td>
                                                        <td>Message</td>
                                                        <td>Status</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                </tbody>
                                            </table>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(UserProfile)

import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap"
import { getToken, getUserDetail } from './../../../helpers/helper';

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// users
import user1 from "../../../assets/images/favicon-32x32.png"
const ProfileMenu = props => {
    // Declare a new state variable, which we'll call "menu"
    const [menu, setMenu] = useState(false)

    const [userDetail, setUserDetail] = useState({ user_name: '' });

    useEffect(() => {
        if (getToken()) {
            var obj = getUserDetail();
            userDetail.user_name = obj.user_name;
            console.log(userDetail);
            setUserDetail(userDetail);

        }
    }, [userDetail])

    return (
        <React.Fragment>
            <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block"
            >
                <DropdownToggle
                    className="btn header-item bg-soft-light border-start border-end"
                    id="page-header-user-dropdown"
                    tag="button"
                >
                    <img
                        className="rounded-circle header-profile-user"
                        src={user1}
                        alt="Header Avatar"
                    />
                    <span className="d-none d-xl-inline-block ms-2 me-1">{userDetail.user_name}</span>
                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem>
                        <Link to="/profile" >
                            <i className="bx bx-user font-size-16 align-middle me-1" />Profile</Link>
                    </DropdownItem>
                    <DropdownItem>
                        <Link to="/change-password" >
                            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
                            Change Password
                        </Link>
                    </DropdownItem>
                    <div className="dropdown-divider" />
                    <Link to="/logout" className="dropdown-item">
                        <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                        <span>Logout</span>
                    </Link>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    )
}

ProfileMenu.propTypes = {
    success: PropTypes.any,
    t: PropTypes.any
}

const mapStatetoProps = state => {
    const { error, success } = state.Profile
    return { error, success }
}

export default withRouter(
    connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
